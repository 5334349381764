/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { DashboardDateOption } from 'fabscale-app/routes/dashboard/index/controller';
import { KpiData } from 'fabscale-app/models/kpi-data';

export default class PageDashboardCardsKpiTotalValueCard extends Component<{
  Args: {
    kpiData: KpiData;
    comparativeData: KpiData;
    dateOption: DashboardDateOption;
  };
}> {
  @service l10n: L10nService;

  get previousLabel() {
    let { l10n } = this;
    let { dateOption } = this.args;

    switch (dateOption) {
      case 'LAST_24H':
        return l10n.t('Previous 24h');
      case 'THIS_WEEK':
      case 'LAST_WEEK':
        return l10n.t('Previous week');
      default:
        return l10n.t('Previous day');
    }
  }
}
