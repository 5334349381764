/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { LabSample } from 'fabscale-app/models/lab-sample';

interface Args {
  firstLabSample: LabSample;
  secondLabSample: LabSample;
  tab: string;
  onLabSampleUpdate: (
    labSample: LabSample,
    sampleType: 'first' | 'second'
  ) => void;
}

export default class RoastPicCompare extends Component<Args> {
  @action
  onFirstLabSampleUpdate(labSample: LabSample) {
    this.args.onLabSampleUpdate(labSample, 'first');
  }

  @action
  onSecondLabSampleUpdate(labSample: LabSample) {
    this.args.onLabSampleUpdate(labSample, 'second');
  }
}
