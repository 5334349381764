/* import __COLOCATED_TEMPLATE__ from './chart-axis.hbs'; */
import Component from '@glimmer/component';
import { select } from 'd3-selection';
import 'd3-transition';
import { action } from '@ember/object';
import { isTesting, macroCondition } from '@embroider/macros';
import { AxisScale } from 'd3-axis';
import { Resource, use } from 'ember-could-get-used-to-this';
import { tracked } from '@glimmer/tracking';

interface Args {
  axis: AxisScale<any>;
  labelText?: string;
  xOffset?: number;
  yOffset?: number;
  labelXOffset?: number;
  labelYOffset?: number;
}

export default class ChartAxis extends Component<Args> {
  @tracked container: HTMLElement;

  @use d3Axis = new ChartAxisResource(() => [this.args.axis, this.container]);

  @action
  renderD3Axis(container: HTMLElement) {
    this.container = container;
  }
}

type PositionalArgs = [axis: AxisScale<any>, container: HTMLElement];

class ChartAxisResource extends Resource<PositionalArgs, any> {
  setup() {
    let [axis, container] = this.args.positional;

    select(container).call(axis);
  }

  update() {
    let [axis, container] = this.args.positional;

    let transitionTime = macroCondition(isTesting()) ? 0 : 200;

    select(container).transition().duration(transitionTime).call(axis);
  }
}
