/* import __COLOCATED_TEMPLATE__ from './sort-container.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { SortDirection } from 'fabscale-app/models/enums/sort-options';
import { assert } from '@ember/debug';

export interface DataTableSortDataOptions {
  sortBy?: string;
  sortDirection?: SortDirection;
  defaultSortBy?: string;
  defaultSortDirection?: SortDirection;
  externalSort: boolean;
  updateSort: (sortBy: string) => void;
}

interface Args {
  initialSortBy: string;
  initialSortDirection?: SortDirection;
}

export default class UiDataTableSortContainer extends Component<Args> {
  @tracked sortBy: string;
  @tracked sortDirection;

  // Set on init
  defaultSortBy;
  defaultSortDirection;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.sortBy = this.args.initialSortBy;
    this.sortDirection = this.args.initialSortDirection || 'ASC';

    this.defaultSortBy = this.sortBy;
    this.defaultSortDirection = this.sortDirection;

    assert(`SortContainer: You have to pass @initialSortBy`, !!this.sortBy);
  }

  @action
  updateSort(sortBy: string) {
    let currentSortBy = this.sortBy;
    let currentSortDirection = this.sortDirection;

    let newSortDirection: SortDirection =
      sortBy === currentSortBy && currentSortDirection === 'ASC'
        ? 'DESC'
        : 'ASC';

    this.sortBy = sortBy;
    this.sortDirection = newSortDirection;
  }
}
