/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import RepeatConfigCell from 'fabscale-app/components/page/maintenance-tasks/tasks-create-bulk/cells/repeat-config';
import RequirementsCell from 'fabscale-app/components/page/maintenance-tasks/tasks-create-bulk/cells/requirements';
import EstimatedDurationCell from 'fabscale-app/components/page/maintenance-tasks/tasks-create-bulk/cells/estimated-duration';
import { Interval } from 'fabscale-app/models/enums/intervals';
import {
  TaskCategories,
  TaskCategory,
} from 'fabscale-app/models/enums/task-categories';
import { TaskRequirement } from 'fabscale-app/models/enums/task-requirements';
import { FormDataModel } from 'fabscale-app/models/form-data';
import { PlantAsset } from 'fabscale-app/models/plant-asset';
import { PlantAssetArea } from 'fabscale-app/models/plant-asset-area';
import { RepeatConfigInput } from 'fabscale-app/models/repeat-config';
import { TableColumnDefinitionInput } from 'fabscale-app/models/table-column-definition';
import ErrorParserService from 'fabscale-app/services/error-parser';
import StoreMaintenanceTaskService from 'fabscale-app/services/store/maintenance-task';
import {
  getTimeValue,
  parseTime,
} from 'fabscale-app/utilities/utils/parse-time';
import { DateTime } from 'luxon';
import { shiftDateByInterval } from 'fabscale-app/utilities/utils/date-interval';
import { SuccessMessage } from 'fabscale-app';
import { waitForPromise } from '@ember/test-waiters';

export interface TaskData {
  index: number;
  title: string;
  repeatConfig: RepeatConfigInput;
  category: TaskCategory;
  requirements: TaskRequirement[];
  estimatedDurationSeconds: number | undefined;
  description: string;
}

class FormData {
  @tracked plantAssetId?: string;
  @tracked plantAssetAreaId?: string;
  @tracked startDate = DateTime.local().startOf('day');
  @tracked sheetsContent?: string;
  @tracked parsedTaskData?: TaskData[];
}

export default class MaintenanceTasksCreateBulk extends Component {
  @service('error-parser') errorParser: ErrorParserService;
  @service('store/maintenance-task')
  maintenanceTaskManagerStore: StoreMaintenanceTaskService;

  formData: FormData;
  formModel: FormDataModel<FormData>;
  @tracked successMessage?: SuccessMessage;

  @tracked createdTasksCount = 0;

  get creationProgress() {
    let { createdTasksCount } = this;
    let total = this.formData.parsedTaskData?.length || 0;

    if (!total || !createdTasksCount) {
      return 0;
    }

    return (createdTasksCount / total) * 100;
  }

  columns: TableColumnDefinitionInput[] = [
    {
      propertyName: 'title',
      title: 'Title',
    },
    {
      propertyName: 'category',
      title: 'Category',
    },
    {
      propertyName: 'repeatConfig',
      title: 'Interval',
      component: RepeatConfigCell,
      sortBy: 'repeatConfig.amount',
    },
    {
      propertyName: 'requirements',
      title: 'Requirements',
      component: RequirementsCell,
    },
    {
      propertyName: 'estimatedDurationSeconds',
      title: 'Est. duration',
      component: EstimatedDurationCell,
    },
  ];

  get prettyParsedTaskData() {
    return this.formData.parsedTaskData
      ? JSON.stringify(this.formData.parsedTaskData, null, 2)
      : undefined;
  }

  constructor(owner: unknown, args: any) {
    super(owner, args);

    this._initFormData();
  }

  _initFormData() {
    let formData = new FormData();

    this.formModel = new FormDataModel({
      data: formData,
      validations: [
        {
          propertyName: 'plantAssetId',
          validate: (value) => !!value,
          message: 'You have to select a plant asset',
        },
        {
          validate: (_, { data }) => !!data.parsedTaskData,
          message: 'You have to parse task data.',
        },
      ],
    });

    this.formData = formData;
  }

  _resetFormData() {
    this.formData.plantAssetAreaId = undefined;
    this.formData.parsedTaskData = undefined;
    this.formData.sheetsContent = undefined;
  }

  @action
  updatePlantAsset(plantAsset?: PlantAsset) {
    this.formModel.updateProperty('plantAssetId', plantAsset?.id);
    this.formModel.updateProperty('plantAssetAreaId', undefined);
  }

  @action
  updatePlantAssetArea(plantAssetArea?: PlantAssetArea) {
    this.formModel.updateProperty('plantAssetAreaId', plantAssetArea?.id);
  }

  @action
  updateStartDate(startDate: DateTime) {
    this.formModel.updateProperty('startDate', startDate);
  }

  @action
  updateSheetsContent(sheetsContent: string) {
    this.formModel.updateProperty('sheetsContent', sheetsContent);
  }

  parseSheetsContentTask = dropTask(async () => {
    let { sheetsContent } = this.formData;

    if (!sheetsContent) {
      return;
    }

    try {
      let parsed: TaskData[] = await waitForPromise(
        parseSheetsContent(sheetsContent)
      );
      this.formData.parsedTaskData = parsed;
    } catch (error) {
      this.formModel.addError((error as any)?.message, 'sheetsContent');
    }
  });

  createMaintenanceTasksTask = dropTask(async () => {
    this.successMessage = undefined;

    await this.formModel.validate();

    if (this.formModel.isInvalid) {
      return;
    }

    let { maintenanceTaskManagerStore } = this;
    let { plantAssetId, plantAssetAreaId, startDate, parsedTaskData } =
      this.formData;

    this.createdTasksCount = 0;

    for (let taskData of parsedTaskData!) {
      let dueDate = shiftDateByInterval(
        startDate,
        taskData.repeatConfig.timeInterval.interval,
        taskData.repeatConfig.timeInterval.amount
      );

      try {
        await maintenanceTaskManagerStore.createRepeating(
          Object.assign({ plantAssetId, plantAssetAreaId, dueDate }, taskData),
          Object.assign(taskData.repeatConfig, { startDate })
        );
      } catch (error) {
        this.formModel.addError(this.errorParser.getErrorMessage(error));
        continue;
      }

      this.createdTasksCount++;
    }

    this.successMessage = {
      title: `${this.createdTasksCount} task(s) successfully created.`,
    };

    this._resetFormData();
  });
}

export async function parseSheetsContent(content: string): Promise<TaskData[]> {
  // @ts-ignore
  let { parse } = await import('papaparse');

  let parsed: {
    errors: {
      message: string;
      row: number;
    }[];
    data: string[][];
  } = parse(content, {
    transform: (value: string) => value.trim(),
  });

  if (parsed.errors.length) {
    throw new Error(
      `An error occurred when trying to parse the sheet content: ${parsed.errors
        .map((error) => `Row ${error.row}: ${error.message}`)
        .join(', ')}`
    );
  }
  let rows = parsed.data as [
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string
  ][];

  /*
  Expected columns:
  
  Title	
  Uptime-h
  Interval
  Interval-Unit
  Category
  Roaster-shutdown
  Area-shutdown
  Spare-parts
  External
  Est.-Duration
  Description
  */

  let taskData = rows.map((row, index) => {
    if (row.length != 11) {
      throw new Error(`row #${index} has ${row.length} instead of 11 columns`);
    }

    let [
      title,
      uptimeH,
      intervalStr,
      intervalUnit,
      categoryStr,
      reqShutdown,
      reqAreaShutdown,
      reqSpareParts,
      reqExternal,
      estDurationStr,
      description,
    ] = row;

    try {
      return {
        index,
        title,
        repeatConfig: parseRepeatConfig(intervalStr, intervalUnit, uptimeH),
        category: parseCategory(categoryStr),
        requirements: parseRequirements(
          reqShutdown,
          reqAreaShutdown,
          reqSpareParts,
          reqExternal
        ),
        estimatedDurationSeconds: estDurationStr
          ? getTimeValue(parseTime(estDurationStr, { expectHours: true }))
          : undefined,
        description,
      };
    } catch (error) {
      throw new Error(`Error in row #${index}: ${(error as any)?.message}`);
    }
  });

  return taskData;
}

function parseRepeatConfig(
  amountStr: string,
  intervalStr: string,
  uptimeH: string
): RepeatConfigInput {
  if (!amountStr || !intervalStr) {
    throw new Error(`There has to be an interval amount & unit.`);
  }

  let repeatConfig: RepeatConfigInput = {
    startDate: DateTime.local().startOf('day'),
    timeInterval: {
      amount: parseInteger(amountStr),
      interval: parseInterval(intervalStr),
    },
  };

  if (uptimeH) {
    let amount = parseInteger(uptimeH);
    if (amount > 0) {
      repeatConfig.uptimeInterval = {
        amount,
        unit: 'HOUR',
      };
    }
  }

  return repeatConfig;
}

function parseInteger(num: string) {
  return parseInt(num.replace(/,/g, ''));
}

function parseInterval(intervalStr: string): Interval {
  let interval = intervalStr.toUpperCase();

  if (['DAY', 'WEEK', 'MONTH', 'YEAR'].includes(interval)) {
    return interval as Interval;
  }

  throw new Error(`interval unit "${intervalStr}" cannot be parsed`);
}

function parseCategory(categoryStr: string): TaskCategory {
  let cat = categoryStr.toUpperCase();

  let map = new Map([
    ['REINIGUNG', 'CLEAN'],
    ['KONTROLLE', 'CHECK'],
    ['CLEANING', 'CLEAN'],
  ]);

  if (map.has(cat)) {
    cat = map.get(cat)!;
  }

  if (TaskCategories.includes(cat as TaskCategory)) {
    return cat as TaskCategory;
  }

  throw new Error(`category "${categoryStr}" cannot be parsed`);
}

function parseRequirements(
  reqShutdown: string,
  reqAreaShutdown: string,
  reqSpareParts: string,
  reqExternal: string
): TaskRequirement[] {
  let requirements: TaskRequirement[] = [];

  if (parseCheck(reqShutdown)) {
    requirements.push('ASSET_SHUTDOWN');
  }

  if (parseCheck(reqAreaShutdown)) {
    requirements.push('AREA_SHUTDOWN');
  }

  if (parseCheck(reqSpareParts)) {
    requirements.push('SPARE_PARTS');
  }

  if (parseCheck(reqExternal)) {
    requirements.push('EXTERNAL');
  }

  return requirements;
}

function parseCheck(check: string) {
  return !!check;
}
