/* import __COLOCATED_TEMPLATE__ from './company-user-show.hbs'; */
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { LocaleLabels } from 'fabscale-app/models/enums/locales';
import { User } from 'fabscale-app/models/user';
import ErrorParserService from 'fabscale-app/services/error-parser';
import StoreUserService from 'fabscale-app/services/store/user';
import UserSessionService from 'fabscale-app/services/user-session';

interface Args {
  user: User;
}

export default class SettingsCompanyUserShow extends Component<Args> {
  @service router: RouterService;
  @service userSession: UserSessionService;
  @service('store/user') userStore: StoreUserService;
  @service('error-parser') errorParser: ErrorParserService;

  @tracked error?: string;

  get localeLabel() {
    let { locale } = this.args.user;
    return LocaleLabels.find((localeLabel) => localeLabel.locale === locale)
      ?.label;
  }

  get userIsCurrentUser() {
    return this.args.user.id === this.userSession.user!.id;
  }

  deleteUserTask = dropTask(async () => {
    let { userStore } = this;

    this.error = undefined;

    try {
      await userStore.delete(this.args.user.email);
    } catch (error) {
      this.error = this.errorParser.getErrorMessage(error);
      return;
    }

    this.router.transitionTo('routes/company-settings.users.index');
  });
}
