/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import {
  BooleanRoastBatchParameterTypes,
  getUnitTypeForNumericParameterType,
  NumericRoastBatchParameterTypes,
} from 'fabscale-app/models/enums/roast-batch-parameter-type';
import { getDefaultUnit } from 'fabscale-app/models/enums/unit-systems';
import { Unit, UnitsRoastColor } from 'fabscale-app/models/enums/units';
import {
  EditingBooleanParameter,
  EditingNumericParameter,
} from 'fabscale-app/models/editing-roast-batch-parameter';
import {
  BooleanRoastBatchParameter,
  NumericRoastBatchParameter,
  RoastBatch,
} from 'fabscale-app/models/roast-batch';
import ErrorParserService from 'fabscale-app/services/error-parser';
import SettingsService from 'fabscale-app/services/settings';
import StoreRoastBatchService from 'fabscale-app/services/store/roast-batch';
import { logError } from 'fabscale-app/utilities/utils/log-error';

interface Args {
  roastBatch: RoastBatch;
}

export default class RoastBatchesEditForm extends Component<Args> {
  @service settings: SettingsService;
  @service l10n: L10nService;
  @service('store/roast-batch') roastBatchStore: StoreRoastBatchService;
  @service('error-parser') errorParser: ErrorParserService;
  @service router: RouterService;

  @tracked error?: string;

  booleanOptions: [string, string];

  get unitSystem() {
    return this.settings.locationSettings.unitSystem;
  }

  get defaultRoastColorUnit() {
    return this.settings.locationSettings.defaultRoastColorUnit;
  }

  @cached
  get numericRoastBatchParameters() {
    let { numericRoastBatchParameters } = this.args.roastBatch;
    let { unitSystem, defaultRoastColorUnit } = this;

    return NumericRoastBatchParameterTypes.map((parameterType) => {
      let param = numericRoastBatchParameters.find(
        (param) => param.parameterType === parameterType
      );

      let unitType = getUnitTypeForNumericParameterType(parameterType);
      let defaultUnit =
        unitType === UnitsRoastColor
          ? defaultRoastColorUnit
          : getDefaultUnit(unitSystem, unitType);

      let { value, unit = defaultUnit, externalId } = param || {};

      return new EditingNumericParameter({
        parameterType,
        value,
        unit,
        externalId,
        units: unitType,
      });
    });
  }

  @cached
  get booleanRoastBatchParameters() {
    let { booleanRoastBatchParameters } = this.args.roastBatch;

    return BooleanRoastBatchParameterTypes.map((parameterType) => {
      let param = booleanRoastBatchParameters.find(
        (param) => param.parameterType === parameterType
      );

      let { value, externalId } = param || {};

      return new EditingBooleanParameter({
        parameterType,
        value,
        externalId,
      });
    });
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.booleanOptions = [this.l10n.t('Yes'), this.l10n.t('No')];
  }

  @action
  updateNumericRoastBatchParameterValue(
    param: EditingNumericParameter,
    value?: number
  ) {
    param.value = value;
  }

  @action
  updateNumericRoastBatchParameterUnit(
    param: EditingNumericParameter,
    unit: Unit
  ) {
    param.unit = unit;
  }

  @action
  updateBooleanRoastBatchParameterValue(
    param: EditingBooleanParameter,
    value: string | undefined
  ) {
    if (value === this.booleanOptions[0]) {
      param.value = true;
    } else if (value === this.booleanOptions[1]) {
      param.value = false;
    } else {
      param.value = undefined;
    }
  }

  submitFormTask = dropTask(async () => {
    let roastBatchId = this.args.roastBatch.id;

    let changedNumericParameters = this.numericRoastBatchParameters.filter(
      (param) => param.hasChanged
    );

    let changedBooleanParameters = this.booleanRoastBatchParameters.filter(
      (param) => param.hasChanged
    );

    let numericRoastBatchParameters: NumericRoastBatchParameter[] =
      changedNumericParameters.map((param) => {
        let { value, unit, externalId, parameterType } = param;

        return { value: value as number, unit, externalId, parameterType };
      });

    let booleanRoastBatchParameters: BooleanRoastBatchParameter[] =
      changedBooleanParameters.map((param) => {
        let { value, externalId, parameterType } = param;

        return { value: value as boolean, externalId, parameterType };
      });

    try {
      await this.roastBatchStore.setRoastBatchParameters(roastBatchId, {
        numericRoastBatchParameters,
        booleanRoastBatchParameters,
      });
    } catch (error) {
      logError(error);

      this.error = this.errorParser.getErrorMessage(error);
      return;
    }

    this.router.transitionTo('routes/roast-batches.show.index');
  });
}
