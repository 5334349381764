/* import __COLOCATED_TEMPLATE__ from './task-table.hbs'; */
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import CellAssignedUser from 'fabscale-app/components/module/task-table-paginated/cells/assigned-user';
import CellTitle from 'fabscale-app/components/module/task-table-paginated/cells/title';
import { SortDirection } from 'fabscale-app/models/enums/sort-options';
import { TaskStatus } from 'fabscale-app/models/enums/task-status';
import { MaintenanceTask } from 'fabscale-app/models/maintenance-task';
import { TableColumnDefinitionInput } from 'fabscale-app/models/table-column-definition';
import { DateFormat } from 'fabscale-app/utilities/utils/format-date';
import { DateTime } from 'luxon';

interface Args {
  tasks: MaintenanceTask[];
}

export default class ModuleTaskTable extends Component<Args> {
  @service l10n: L10nService;

  columns: TableColumnDefinitionInput[];

  hasOpenTasks: boolean;
  hasCompletedTasks: boolean;

  get initialSortBy() {
    return this.hasOpenTasks ? 'dueDate' : 'completionDate';
  }

  get initialSortDirection(): SortDirection {
    return this.initialSortBy === 'dueDate' ? 'ASC' : 'DESC';
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.hasOpenTasks = this.args.tasks.some(
      (task) => task.isCompleted === false
    );
    this.hasCompletedTasks = this.args.tasks.some(
      (task) => task.isCompleted === true
    );

    this._setupColumns();
  }

  _setupColumns() {
    let { l10n } = this;

    let columns: TableColumnDefinitionInput[] = [
      {
        title: l10n.t('Title'),
        propertyName: 'title',
        tdClass: 'min-width-5',
        noCompactTitle: true,
        component: CellTitle,
      },
      {
        title: l10n.t('Assignee'),
        propertyName: 'assignedUser',
        tdClass: 'order-last',
        hideIfCompact: true,
        component: CellAssignedUser,
      },
    ];

    if (this.hasCompletedTasks) {
      columns.push({
        title: l10n.t('Completion date'),
        propertyName: 'completionDate',
        hideIfCompact: true,
        thClass: 'text-right nowrap',
        tdClass: 'text-right',
        cellType: 'DATE',
        cellData: {
          dateFormat: DateFormat.DateLong,
        },
      });
    }

    if (this.hasOpenTasks) {
      columns.push({
        title: l10n.t('Due date'),
        propertyName: 'dueDate',
        hideIfCompact: true,
        thClass: 'text-right nowrap',
        tdClass: 'text-right',
        cellType: 'DATE',
        cellData: {
          dateFormat: DateFormat.DateLong,
        },
      });
    }

    this.columns = columns;
  }

  @action
  onToggleStatus({
    id,
    status,
    completionDate,
  }: {
    id: string;
    status: TaskStatus;
    completionDate?: DateTime;
  }) {
    let task = this.args.tasks.find((task) => task.id === id);

    if (task) {
      task.status = status;
      task.completionDate = completionDate;
    }
  }
}
