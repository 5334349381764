/* import __COLOCATED_TEMPLATE__ from './calendar.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { DateTime } from 'luxon';
import SettingsService from 'fabscale-app/services/settings';
import AnalyticsService from 'fabscale-app/services/analytics';
import { action } from '@ember/object';
import { DateRangeOptional } from 'fabscale-app/models/date-range';

interface Args {
  value?: DateTime;
  minDate?: DateTime;
  maxDate?: DateTime;
  hasValue: boolean;
  allowClear?: boolean;
  updateCenterMonth: (date?: DateTime) => void;
  centerMonth: DateTime;
  updateDate: (date?: DateTime) => void;
  updateDateRange: (date?: DateRangeOptional) => void;
}

export default class UiDateRangePickerCalendar extends Component<Args> {
  @service settings: SettingsService;
  @service analytics: AnalyticsService;

  get nextMonth() {
    return this.args.centerMonth.plus({ months: 1 }).startOf('month');
  }

  get startDayOfWeek() {
    return this.settings.locationSettings.startDayOfWeek;
  }

  get showClearButton() {
    return this.args.allowClear && this.args.hasValue;
  }

  @action
  selectClear() {
    this.analytics.addEvent('date-picker-select-clear');
    this.args.updateDateRange(undefined);
  }
}
