/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { MaintenanceTask } from 'fabscale-app/models/maintenance-task';
import ErrorParserService from 'fabscale-app/services/error-parser';
import MaintenanceTaskManagerService from 'fabscale-app/services/maintenance-task-manager';
import StoreMaintenanceTaskService from 'fabscale-app/services/store/maintenance-task';

interface Args {
  task: MaintenanceTask;
}

export default class MaintenanceTasksTaskDetails extends Component<Args> {
  @service maintenanceTaskManager: MaintenanceTaskManagerService;
  @service('error-parser') errorParser: ErrorParserService;
  @service('store/maintenance-task')
  maintenanceTaskManagerStore: StoreMaintenanceTaskService;

  @tracked error?: string;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.maintenanceTaskManager.viewedTask(this.args.task);
  }

  deleteTask = dropTask(async () => {
    let { id } = this.args.task;
    let { maintenanceTaskManagerStore, maintenanceTaskManager } = this;

    try {
      await maintenanceTaskManagerStore.delete(id);
    } catch (error) {
      this.error = this.errorParser.getErrorMessage(error);
      return;
    }

    maintenanceTaskManager.goToPreviousPage();
  });
}
