/* import __COLOCATED_TEMPLATE__ from './edit-role.hbs'; */
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import { Permission } from 'fabscale-app/models/enums/permissions';
import { UserRole } from 'fabscale-app/models/user-role';
import StoreUserRoleService from 'fabscale-app/services/store/user-role';
import UserSessionService from 'fabscale-app/services/user-session';

interface Args {
  role: UserRole;
}

export default class PageSettingsCreateRole extends Component<Args> {
  @service('store/user-role') userRoleStore: StoreUserRoleService;
  @service userSession: UserSessionService;
  @service router: RouterService;

  updateUserRoleTask = dropTask(
    async ({
      name,
      permissions,
    }: {
      name: string;
      permissions: Permission[];
    }) => {
      let { role } = this.args;

      await this.userRoleStore.update(role.id, {
        name,
        permissions,
      });

      // Reload if role is of current user
      if (role.id === this.userSession.user?.role.id) {
        window.location.href = this.router.urlFor(
          'routes/company-settings.roles.show.index',
          role.id
        );
      }
    }
  );
}
