/* import __COLOCATED_TEMPLATE__ from './role-template-select.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { UserRole } from 'fabscale-app/models/user-role';

interface Args {
  onSelect: (role: UserRole) => Promise<void>;
}

export default class RoleTemplateSelect extends Component<Args> {
  @tracked selectedRole?: UserRole;

  @action
  updateSelectedRole(role: UserRole) {
    this.selectedRole = role;
  }

  @action
  async submitRole() {
    if (!this.selectedRole) {
      return;
    }

    await this.args.onSelect(this.selectedRole);

    this.selectedRole = undefined;
  }
}
