/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { TableGetColumnInfoHandler } from 'fabscale-app/helpers/data-table/get-column-info';
import { FilePending } from 'fabscale-app/models/file-pending';
import { FileWrapper } from 'fabscale-app/models/file-wrapper';
import {
  TableColumnDefinition,
  TableColumnDefinitionInput,
} from 'fabscale-app/models/table-column-definition';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { DateTime } from 'luxon';
import CellName from 'fabscale-app/components/module/file-upload-list/cells/name';
import CellDate from 'fabscale-app/components/module/file-upload-list/cells/date';
import CellActions from 'fabscale-app/components/module/file-upload-list/cells/actions';

interface Args {
  files: FileWrapper[];
  filesPending: FilePending[];
}

export class FileOrPendingFile {
  id?: string;
  @tracked name: string;
  size: number;
  extension: string;
  sizeFormatted: string;
  creationDate: DateTime;
  isPending: boolean;
  creationDateSort: number;
  url?: string;
  thumbnailUrl?: string;
  fileWrapper?: FileWrapper;

  constructor(options: {
    id?: string;
    name: string;
    size: number;
    extension: string;
    sizeFormatted: string;
    creationDate: DateTime;
    isPending: boolean;
    creationDateSort: number;
    url?: string;
    thumbnailUrl?: string;
    fileWrapper?: FileWrapper;
  }) {
    Object.assign(this, options);
  }
}

export default class FileUploadListTable extends Component<Args> {
  @service l10n: L10nService;

  @tracked editRecord?: FileOrPendingFile;

  columns: TableColumnDefinitionInput[];

  @cached
  get allTableData(): FileOrPendingFile[] {
    let files = this.args.files || [];
    let filesPending = this.args.filesPending || [];

    let parsedFiles: FileOrPendingFile[] = files.map((file) => {
      let {
        id,
        name,
        size,
        sizeFormatted,
        creationDate,
        extension,
        url,
        thumbnailUrl,
      } = file;

      return new FileOrPendingFile({
        id,
        name,
        size,
        sizeFormatted,
        creationDate,
        extension,
        url,
        thumbnailUrl,
        isPending: false,
        creationDateSort: creationDate.valueOf(),
        fileWrapper: file,
      });
    });

    let parsedPendingFiles: FileOrPendingFile[] = filesPending.map(
      (filePending) => {
        let { name, size, sizeFormatted, creationDate, extension } =
          filePending;

        return new FileOrPendingFile({
          name,
          size,
          sizeFormatted,
          creationDate,
          extension,
          isPending: true,
          creationDateSort: creationDate.valueOf() + 99999,
        });
      }
    );

    let allTableData: FileOrPendingFile[] = parsedFiles.slice();
    return allTableData.concat(...parsedPendingFiles);
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    let { l10n } = this;
    this.columns = [
      {
        propertyName: 'name',
        title: l10n.t('Name'),
        component: CellName,
        tdClass: 'min-width-5',
        noCompactTitle: true,
      },
      {
        propertyName: 'sizeFormatted',
        title: l10n.t('File size'),
        sortBy: 'size',
        tdClass: 'text-right nowrap',
        thClass: 'text-right nowrap',
      },
      {
        propertyName: 'creationDate',
        title: l10n.t('Uploaded'),
        component: CellDate,
        sortBy: 'creationDateSort',
        tdClass: 'text-right nowrap',
        thClass: 'text-right',
      },
      {
        propertyName: '',
        title: '',
        component: CellActions,
        tdClass: 'nowrap',
      },
    ];
  }

  get getColumnInfoHandler(): TableGetColumnInfoHandler | undefined {
    let { editRecord } = this;

    if (!editRecord) {
      return undefined;
    }

    return (column: TableColumnDefinition, record: FileOrPendingFile) => {
      if (editRecord !== record) {
        return { colspan: undefined, showColumn: true };
      }

      // Editing this record - only show name column
      return column.propertyName === 'name'
        ? { colspan: 4, showColumn: true }
        : { showColumn: false };
    };
  }

  @action
  startEditMode(file: FileOrPendingFile) {
    this.editRecord = file;
  }

  @action
  cancelEditMode() {
    this.editRecord = undefined;
  }
}
