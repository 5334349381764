/* import __COLOCATED_TEMPLATE__ from './general.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import StoreMaintenanceTaskService from 'fabscale-app/services/store/maintenance-task';
import { MaintenanceTask } from 'fabscale-app/models/maintenance-task';
import { DateTime } from 'luxon';
import { TaskStatus } from 'fabscale-app/models/enums/task-status';

interface Args {
  task: MaintenanceTask;
}

export default class MaintenanceTasksTaskDetailsGeneral extends Component<Args> {
  @service('store/maintenance-task')
  maintenanceTaskManagerStore: StoreMaintenanceTaskService;

  toggleStatusTask = dropTask(async () => {
    let { task } = this.args;
    let { id, isCompleted } = task;

    let status: TaskStatus = isCompleted ? 'OPEN' : 'COMPLETED';

    let {
      completionDate,
    }: {
      completionDate?: DateTime;
    } = await this.maintenanceTaskManagerStore.updateStatus(id, {
      status,
    });

    task.status = status;
    task.completionDate = completionDate;
  });
}
