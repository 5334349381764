/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { restartableTask, timeout } from 'ember-concurrency';
import { DateRange } from 'fabscale-app/models/date-range';
import ScreenService from 'fabscale-app/services/screen';
import SettingsService from 'fabscale-app/services/settings';
import { MaintenanceTaskQueryOptions } from 'fabscale-app/services/store/maintenance-task';
import UserSessionService from 'fabscale-app/services/user-session';
import { DateTime } from 'luxon';

interface Args {
  date: DateTime;
  query: MaintenanceTaskQueryOptions;
}

export default class TaskCalendarDay extends Component<Args> {
  @service settings: SettingsService;
  @service userSession: UserSessionService;
  @service screen: ScreenService;

  @tracked selectedMonth?: DateTime;

  get centerMonth() {
    return this.selectedMonth || this.args.date;
  }

  get query(): MaintenanceTaskQueryOptions {
    let { query, date } = this.args;

    let dateRange = new DateRange({
      start: date.startOf('day'),
      end: date.endOf('day'),
    });

    return Object.assign({}, query, { dateRange });
  }

  @action
  updateCenterMonth(date: DateTime) {
    this.selectedMonth = date;
  }

  resetCenterMonthTask = restartableTask(async () => {
    if (!this.selectedMonth) {
      return;
    }

    // We wait one tick here to avoid triggering an error
    // that we change a tracked property that was read before
    await timeout(1);
    this.selectedMonth = undefined;
  });
}
