/* import __COLOCATED_TEMPLATE__ from './kpi-config-options.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import {
  DashboardCardConfigKpiOverTime,
  DashboardCardConfigKpiPerPlantAsset,
  DashboardCardConfigKpiPerRecipe,
  DashboardCardConfigKpiTotalValue,
} from 'fabscale-app/models/dashboard-config';

type KpiConfig =
  | DashboardCardConfigKpiOverTime
  | DashboardCardConfigKpiPerPlantAsset
  | DashboardCardConfigKpiPerRecipe
  | DashboardCardConfigKpiTotalValue;

interface Args<T> {
  id: number;
  config: T;
  updateConfig: (config: KpiConfig) => void;
  validateConfig: (config: KpiConfig) => boolean;
}

export default class PageDashboardCustomizeKpiConfigOptions<
  T extends KpiConfig
> extends Component<{
  Args: Args<T>;
}> {
  get hasError() {
    return !this.args.validateConfig(this.args.config);
  }

  @action
  updateKpiType(kpiType: KpiType) {
    let { config } = this.args;

    let newConfig: T = Object.assign({}, config, { kpiType });
    this.args.updateConfig(newConfig);
  }

  @action
  updateColor(color: string) {
    let { config } = this.args;

    let newConfig: T = Object.assign({}, config, { color });
    this.args.updateConfig(newConfig);
  }
}
