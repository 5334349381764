/* import __COLOCATED_TEMPLATE__ from './pagination.hbs'; */
import Component from '@glimmer/component';
import { generatePaginationItems } from 'fabscale-app/utilities/utils/pagination';

interface Args {
  pages: number;
  currentPage: number;
  totalItems: number;
  pageSize: number;
  movePrevious: () => void;
  moveNext: () => void;
  moveTo: (page: number) => void;
}

export default class UiPagination extends Component<Args> {
  get canMovePrevious() {
    return this.args.currentPage > 1;
  }

  get canMoveNext() {
    return this.args.currentPage < this.args.pages;
  }

  get itemsStart() {
    let { currentPage, pageSize, totalItems } = this.args;
    return totalItems ? (currentPage - 1) * pageSize + 1 : 0;
  }

  get itemsEnd() {
    let { itemsStart } = this;
    let { pageSize, totalItems } = this.args;

    return Math.min(totalItems, itemsStart + pageSize - 1);
  }

  get showPageInfo() {
    return typeof this.args.totalItems === 'number';
  }

  get pageItems() {
    let { pages, currentPage } = this.args;

    return generatePaginationItems({ pages, currentPage });
  }
}
