import { DateRangeOptional } from 'fabscale-app/models/date-range';
import { DateTime } from 'luxon';

// The default range is supposed to cover "all", but allow us to always have a date range to work with.
export function getActualDateRange(dateRange?: DateRangeOptional) {
  return {
    start:
      dateRange?.start ?? DateTime.local().minus({ years: 10 }).startOf('day'),
    end: dateRange?.end ?? DateTime.local().plus({ days: 1 }).startOf('day'),
  };
}
