/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { getOwnConfig } from '@embroider/macros';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import config from 'fabscale-app/config/environment';
import UserAuthenticationService from 'fabscale-app/services/user-authentication';
import UserSessionService from 'fabscale-app/services/user-session';
import { getUser } from 'fabscale-app/utilities/utils/local-storage';

export default class SiteWrapperNav extends Component {
  @service userAuthentication: UserAuthenticationService;
  @service router: RouterService;
  @service userSession: UserSessionService;

  isSimulation: boolean = getOwnConfig<any>().isSimulation;

  // Properties
  @tracked environmentName = config.environmentName;

  get hasCurrentLocation() {
    return Boolean(this.userSession.currentLocation);
  }

  get locationName() {
    return this.userSession.currentLocation?.name;
  }

  get userName() {
    return this.isSimulation
      ? getUser(true)?.email
      : this.userSession.user?.name;
  }

  get availableLocations() {
    return this.userSession.locations!;
  }

  get otherAvailableLocations() {
    let currentLocationId = this.userSession.currentLocation?.id;
    let { availableLocations } = this;

    return currentLocationId
      ? availableLocations.filter(
          (location) => location.id !== currentLocationId
        )
      : availableLocations;
  }

  @action
  hideEnvironmentName() {
    this.environmentName = undefined;
  }
}
