/* import __COLOCATED_TEMPLATE__ from './table-over-time.hbs'; */
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { Interval } from 'fabscale-app/models/enums/intervals';
import { PlantAssetInfoSummaryOverTime } from 'fabscale-app/models/plant-asset-info-summary';
import { TableColumnDefinitionInput } from 'fabscale-app/models/table-column-definition';
import EnumLabelsService from 'fabscale-app/services/enum-labels';
import { convertTimeAmount } from 'fabscale-app/utilities/utils/unit-converter';
import { DateTime } from 'luxon';
import { StatusMapReportDetailTableData } from './table';

interface Args {
  data: PlantAssetInfoSummaryOverTime[];
  interval: Interval;
}

export default class KpiCompareReportDetailsTableOverTime extends Component<Args> {
  @service l10n: L10nService;
  @service('enum-labels') enumLabels: EnumLabelsService;

  get columns(): TableColumnDefinitionInput[] {
    let { data } = this.args;

    let { l10n, enumLabels, dateFormatString } = this;

    let columns: TableColumnDefinitionInput[] = [
      {
        title: l10n.t('Date'),
        propertyName: 'label',
        cellType: 'DATE',
        cellData: {
          dateFormatString,
        },
      },
      ...data.map((summary) => {
        let col: TableColumnDefinitionInput = {
          title: enumLabels.plantAssetStatus(summary.plantAssetStatus),
          propertyName: `${summary.plantAssetStatus}.value`,
          cellType: 'AMOUNT',
          cellData: {
            unit: 'HOUR',
          },
        };

        return col;
      }),
    ];

    return columns;
  }

  @cached
  get data(): StatusMapReportDetailTableData[] {
    let { data } = this.args;
    let { dateFormatString } = this;

    let allDates: DateTime[] = [];

    data
      .map((item) => item.bins)
      .forEach((dateBinValues) => {
        dateBinValues.forEach((dateBinValue) => {
          let { dateRange } = dateBinValue;

          if (!allDates.find((dateB) => +dateRange.start === +dateB)) {
            allDates.push(dateRange.start);
          }
        });
      });

    // Remove dates that are after today
    let today = +DateTime.local();
    allDates = allDates.filter((date) => +date <= today).sort();

    return allDates.map((date) => {
      let searchLabel = date.toFormat(dateFormatString);

      let item: StatusMapReportDetailTableData = {
        label: date,
        searchLabel: searchLabel.toLowerCase(),
      };

      data.forEach((summary) => {
        let dateBinValue = summary.bins.find(
          (item) => +date === +item.dateRange.start
        );

        let { unit } = summary;

        if (dateBinValue) {
          item[summary.plantAssetStatus] = {
            value: convertTimeAmount(dateBinValue.value, unit, 'HOUR'),
            unit: 'HOUR',
          };
        }
      });

      return item;
    });
  }

  get dateFormatString() {
    let { l10n } = this;
    let { interval } = this.args;

    if (interval === 'WEEK') {
      return `'${l10n.t('Week')}' W - D`;
    }

    if (interval === 'MONTH') {
      return 'LLLL yyyy';
    }

    // Default: day
    return 'D (EEE)';
  }

  get labelColumn(): TableColumnDefinitionInput {
    let { l10n, dateFormatString } = this;

    return {
      title: l10n.t('Date'),
      propertyName: 'label',
      cellType: 'DATE',
      cellData: {
        dateFormatString,
      },
    };
  }
}
