/* import __COLOCATED_TEMPLATE__ from './drop-zone.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask, timeout } from 'ember-concurrency';

interface Args {
  onSelectFiles: (files: File[]) => void;
}

export default class ModuleFileUploadListDropZone extends Component<Args> {
  @tracked isDraggingAnywhere = false;
  @tracked isActive = false;

  _body = document.body;
  _element: HTMLElement;

  _handlers?: {
    dragenter: () => void;
    drop: (event: DragEvent) => void;
  };

  _bodyHandlers?: {
    dragenter: (event: DragEvent) => void;
    dragleave: (event: DragEvent) => void;
    dragover: (event: DragEvent) => void;
    drop: (event: DragEvent) => void;
  };

  willDestroy() {
    this._endListening();
    this._endListeningBody();
    super.willDestroy();
  }

  @action
  registerDropZone(element: HTMLElement) {
    this._element = element;
    this._beginListening();
    this._beginListeningBody();
  }

  /*
    Drag enter/leave events are weird...
    When moving over different elements, the browser will first trigger enter, then leave.
    Only when we leave the page or similar does only a leave event happen.
  */
  _updateIsDraggingAnywhereTask = dropTask(
    async (isDraggingAnywhere: boolean) => {
      await timeout(1);

      if (this.isDraggingAnywhere !== isDraggingAnywhere) {
        this.isDraggingAnywhere = isDraggingAnywhere;
      }
    }
  );

  dragEnterBody(event: DragEvent) {
    event.preventDefault();

    let target = event.target as HTMLElement;

    if (this.isActive && !this._element.contains(target)) {
      this.isActive = false;
    }

    this._updateIsDraggingAnywhereTask.perform(true);
  }

  dragLeaveBody(event: DragEvent) {
    event.preventDefault();

    this._updateIsDraggingAnywhereTask.perform(false);
  }

  dropBody(event: DragEvent) {
    event.preventDefault();

    this.isDraggingAnywhere = false;
    this._updateIsDraggingAnywhereTask.cancelAll();
  }

  dragEnter() {
    this.isActive = true;
  }

  drop(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();

    this.isActive = false;
    this.isDraggingAnywhere = false;
    this._updateIsDraggingAnywhereTask.cancelAll();

    if (event.dataTransfer?.files) {
      this.args.onSelectFiles(Array.from(event.dataTransfer.files));
    }
  }

  _beginListening() {
    let element = this._element;

    let handlers = {
      dragenter: () => this.dragEnter(),
      drop: (event: DragEvent) => this.drop(event),
    };

    element.addEventListener('dragenter', handlers.dragenter, {
      passive: true,
    });
    element.addEventListener('drop', handlers.drop, {
      passive: false,
    });

    this._handlers = handlers;
  }

  _beginListeningBody() {
    let body = this._body;

    let handlers = {
      dragenter: (event: DragEvent) => this.dragEnterBody(event),
      dragleave: (event: DragEvent) => this.dragLeaveBody(event),
      dragover: (event: DragEvent) => event.preventDefault(),
      drop: (event: DragEvent) => this.dropBody(event),
    };

    body.addEventListener('dragenter', handlers.dragenter, {
      passive: true,
    });
    body.addEventListener('dragleave', handlers.dragleave, {
      passive: true,
    });
    body.addEventListener('dragover', handlers.dragover, {
      passive: false,
    });
    body.addEventListener('drop', handlers.drop, {
      passive: false,
    });

    this._bodyHandlers = handlers;
  }

  _endListening() {
    let element = this._element;
    let handlers = this._handlers;

    if (!handlers) {
      return;
    }

    element.removeEventListener('dragenter', handlers.dragenter);
    element.removeEventListener('drop', handlers.drop);

    this._handlers = undefined;
  }

  _endListeningBody() {
    let body = this._body;
    let handlers = this._bodyHandlers;

    if (!handlers) {
      return;
    }

    body.removeEventListener('dragenter', handlers.dragenter);
    body.removeEventListener('drop', handlers.drop);

    this._bodyHandlers = undefined;
  }
}
