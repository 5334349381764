/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { restartableTask, timeout } from 'ember-concurrency';
import { use } from 'ember-could-get-used-to-this';
import LoadPlantAssetsInUseResource from 'fabscale-app/helpers/resources/load-plant-assets-in-use';
import { DateRange } from 'fabscale-app/models/date-range';
import AnalyticsService from 'fabscale-app/services/analytics';
import { removeItem } from 'fabscale-app/utilities/utils/array';
import { chartColors } from 'fabscale-app/utilities/fixtures/chart-colors';
import { getAsyncResourceData } from 'fabscale-app/utilities/utils/get-async-resource-value';

interface Args {
  overTimeDateRange: DateRange;
}

export default class KpiDetailsReportPerRecipeIndex extends Component<Args> {
  @service analytics: AnalyticsService;

  @use
  allPlantAssetsAsyncResource = new LoadPlantAssetsInUseResource(() => [
    { dateRange: this.args.overTimeDateRange },
  ]);

  get allPlantAssets() {
    return getAsyncResourceData(this.allPlantAssetsAsyncResource, []);
  }

  @tracked selectedRecipesWithColor: { id: string; color: string }[] = [];

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    // Trigger loading this
    this.allPlantAssets;
  }

  @action
  removeSelectedRecipeId(recipeId: string) {
    const selectedRecipeIds = this.selectedRecipesWithColor.map(
      (item) => item.id
    );

    removeItem(selectedRecipeIds, recipeId);

    this.updateSelectedRecipeIdsTask.perform(selectedRecipeIds);
  }

  @action
  resetSelectedRecipeIds() {
    this.selectedRecipesWithColor = [];
  }

  updateSelectedRecipeIdsTask = restartableTask(
    async (selectedRecipeIds: string[]) => {
      // We use a task here to force the chart to rerender
      // This is kind of necessary as there is an issue in the sub component.
      // See: https://github.com/emberjs/ember.js/issues/18969
      await timeout(1);

      let currentSelectedRecipesWithColor = this.selectedRecipesWithColor;

      // We want to update the selected recipes, but keep previously set colors
      // Otherwise, the colors would always change when removing a recipe from the middle (for all following recipes)
      // This is a bit tricky...
      let selectedRecipesWithOptionalColor: {
        id: string;
        color?: string;
      }[] = selectedRecipeIds.map((id) => {
        let existing = currentSelectedRecipesWithColor.find(
          (item) => item.id === id
        );
        if (existing) {
          return existing;
        }

        return {
          id,
          color: undefined,
        };
      });

      // Now add new colors...
      // We want to start colors after # of plant assets, as this means we will not use the same colors as the per-roaster chart
      let allPlantAssetsCount = this.allPlantAssets.length;
      let usedColors = selectedRecipesWithOptionalColor.map(
        (item) => item.color
      );

      let remainingColors = chartColors
        .slice(allPlantAssetsCount)
        .filter((color) => !usedColors.includes(color));

      let selectedRecipesWithColor = selectedRecipesWithOptionalColor.map(
        (item) => {
          let { id, color: optionalColor } = item;

          if (optionalColor) {
            return { id, color: optionalColor };
          }

          let color = remainingColors.shift()!;

          // If you select too many records, the colors repeat
          // This is not really feasible to differentiate, but we need to handle it _somehow_
          if (remainingColors.length === 0) {
            remainingColors = chartColors.slice();
          }

          return { id, color };
        }
      );

      this.selectedRecipesWithColor = selectedRecipesWithColor;

      this.analytics.addEvent('kpi-details-select-recipe', [
        { name: 'selectedCount', value: selectedRecipesWithColor.length },
      ]);
    }
  );
}
