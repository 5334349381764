/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import { isTesting } from '@embroider/macros';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { restartableTask, timeout } from 'ember-concurrency';
import { SortDirection } from 'fabscale-app/models/enums/sort-options';
import { TIMEOUTS } from 'fabscale-app/utilities/fixtures/timeouts';
import { TableColumnDefinition } from 'fabscale-app/models/table-column-definition';

interface Args {
  sortOptions: {
    sortBy: string;
    sortDirection: SortDirection;
    defaultSortBy: string;
    defaultSortDirection: SortDirection;
    updateSort: (sortBy: string) => void;
    externalSort: boolean;
  };
  columns: TableColumnDefinition[];
  tableData?: { [key: string]: any };
  hideCompactSort?: boolean;
}

export default class UiDataTableList extends Component<Args> {
  @tracked tableElement?: HTMLElement;
  @tracked tableContainerElement?: HTMLElement;
  @tracked isCompact = false;

  // By default, we always want to use the non-compact form in tests, to ensure consistency
  allowCompact = !isTesting();

  get tableData() {
    let { isCompact } = this;
    let { tableData } = this.args;

    return Object.assign({ isCompact }, tableData);
  }

  get hideCompactSort() {
    const { hideCompactSort } = this.args;

    return hideCompactSort;
  }

  constructor(owner: any, args: Args) {
    super(owner, args);

    assert(
      `You have to pass an array of TableColumnDefinition as @columns`,
      Array.isArray(args.columns) &&
        !args.columns.some(
          (column) => !(column instanceof TableColumnDefinition)
        )
    );
  }

  @action
  onResizeTable({
    element,
    runCount,
  }: {
    element: HTMLElement;
    runCount: number;
  }) {
    if (runCount === 0) {
      // Initial run
      this.tableContainerElement = element;
      this.tableElement = element.querySelector('.table')!;
      this._checkIsCompact();
      return;
    }

    this.recalculateTableSizeTask.perform();
  }

  @action
  startOrStopPrinting() {
    this.recalculateTableSizeTask.perform();
  }

  recalculateTableSizeTask = restartableTask(async () => {
    // Reset first, to check again...
    this.isCompact = false;

    // Wait for it to render...
    await timeout(1);

    // Check if compact
    this._checkIsCompact();
  });

  columnsChangedTask = restartableTask(async () => {
    // Wait for everything to settle
    // NOTE: If we'd directly use recalculateTableSizeTask here, without waiting,
    // We would sometimes get race conditions when the columns change and the table width also changes
    // So we gate this here by waiting a bit
    await timeout(TIMEOUTS.chartResizeDebounce);

    this.recalculateTableSizeTask.perform();
  });

  _checkIsCompact() {
    if (!this.allowCompact) {
      return;
    }

    let { tableElement, tableContainerElement } = this;

    if (!tableElement || !tableContainerElement) {
      return;
    }

    let tableContentWidth = tableElement.offsetWidth;
    let tableContainerWidth = tableContainerElement.offsetWidth;

    this.isCompact = tableContentWidth > tableContainerWidth;
  }
}
