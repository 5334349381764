/* import __COLOCATED_TEMPLATE__ from './users-table.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { filterRecords } from 'fabscale-app/utilities/utils/filter-records';
import UserSessionService from 'fabscale-app/services/user-session';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { User } from 'fabscale-app/models/user';
import ScreenService from 'fabscale-app/services/screen';
import { TableColumnDefinitionInput } from 'fabscale-app/models/table-column-definition';
import CellUserName from 'fabscale-app/components/page/settings/company-users/cells/user-name';
import CellStatus from 'fabscale-app/components/page/settings/company-users/cells/status';
import CellRole from 'fabscale-app/components/page/settings/company-users/cells/role';
import CellDetails from 'fabscale-app/components/page/settings/company-users/cells/details';
import CellActions from 'fabscale-app/components/page/settings/company-users/cells/actions';
import CellLocations from 'fabscale-app/components/page/settings/company-users/cells/locations';
import { AbilitiesService } from 'ember-can';

interface Args {
  users: User[];
}

export default class SettingsCompanyUsersUsersList extends Component<Args> {
  @service userSession: UserSessionService;
  @service l10n: L10nService;
  @service screen: ScreenService;
  @service abilities: AbilitiesService;

  @tracked searchTerm?: string;

  get filteredUsers() {
    let { users } = this.args;
    let { searchTerm } = this;

    if (!searchTerm) {
      return users;
    }

    return filterRecords(users, searchTerm, {
      propertyNames: ['name', 'email'],
    });
  }

  get userColumns() {
    let { l10n, abilities } = this;
    let { isMobile } = this.screen;

    let userCols: TableColumnDefinitionInput[] = isMobile
      ? [
          {
            title: l10n.t('User'),
            propertyName: 'name',
          },
          {
            title: l10n.t('Email'),
            propertyName: 'email',
          },
        ]
      : [
          {
            title: l10n.t('User'),
            propertyName: 'name',
            component: CellUserName,
            noCompactTitle: true,
          },
        ];

    userCols = userCols.concat([
      {
        title: l10n.t('Status'),
        propertyName: 'status',
        component: CellStatus,
      },
      {
        title: l10n.t('Role'),
        propertyName: 'role',
        sortBy: 'role.name',
        component: CellRole,
        tdClass: 'nowrap',
      },
    ]);

    if (abilities.can('have multiple locations in company')) {
      userCols.push({
        title: l10n.t('Plants'),
        propertyName: 'locations',
        sortBy: 'locations.length',
        component: CellLocations,
        tdClass: 'nowrap',
      });
    }

    if (abilities.can('edit user')) {
      userCols.push({
        component: CellActions,
        tdClass: 'nowrap mobile-width-100p',
      });
    }

    userCols.push({
      component: CellDetails,
      tdClass: 'nowrap mobile-order-first',
    });

    return userCols;
  }

  get currentUserEmail() {
    return this.userSession.user!.email;
  }

  @action
  filterData(searchTerm: string) {
    this.searchTerm = searchTerm;
  }
}
