/* import __COLOCATED_TEMPLATE__ from './area.hbs'; */
import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';
import { guidFor } from '@ember/object/internals';

interface Args {
  svgAreaD: string;
  color: string;
}

export default class LineChartArea extends Component<Args> {
  guid = guidFor(this);

  get gradientColorDefinitionStyle() {
    return htmlSafe(`stop-color: ${this.args.color};`);
  }
}
