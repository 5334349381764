/* import __COLOCATED_TEMPLATE__ from './duration.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { UnitTime } from 'fabscale-app/models/enums/units';
import { logError } from 'fabscale-app/utilities/utils/log-error';
import {
  getTimeValue,
  parseTime,
  ParseTimeError,
} from 'fabscale-app/utilities/utils/parse-time';

interface Args {
  value?: number;
  unit: UnitTime;
  expectHours?: boolean;
  onChange: (value: number | undefined) => void;
}

export default class FormDuration extends Component<Args> {
  get expectHours() {
    return this.args.expectHours || false;
  }

  @action
  updateDuration(duration: string) {
    try {
      let timeParts = parseTime(duration, { expectHours: this.expectHours });
      let value = getTimeValue(timeParts);
      let convertedValue = convertSeconds(value, this.args.unit);

      this.args.onChange(convertedValue);
    } catch (error) {
      // If the entered time is invalid, we want to reset the value to the previous value
      // This will trigger a re-render and thus reset the input field value
      if (error instanceof ParseTimeError) {
        this.args.onChange(this.args.value);
        return;
      }

      logError(error);
    }
  }
}

function convertSeconds(seconds: number, unit: UnitTime) {
  if (unit === 'MILLISECOND') {
    return seconds * 1000;
  }

  if (unit === 'MINUTE') {
    return seconds / 60;
  }

  if (unit === 'HOUR') {
    return seconds / 60 / 60;
  }

  return seconds;
}
