/* import __COLOCATED_TEMPLATE__ from './checkboxes.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { removeItem } from 'fabscale-app/utilities/utils/array';

interface Args {
  value?: any[];
  onChange: (value: any[]) => void;
}

export default class FormCheckboxes extends Component<Args> {
  @action
  onToggle(option: any, isChecked: boolean) {
    let value = this.args.value ? this.args.value.slice() : [];

    if (isChecked && !value.includes(option)) {
      value.push(option);
    }

    if (!isChecked && value.includes(option)) {
      removeItem(value, option);
    }

    this.args.onChange(value);
  }
}
