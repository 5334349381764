/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { restartableTask, timeout } from 'ember-concurrency';

export default class SiteWrapperDesktopIndex extends Component<{
  Args: {
    isPinned: boolean;
    pin: () => void;
    unpin: () => void;
  };
}> {
  @tracked overlayIsActive = false;

  @action
  showOverlay() {
    this.overlayIsActive = true;
    this.closeOverlayDebounced.cancelAll();
  }

  @action
  closeOverlay() {
    this.overlayIsActive = false;
  }

  closeOverlayDebounced = restartableTask(async () => {
    await timeout(350);

    this.overlayIsActive = false;
  });

  @action
  unpin() {
    this.args.unpin();
    this.closeOverlay();
  }
}
