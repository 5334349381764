/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class PageDashboardCustomizeHeader extends Component {
  @service router: RouterService;

  @action
  redirectToDashboard() {
    this.router.transitionTo('routes/dashboard.index');
  }
}
