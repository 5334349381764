/* import __COLOCATED_TEMPLATE__ from './my-tasks.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import { TaskStatus } from 'fabscale-app/models/enums/task-status';
import { MaintenanceTask } from 'fabscale-app/models/maintenance-task';
import PdfService from 'fabscale-app/services/pdf';
import { MaintenanceTaskQueryOptions } from 'fabscale-app/services/store/maintenance-task';
import UserSessionService from 'fabscale-app/services/user-session';
import { DateTime } from 'luxon';

export default class MaintenanceTasksMyTasks extends Component {
  @service userSession: UserSessionService;
  @service pdf: PdfService;

  query: MaintenanceTaskQueryOptions;

  constructor(owner: unknown, args: any) {
    super(owner, args);

    this.query = {
      status: 'OPEN',
      assignedUserIds: [this.userSession.user!.id],
    };
  }

  printPageTask = dropTask(async () => {
    await this.pdf.generateForCurrentPageTask.perform();
  });

  @action
  onToggleStatus(
    tasks: MaintenanceTask[],
    {
      id,
      status,
      completionDate,
    }: {
      id: string;
      status: TaskStatus;
      completionDate?: DateTime;
    }
  ) {
    let task = tasks.find((task) => task.id === id);

    if (task) {
      task.status = status;
      task.completionDate = completionDate;
    }
  }
}
