/* import __COLOCATED_TEMPLATE__ from './lightbox-gallery.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { FileExtensionsWebViewable } from 'fabscale-app/models/enums/file-extension';
import { FileWrapper } from 'fabscale-app/models/file-wrapper';

interface Args {
  selectedFile: FileWrapper;
  allFiles: FileWrapper[];
  close: () => void;
  selectFile: (file: FileWrapper) => void;
}

interface LightboxImage {
  name: string;
  url: string;
}

export default class LightboxGallery extends Component<Args> {
  get selectedImage(): LightboxImage | undefined {
    let { selectedFile } = this.args;
    return this.images.find((image) => image.url === selectedFile.url);
  }

  @cached
  get images(): LightboxImage[] {
    let { allFiles } = this.args;

    let imageFiles = allFiles.filter((file: FileWrapper) =>
      FileExtensionsWebViewable.includes(file.extension)
    );

    return imageFiles.map((file) => {
      let { name, url } = file;

      return {
        name,
        url,
      };
    });
  }

  get canGoNext() {
    return this.images.length > 1;
  }

  get canGoPrevious() {
    return this.images.length > 1;
  }

  @action
  goNext() {
    let { images, selectedImage } = this;

    if (!this.canGoNext) {
      return;
    }

    let nextImageIndex = images.indexOf(selectedImage!) + 1;
    let nextImage = images[nextImageIndex] || images[0]!;

    let file = this.args.allFiles.find((file) => file.url === nextImage.url);

    if (!file) {
      return;
    }

    this.args.selectFile(file);
  }

  @action
  goPrevious() {
    let { images, selectedImage } = this;

    if (!this.canGoPrevious) {
      return;
    }

    let previousImageIndex = images.indexOf(selectedImage!) - 1;
    let previousImage =
      images[previousImageIndex] || images[images.length - 1]!;

    let file = this.args.allFiles.find(
      (file) => file.url === previousImage.url
    );

    if (!file) {
      return;
    }

    this.args.selectFile(file);
  }
}
