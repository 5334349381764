/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { LineChartDataInput } from 'fabscale-app/components/chart';

interface Args {
  rorType: number;
  lineChartData: LineChartDataInput[];
  updateRorType: (rorType: number) => void;
}

export default class RoastBatchesNumericCurveCurveSelect extends Component<Args> {
  @service l10n: L10nService;

  @cached
  get rorTypes() {
    return [
      { value: 0, label: this.l10n.t('No RoR') },
      { value: 5, label: 'RoR (5s)' },
      { value: 10, label: 'RoR (10s)' },
      { value: 15, label: 'RoR (15s)' },
      { value: 30, label: 'RoR (30s)' },
      { value: 60, label: 'RoR (60s)' },
    ];
  }

  get selectedRorType() {
    let { rorType } = this.args;
    return (
      this.rorTypes.find((rorTypeDef) => rorTypeDef.value === rorType) ||
      this.rorTypes[0]
    );
  }

  @action
  updateRorType(rorType: { value: number; label: string }) {
    this.args.updateRorType(rorType.value);
  }
}
