/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import { DateRangeOptional } from 'fabscale-app/models/date-range';
import { KpiDataFilterSettings } from 'fabscale-app/models/kpi-data-filter-settings';
import { RelativeTimeframe } from 'fabscale-app/models/enums/relative-timeframe';
import {
  TimeRange,
  TimeRangeOptional,
  TimeRangeOptionalEnd,
} from 'fabscale-app/models/time-range';
import { KpiCompareReportTableType } from 'fabscale-app/routes/reports/kpi-compare/index/controller';
import AnalyticsService from 'fabscale-app/services/analytics';
import PdfService from 'fabscale-app/services/pdf';
import SettingsService from 'fabscale-app/services/settings';
import { getEndTimeIsoString } from 'fabscale-app/utilities/utils/parse-time';

export interface KpiCompareReportFilters {
  dateRange?: DateRangeOptional;
  relativeTimeframe?: RelativeTimeframe;
  timeRange: TimeRangeOptional;
  plantAssetIds: string[];
  recipeIds: string[];
  kpiTypes: KpiType[];
}

interface Args {
  filterSettings?: KpiDataFilterSettings;
  updateFilterSettings: (filterSettings: KpiDataFilterSettings) => void;
  tableId?: KpiCompareReportTableType;
}

export default class KpiCompareReportIndex extends Component<Args> {
  @service pdf: PdfService;
  @service analytics: AnalyticsService;
  @service settings: SettingsService;

  get filters(): KpiCompareReportFilters {
    let { filterSettings } = this.args;
    let { defaultTimeRange } = this;

    let {
      dateRange,
      relativeTimeframe,
      timeRange,
      kpiTypes = [],
      recipeIds = [],
      plantAssetIds = [],
    } = filterSettings || {};

    return {
      dateRange,
      relativeTimeframe,
      timeRange: Object.assign({}, defaultTimeRange, timeRange),
      kpiTypes,
      plantAssetIds,
      recipeIds,
    };
  }

  get timeRange(): TimeRange {
    let timeRange = Object.assign(
      {},
      this.defaultTimeRange,
      this.args.filterSettings?.timeRange
    );

    return timeRange;
  }

  get defaultTimeRange() {
    let { dayStartTime } = this.settings.locationSettings;
    let end = getEndTimeIsoString(dayStartTime);

    return { start: dayStartTime, end };
  }

  get hasAllRequiredData() {
    return Boolean(
      this.filters.dateRange?.start &&
        this.filters.dateRange?.end &&
        this.filters.kpiTypes.length > 0
    );
  }

  @action
  updateFilters(filters: KpiCompareReportFilters) {
    let {
      kpiTypes,
      dateRange,
      relativeTimeframe,
      timeRange,
      plantAssetIds,
      recipeIds,
    } = filters;

    let filterSettings = new KpiDataFilterSettings({
      absoluteTimeframe: relativeTimeframe ? undefined : dateRange,
      relativeTimeframe,
      kpiTypes,
      timeRange: timeRange as TimeRangeOptionalEnd,
      recipeIds,
      plantAssetIds,
    });

    this.args.updateFilterSettings(filterSettings);
  }
}
