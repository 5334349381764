/* import __COLOCATED_TEMPLATE__ from './grouped-list.hbs'; */
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { TaskStatus } from 'fabscale-app/models/enums/task-status';
import { MaintenanceTask } from 'fabscale-app/models/maintenance-task';
import { sortBy } from 'fabscale-app/utilities/utils/array';
import getColorForPlantAsset from 'fabscale-app/utilities/utils/get-color-for-plant-asset';
import { DateTime } from 'luxon';

interface Args {
  tasks: MaintenanceTask[];
}

interface TaskGroup {
  id?: string;
  label: string;
  color: string | undefined;
  tasks: MaintenanceTask[];
}

export default class TaskCalendarDayGroupedList extends Component<Args> {
  @service l10n: L10nService;

  get groupedTasks() {
    let { tasks } = this.args;
    let { l10n } = this;

    let groups: TaskGroup[] = [];

    let otherGroup: TaskGroup = {
      id: undefined,
      label: l10n.t('Other'),
      color: undefined,
      tasks: [],
    };

    tasks.forEach((task) => {
      let plantAsset = task.plantAsset;

      if (!plantAsset) {
        otherGroup.tasks.push(task);
        return;
      }

      let group = groups.find((group) => group.id === plantAsset!.id);

      if (group) {
        group.tasks.push(task);
      } else {
        let group = {
          id: plantAsset.id,
          label: plantAsset.name,
          color: getColorForPlantAsset(plantAsset),
          tasks: [task],
        };
        groups.push(group);
      }
    });

    let sortedGroups = sortBy(groups, 'label');

    if (otherGroup.tasks.length > 0) {
      sortedGroups.push(otherGroup);
    }

    return sortedGroups;
  }

  @action
  onToggleStatus({
    id,
    status,
    completionDate,
  }: {
    id: string;
    status: TaskStatus;
    completionDate?: DateTime;
  }) {
    let task = this.args.tasks.find((task) => task.id === id);

    if (task) {
      task.status = status;
      task.completionDate = completionDate;
    }
  }
}
