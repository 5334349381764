/* import __COLOCATED_TEMPLATE__ from './attachments.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';
import { FileExtensions } from 'fabscale-app/models/enums/file-extension';
import { FileWrapper } from 'fabscale-app/models/file-wrapper';
import { MaintenanceTask } from 'fabscale-app/models/maintenance-task';
import StoreMaintenanceTaskService from 'fabscale-app/services/store/maintenance-task';
import GraphQLService from 'fabscale-app/services/-graphql';
import { removeItem } from 'fabscale-app/utilities/utils/array';

interface Args {
  task: MaintenanceTask;
}

export default class MaintenanceTasksTaskDetailsAttachments extends Component<Args> {
  @service('store/maintenance-task')
  maintenanceTaskManagerStore: StoreMaintenanceTaskService;

  @service graphql: GraphQLService;

  @tracked files: FileWrapper[] = [];
  allowedExtensions = FileExtensions;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.files = this.args.task.files;
  }

  @action
  triggerFileUpload(fileInputId: string) {
    let input = document.querySelector(`#${fileInputId}`) as HTMLInputElement;
    input.click();
  }

  removeFileTask = task(async (file: FileWrapper) => {
    await this.maintenanceTaskManagerStore.removeFileFromMaintenanceTask(
      this.args.task.id,
      file.id
    );

    // Wait for a tick before this is removed, to avoid a cyclical task cancellation error
    this._removeFileFromListTask.perform(file);
  });

  _removeFileFromListTask = task(async (file: FileWrapper) => {
    await timeout(1);

    let files = this.files.slice();
    removeItem(files, file);
    this.files = files;
  });

  @action
  onFileUpdated() {
    this.graphql.invalidateCache([
      {
        cacheEntity: 'MaintenanceTask',
        cacheId: this.args.task.id,
      },
    ]);
  }

  addFileToMaintenanceTaskTask = task(async (file: FileWrapper) => {
    let taskId = this.args.task.id;

    await this.maintenanceTaskManagerStore.addFileToMaintenanceTask(
      taskId,
      file.id
    );

    let files = this.files.slice();
    files.push(file);
    this.files = files;
  });
}
