export default class IntersectionObserverProxy {
  _intersectionObserver;
  _elementData = new WeakMap();
  _elementWasEverVisible = new WeakMap();

  constructor(options: { intersectionObserver: IntersectionObserver }) {
    this._intersectionObserver = options.intersectionObserver;
  }

  observeElement(element: Element, ...args: any[]) {
    this._intersectionObserver.observe(element);
    this._elementData.set(element, args);
  }

  unobserveElement(element: Element) {
    this._intersectionObserver.unobserve(element);
    this._elementData.delete(element);
  }

  getElementData(element: Element) {
    return this._elementData.get(element);
  }

  elementWasVisible(element: Element) {
    this._elementWasEverVisible.set(element, true);
  }

  elementWasEverVisible(element: Element) {
    return this._elementWasEverVisible.has(element);
  }

  disconnect() {
    this._intersectionObserver.disconnect();
  }
}
