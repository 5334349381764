/* import __COLOCATED_TEMPLATE__ from './y-axis.hbs'; */
import { action } from '@ember/object';
import { isTesting, macroCondition } from '@embroider/macros';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { AxisScale } from 'd3-axis';
import { select } from 'd3-selection';
import 'd3-transition';
import { Resource, use } from 'ember-could-get-used-to-this';

interface Args {
  axis: AxisScale<any>;
  xOffset?: number;
  axisWidth: number;
  yScaleBandwidth: number;
}

interface AxisArgs {
  axis: AxisScale<any>;
  container: HTMLElement;
  axisWidth: number;
  yScaleBandwidth: number;
}

export default class ChartAxis extends Component<Args> {
  @tracked container: HTMLElement;

  @use d3Axis = new ChartAxisResource(() => [this.options]);

  get options(): AxisArgs {
    return {
      axis: this.args.axis,
      container: this.container,
      axisWidth: this.args.axisWidth,
      yScaleBandwidth: this.args.yScaleBandwidth,
    };
  }

  @action
  renderD3Axis(container: HTMLElement) {
    this.container = container;
  }
}

type PositionalArgs = [options: AxisArgs];

class ChartAxisResource extends Resource<PositionalArgs, any> {
  setup() {
    let [options] = this.args.positional;

    select(options.container)
      .call(options.axis)
      .call((selection) =>
        this._fixAxis(
          selection.selectAll('.tick').nodes() as HTMLElement[],
          options
        )
      );
  }

  update() {
    let [options] = this.args.positional;

    let transitionTime = macroCondition(isTesting()) ? 0 : 200;

    select(options.container)
      .transition()
      .duration(transitionTime)
      .call(options.axis)
      .call((selection) =>
        this._fixAxis(
          selection.selectAll('.tick').nodes() as HTMLElement[],
          options
        )
      );
  }

  /*
    SVG is not good at handling multi-line text (or any text, really)
    So if we want to make sure labels wrap properly, we need to do that with HTML
    Luckily, we can use <foreignObject> to insert a <div> inside, where we can use regular CSS
    While this is a bit "hacky", it works fine in embedding the div in the SVG context (positioning, ...)
  */
  _fixAxis(ticks: HTMLElement[], options: AxisArgs) {
    let { axisWidth, yScaleBandwidth } = options;

    let yOffset = yScaleBandwidth / -2;

    ticks.forEach((g) => {
      let label = g.querySelector('text')?.textContent;

      if (!label) {
        return;
      }

      g.innerHTML = `
<foreignObject x="0" y="${yOffset}" width="${axisWidth}" height="${yScaleBandwidth}">
  <div xmlns="http://www.w3.org/1999/xhtml" class="timeline-bar-chart-y-label">
      ${label}
  </div>
</foreignObject>`;
    });
  }
}
