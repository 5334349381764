/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { LabSample } from 'fabscale-app/models/lab-sample';

interface Args {
  labSampleUpdate: (labSample: LabSample) => void;
}

export default class RoastPicDetails extends Component<Args> {
  @action
  onLabSampleUpdate(labSample: LabSample) {
    this.args.labSampleUpdate(labSample);
  }
}
