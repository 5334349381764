/* import __COLOCATED_TEMPLATE__ from './hover-line.hbs'; */
import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';
import { ChartTooltipData } from 'fabscale-app/components/chart';
import { cached } from '@glimmer/tracking';

interface Args {
  containerElement: HTMLElement;
  tooltipItem: ChartTooltipData;
}

export default class ChartLineChartWithTooltipHoverLine extends Component<Args> {
  @cached
  get circleElementItems() {
    let { domElements } = this.args.tooltipItem;
    let { y: containerY } = this._getContainerPosition();
    let { xPos } = this.args.tooltipItem;

    return domElements.map((circle) => {
      let boundingClientRect = circle.getBoundingClientRect();
      return {
        color: circle.getAttribute('data-chart-line-circle-color'),
        xPos,
        yPos: boundingClientRect.y + boundingClientRect.height / 2 - containerY,
      };
    });
  }

  get circlePositions() {
    return this.circleElementItems.map((circlePosition) => {
      let parts = [
        `left: ${circlePosition.xPos}px;`,
        `top: ${circlePosition.yPos}px;`,
        `background-color: ${circlePosition.color};`,
      ];

      return {
        style: htmlSafe(parts.join(' ')),
      };
    });
  }

  get lineStyle() {
    let { tooltipItem } = this.args;

    if (!tooltipItem) {
      return;
    }

    let { xPos, yPos } = tooltipItem;

    let parts = [`left: ${xPos}px;`, `top: ${yPos}px;`];
    return htmlSafe(parts.join(' '));
  }

  _getContainerPosition() {
    let { x, y } = this.args.containerElement.getBoundingClientRect();
    return { x, y };
  }
}
