/* import __COLOCATED_TEMPLATE__ from './after-options.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';

interface Args {
  select: any;
  extra: {
    isMultiSelect: boolean;
    allowClear: boolean;
    label: string;
  };
}

export default class UiSelectAfterOptions extends Component<Args> {
  @action
  clearSelection(): boolean {
    this.args.select.actions.select(this.args.extra.isMultiSelect ? [] : null);

    return false;
  }
}
