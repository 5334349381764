/* import __COLOCATED_TEMPLATE__ from './area-create.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { PlantAssetAreaType } from 'fabscale-app/models/enums/plant-asset-area-types';
import { PlantAsset } from 'fabscale-app/models/plant-asset';
import StorePlantAssetService from 'fabscale-app/services/store/plant-asset';

interface Args {
  plantAsset: PlantAsset;
}

export default class PlantPlantAssetsAreaCreate extends Component<Args> {
  @service('store/plant-asset') plantAssetStore: StorePlantAssetService;

  @tracked createdPlantAssetArea?: { id: string; name: string };

  createPlantAssetAreaTask = dropTask(
    async (
      data: {
        name: string;
        type: PlantAssetAreaType;
        description?: string;
      },
      { plantAssetId }: { plantAssetId: string }
    ) => {
      this.createdPlantAssetArea = undefined;

      let { id } = await this.plantAssetStore.createArea(data, {
        plantAssetId,
      });

      this.createdPlantAssetArea = { id, name: data.name };
    }
  );
}
