/* import __COLOCATED_TEMPLATE__ from './nav-second.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { DateTime } from 'luxon';

interface Args {
  centerMonth: DateTime;
  nextMonth: DateTime;
  updateCenterMonth: (date: DateTime) => void;
  maxDate?: DateTime;
  minDate?: DateTime;
}

export default class DateRangePickerNavSecond extends Component<Args> {
  get canGoNext() {
    let { maxDate, centerMonth } = this.args;

    return !maxDate || maxDate.startOf('month') > centerMonth.startOf('month');
  }

  @action
  gotoNextMonth() {
    let newCenter = this.args.centerMonth.plus({ months: 1 }).startOf('month');
    this.args.updateCenterMonth(newCenter);
  }
}
