/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { PlantAssetStatus } from 'fabscale-app/models/enums/plant-asset-status';
import { Unit } from 'fabscale-app/models/enums/units';
import { TableColumnDefinitionInput } from 'fabscale-app/models/table-column-definition';
import CustomAnalyticsService from 'fabscale-app/services/analytics';
import ExcelService, { ExcelAmountCell } from 'fabscale-app/services/excel';
import { filterRecords } from 'fabscale-app/utilities/utils/filter-records';
import { DateTime } from 'luxon';

export type StatusMapReportDetailTableData = {
  label: string | DateTime;
  searchLabel: string;
} & {
  [key in PlantAssetStatus]?: ValueUnitPair;
};

interface ValueUnitPair {
  value: number;
  unit: Unit;
}

interface Args {
  data: StatusMapReportDetailTableData[];
  columns: TableColumnDefinitionInput[];
}

export default class StatusMapReportDetailsTableOverTime extends Component<Args> {
  @service l10n: L10nService;
  @service excel: ExcelService;
  @service analytics: CustomAnalyticsService;

  @tracked searchTerm?: string;

  get filteredData() {
    let { data } = this.args;
    let { searchTerm } = this;

    if (!searchTerm) {
      return data;
    }

    return filterRecords(data, searchTerm, { propertyName: 'searchLabel' });
  }

  downloadExcel = dropTask(async () => {
    let { data, columns } = this.args;
    let { excel, l10n } = this;

    let rows = data.map((record) => {
      // eslint-disable-next-line unused-imports/no-unused-vars
      let { label, searchLabel, ...fields } = record;

      let row: { label: string | DateTime } & {
        [key in PlantAssetStatus]?: ExcelAmountCell;
      } = {
        label,
      };

      Object.keys(fields).forEach((field) => {
        let valueUnitPair = fields[field as PlantAssetStatus] as ValueUnitPair;

        row[field as PlantAssetStatus] = new ExcelAmountCell({
          amount: valueUnitPair.value,
          unit: valueUnitPair.unit,
        });
      });

      return row;
    });

    let sheetColumns = columns.map((column) => {
      let title = column.title!;
      let propertyName = column.propertyName!;

      let id = propertyName.replace('.value', '');

      return { header: title, id };
    });

    let sheetConfig = {
      sheetName: l10n.t('Fabscale report data'),
      autoFilter: true,
      columns: sheetColumns,
      rows,
    };

    this.analytics.addEvent('status-map-report-download-excel');

    await excel.create(sheetConfig);
  });

  @action
  updateSearchTerm(searchTerm: string) {
    this.searchTerm = searchTerm;
  }
}
