import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { LabSample } from 'fabscale-app/models/lab-sample';
import { action } from '@ember/object';
export type RoastPicDetailPageTab = 'PICTURE_DATA' | 'SIZE' | 'COLOR';
export type RoastPicCompareModel = {
  firstLabSample: LabSample;
  secondLabSample: LabSample;
};
export default class RoastPicCompareController extends Controller {
  declare model: RoastPicCompareModel;
  queryParams = ['tab'];

  @tracked tab?: RoastPicDetailPageTab = 'PICTURE_DATA';

  @action
  updateFirstLabSample(labSample: LabSample) {
    this.model.firstLabSample = labSample;
  }

  @action
  updateSecondLabSample(labSample: LabSample) {
    this.model.secondLabSample = labSample;
  }
}
