/* import __COLOCATED_TEMPLATE__ from './server-paginated.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { task, timeout } from 'ember-concurrency';
import { PageInfo } from 'fabscale-app';

interface Args {
  pageInfo: PageInfo;
  updatePage: (page: number) => void;
  updatePageSize: (page: number) => void;
}

export default class UiDataTableServerPaginated extends Component<Args> {
  containerElement?: HTMLElement;

  get page() {
    return this.args.pageInfo.page;
  }

  get pageSize() {
    return this.args.pageInfo.pageSize;
  }

  get totalPages() {
    return this.args.pageInfo.totalPages;
  }

  get totalItemCount() {
    return this.args.pageInfo.totalItemCount;
  }

  @action
  movePrevious() {
    this.args.updatePage(this.page - 1);
    this._scrollToTableTopTask.perform();
  }

  @action
  moveNext() {
    this.args.updatePage(this.page + 1);
    this._scrollToTableTopTask.perform();
  }

  @action
  moveTo(newPage: number) {
    this.args.updatePage(newPage);
    this._scrollToTableTopTask.perform();
  }

  @action
  updatePageSize(pageSize: number) {
    this.args.updatePageSize(pageSize);
    this._scrollToTableTopTask.perform();
  }

  @action
  registerContainerElement(element: HTMLElement) {
    this.containerElement = element;
  }

  _scrollToTableTopTask = task(async () => {
    await timeout(1);

    if (!this.containerElement) {
      return;
    }

    this.containerElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  });
}
