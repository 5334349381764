/* import __COLOCATED_TEMPLATE__ from './repeat-config.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { restartableTask, timeout } from 'ember-concurrency';
import { Interval } from 'fabscale-app/models/enums/intervals';
import { DateTime } from 'luxon';
import { RepeatConfigFormData } from '.';

interface Args {
  dueDate?: DateTime;
  formData: RepeatConfigFormData;
  updateTimeAmount: (amount: number | undefined) => void;
}

export default class PageMaintenanceTasksTaskFormRepeatConfig extends Component<Args> {
  availableIntervals: Interval[] = ['DAY', 'WEEK', 'MONTH', 'YEAR'];

  @action
  updateTimeAmount(amount?: number) {
    // Special case: if it is the same value as before, set it to undefined shortly and then back to the value
    // To ensure the input field updates
    if (amount === this.args.formData.timeAmount) {
      this._updateRepeatConfigTimeAmountForcedTask.perform(amount);
    } else {
      this.args.updateTimeAmount(amount);
    }
  }

  _updateRepeatConfigTimeAmountForcedTask = restartableTask(
    async (amountNum?: number) => {
      this.args.updateTimeAmount(
        typeof amountNum === 'undefined' ? 0 : undefined
      );

      await timeout(1);

      this.args.updateTimeAmount(amountNum);
    }
  );
}
