/* import __COLOCATED_TEMPLATE__ from './alerts-table.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { isTesting } from '@embroider/macros';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask, timeout } from 'ember-concurrency';
import {
  PlantAssetAlarmSortOption,
  SortDirection,
} from 'fabscale-app/models/enums/sort-options';
import ScreenService from 'fabscale-app/services/screen';

const DEFAULT_SORT_BY = 'RECORDING_DATE';
const DEFAULT_SORT_DIRECTION = 'DESC';

interface Args {
  page?: number;
  pageSize?: number;
  sortBy?: PlantAssetAlarmSortOption;
  sortDirection?: SortDirection;
  updatePage: (page?: number) => void;
  updatePageSize: (pageSize?: number) => void;
  updateSortBy: (sortBy?: PlantAssetAlarmSortOption) => void;
  updateSortDirection: (sortDirection?: SortDirection) => void;
}

export default class PlantAssetAlertsOverviewRoastBatchesTable extends Component<Args> {
  @service screen: ScreenService;

  get page() {
    return this.args.page || 1;
  }

  get pageSize() {
    return this.args.pageSize || 25;
  }

  get sortBy() {
    return this.args.sortBy || DEFAULT_SORT_BY;
  }

  get sortDirection() {
    return this.args.sortDirection || DEFAULT_SORT_DIRECTION;
  }

  @tracked _shouldAutoRefresh: boolean;

  get shouldAutoRefresh() {
    return isTesting() || this.screen.isMobile
      ? false
      : this._shouldAutoRefresh;
  }

  set shouldAutoRefresh(shouldAutoRefresh: boolean) {
    this._shouldAutoRefresh = shouldAutoRefresh;
  }

  get isDefaultSortAndPage() {
    return this.page === 1;
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.shouldAutoRefresh = this.isDefaultSortAndPage;
  }

  @action
  updateSort(sortBy: PlantAssetAlarmSortOption, sortDirection: SortDirection) {
    if (sortBy !== this.args.sortBy) {
      this.args.updateSortBy(sortBy);
    }

    if (sortDirection !== this.args.sortDirection) {
      this.args.updateSortDirection(sortDirection);
    }

    if (
      sortBy !== DEFAULT_SORT_BY ||
      sortDirection !== DEFAULT_SORT_DIRECTION
    ) {
      this.disableAutoRefresh();
    } else {
      this.enableAutoRefreshIfHasDefaultsTask.perform();
    }
  }

  @action
  updatePage(page: number) {
    this.args.updatePage(page);

    if (page > 1) {
      this.disableAutoRefresh();
    } else {
      this.enableAutoRefresh();
    }
  }

  @action
  updatePageSize(pageSize: number) {
    this.args.updatePageSize(pageSize);
    this.args.updatePage(undefined);
  }

  @action
  enableAutoRefresh() {
    if (!this.shouldAutoRefresh) {
      this.shouldAutoRefresh = true;
    }

    // Ensure this is reset
    this.args.updatePage(undefined);
    this.args.updateSortBy(undefined);
    this.args.updateSortDirection(undefined);
  }

  @action
  disableAutoRefresh() {
    if (this.shouldAutoRefresh) {
      this.shouldAutoRefresh = false;
    }
  }

  enableAutoRefreshIfHasDefaultsTask = dropTask(async () => {
    if (this.isDefaultSortAndPage && !this.shouldAutoRefresh) {
      // Wait a tick to ensure we do not run into re-rendering-after-read issues
      await timeout(1);
      this.shouldAutoRefresh = true;
    }
  });
}
