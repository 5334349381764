/* import __COLOCATED_TEMPLATE__ from './general.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { TaskCategories } from 'fabscale-app/models/enums/task-categories';
import { TaskRequirements } from 'fabscale-app/models/enums/task-requirements';
import { FormDataModel } from 'fabscale-app/models/form-data';
import { PlantAsset } from 'fabscale-app/models/plant-asset';
import { PlantAssetArea } from 'fabscale-app/models/plant-asset-area';
import { UserInfo } from 'fabscale-app/models/user-info';
import { FormData } from './index';

interface Args {
  formData: FormData;
  formModel: FormDataModel<FormData>;
  updateAssignedUserId: (assignedUserId: string | undefined) => void;
  updatePlantAssetId: (plantAssetId: string | undefined) => void;
  updatePlantAssetAreaId: (plantAssetAreaId: string | undefined) => void;
  updateEstimatedDurationSeconds: (
    estimatedDuration: number | undefined
  ) => void;
}

export default class PageMaintenanceTasksTaskForm extends Component<Args> {
  availableCategories = TaskCategories;
  availableRequirements = TaskRequirements;

  @action
  updateAssignedUser(assignedUser?: UserInfo) {
    this.args.updateAssignedUserId(assignedUser?.id);
  }

  @action
  updatePlantAsset(plantAsset?: PlantAsset) {
    this.args.updatePlantAssetId(plantAsset?.id);
    this.args.updatePlantAssetAreaId(undefined);
  }

  @action
  updatePlantAssetArea(plantAssetArea?: PlantAssetArea) {
    this.args.updatePlantAssetAreaId(plantAssetArea?.id);
  }

  @action
  updateEstimatedDurationSeconds(estimatedDurationSeconds?: number) {
    this.args.updateEstimatedDurationSeconds(
      estimatedDurationSeconds || undefined
    );
  }
}
