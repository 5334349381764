/* import __COLOCATED_TEMPLATE__ from './density-card.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { LabSample } from 'fabscale-app/models/lab-sample';

interface Args {
  labSample: LabSample;
  labSampleUpdate: (labSample: LabSample) => void;
}

export default class RoastPicDetailsDensityCard extends Component<Args> {
  @tracked editMode: boolean;

  @action
  startEditMode() {
    this.editMode = true;
  }

  @action
  cancelEditMode() {
    this.editMode = false;
  }

  @action
  onLabSampleUpdate(labSample: LabSample) {
    this.args.labSampleUpdate(labSample);
    this.editMode = false;
  }

  get density() {
    return this.args.labSample.density
      ? this.args.labSample.density + ' g/cm³'
      : '-';
  }
}
