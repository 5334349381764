/* import __COLOCATED_TEMPLATE__ from './line-group.hbs'; */
import Component from '@glimmer/component';
import { guidFor } from '@ember/object/internals';
import { LineChartData } from 'fabscale-app/components/chart';
import { ScaleLinear, ScaleTime } from 'd3-scale';

interface Args {
  lineItems: LineChartData[];
  label: string;
  lineGenerator: (items: LineChartData[]) => string;
  areaGenerator: (items: LineChartData[]) => string;
  xScale: ScaleTime<Date, number>;
  yScale: ScaleLinear<number, number>;
  color: string;
  highlightedLabel?: string;
  showArea?: boolean;
}

export default class LineChartLineGroup extends Component<Args> {
  guid = guidFor(this);

  get isHighlighted() {
    return this.args.highlightedLabel === this.args.label;
  }

  get otherIsHighlighted() {
    return Boolean(this.args.highlightedLabel) && !this.isHighlighted;
  }

  get svgPathD() {
    let { lineItems, lineGenerator } = this.args;
    return lineGenerator(lineItems);
  }

  get svgAreaD() {
    let { lineItems, areaGenerator } = this.args;
    return areaGenerator(lineItems);
  }

  get svgCirclePositions() {
    let { lineItems, xScale, yScale } = this.args;

    let items = lineItems.map((lineItem) => {
      let x = xScale(lineItem.jsDate) as number;
      let y = yScale(lineItem.value) as number;

      return {
        item: lineItem,
        x,
        y,
      };
    });

    // For the calculation of circle value positions, we also need the previous/next Y positions
    items.forEach((item, i) => {
      let nextItem = items[i + 1];
      let nextY = nextItem?.x;
      let previousItem = items[i - 1];
      let previousY = previousItem?.y;

      Object.assign(item, { nextY, previousY });
    });

    return items;
  }
}
