/* import __COLOCATED_TEMPLATE__ from './set-initial-password.hbs'; */
import Component from '@glimmer/component';
import { FormDataModel } from 'fabscale-app/models/form-data';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { service } from '@ember/service';
import CognitoService from 'fabscale-app/services/cognito';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import UserAuthenticationService from 'fabscale-app/services/user-authentication';
import { InvalidPasswordError } from 'fabscale-app/models/errors/cognito';

interface Args {
  username: string;
  password: string;
}

export default class LoginPageSetInitialPassword extends Component<Args> {
  @service cognito: CognitoService;
  @service l10n: L10nService;
  @service userAuthentication: UserAuthenticationService;

  formData: FormData;
  formModel: FormDataModel<FormData>;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    let { l10n } = this;

    this.formData = new FormData();

    this.formModel = new FormDataModel({
      data: this.formData,
      validations: [
        {
          propertyName: 'newPassword',
          message: l10n.t('The password must not be blank.'),
          validate: (value) => value,
        },
        {
          propertyName: 'newPassword',
          message: l10n.t('The password must be at least 8 characters long.'),
          validate: (value) => value && value.length >= 8,
        },
      ],
    });
  }

  setInitialPasswordTask = dropTask(async () => {
    await this.formModel.validate();

    if (this.formModel.isInvalid) {
      return;
    }

    let { cognito, userAuthentication } = this;
    let { username, password } = this.args;
    let { newPassword } = this.formData;

    try {
      await cognito.setNewPassword({ username, password, newPassword });
    } catch (error) {
      this._handleError(error);
      return;
    }

    userAuthentication.afterLoginMessage = this.l10n.t(
      'Your account was created'
    );

    try {
      await userAuthentication.login({ username, password: newPassword });
    } catch (error) {
      this._handleError(error);
    }
  });

  _handleError(error: any) {
    let { l10n } = this;

    if (error instanceof InvalidPasswordError) {
      this.formModel.addError(
        l10n.t(
          'The provided password is invalid - it needs to be at least 8 characters long.'
        ),
        'newPassword'
      );
      return;
    }

    this.formModel.addError(error.message || l10n.t('An error occurred!'));
  }

  @action
  updateNewPassword(newPassword: string) {
    this.formModel.updateProperty('newPassword', newPassword, {
      debounced: true,
    });
  }
}

class FormData {
  @tracked newPassword = '';
}
