/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import SettingsService from 'fabscale-app/services/settings';
import { dropTask } from 'ember-concurrency';
import { FormDataModel } from 'fabscale-app/models/form-data';
import { DateTime } from 'luxon';
import jsonParse from 'fabscale-app/helpers/json-parse';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import ErrorParserService from 'fabscale-app/services/error-parser';
import { ShiftData } from 'fabscale-app/models/location';
import Transition from '@ember/routing/-private/transition';
import RouterService from '@ember/routing/router-service';

class FormData {
  @tracked isShiftFeatureEnabled: boolean;
  @tracked morningShift: ShiftData;
  @tracked afternoonShift: ShiftData;
  @tracked nightShift: ShiftData;
}

export default class PlantShiftsIndex extends Component {
  @tracked formData: FormData;
  @tracked formModel: FormDataModel<FormData>;
  @tracked showDialog = false;

  @service l10n: L10nService;
  @service settings: SettingsService;
  @service('error-parser') errorParser: ErrorParserService;
  @service router: RouterService;

  private transition?: Transition;
  private shouldAbort = true;

  constructor(owner: unknown, args: any) {
    super(owner, args);

    try {
      const { shiftEnabled, shiftSchedule } = this.settings.locationSettings;
      const parsedShiftSchedule = jsonParse(shiftSchedule);

      let formData = new FormData();
      formData.isShiftFeatureEnabled = shiftEnabled || false;
      formData.morningShift = parsedShiftSchedule.morningShift || {
        isEnabled: false,
        start: '08:00',
        end: '16:00',
      };
      formData.afternoonShift = parsedShiftSchedule.afternoonShift || {
        isEnabled: false,
        start: '16:00',
        end: '24:00',
      };
      formData.nightShift = parsedShiftSchedule.nightShift || {
        isEnabled: false,
        start: '24:00',
        end: '08:00',
      };

      this.formData = formData;
      this.formModel = new FormDataModel({
        data: formData,
        validations: [],
      });
    } catch (error) {
      this.formModel.addError(
        this.l10n.t('Could not retrieve your shift settings')
      );
    }
  }

  willDestroy(): void {
    this.resetRouteHandler();

    super.willDestroy();
  }

  @action
  updateShiftSchedulePart(
    shiftType: 'morningShift' | 'afternoonShift' | 'nightShift',
    shiftDataKey: 'start' | 'end' | 'isEnabled',
    value: DateTime | boolean
  ) {
    let tmpShiftTarget = this.formData[shiftType];
    let tmpSource = {
      [shiftDataKey]: value,
    };

    this.formData[shiftType] = Object.assign(tmpShiftTarget, tmpSource);
    this.formModel.hasChanges = true;
    this.addRouteHandler();
  }

  @action
  onShiftsEnabledToggle() {
    this.formData.isShiftFeatureEnabled = !this.formData.isShiftFeatureEnabled;
    this.formModel.hasChanges = true;
    this.addRouteHandler();
  }

  @action
  hideSuccessMessage() {
    this.formModel.successMessage = undefined;
  }

  @action
  handleOnCancel() {
    this.shouldAbort = true;
    this.showDialog = false;
  }

  @action
  handleOnYes() {
    this.showDialog = false;
    this.shouldAbort = false;
    if (this.transition) {
      this.router.transitionTo(this.transition.to.name);
    }
  }

  submitFormTask = dropTask(async () => {
    let isValid: boolean = await this.formModel.validate();

    if (!isValid) {
      return;
    }

    let { isShiftFeatureEnabled, morningShift, afternoonShift, nightShift } =
      this.formData;

    try {
      const locationShiftSettingsInput = {
        shiftEnabled: isShiftFeatureEnabled,
        shiftSchedule: { morningShift, afternoonShift, nightShift },
      };
      await this.settings.locationSettings.saveShiftSettings(
        locationShiftSettingsInput
      );
      this.resetRouteHandler();

      this.formModel.hasSuccess({
        title: this.l10n.t('Settings saved'),
        description: this.l10n.t(
          'Your shift settings were successfully updated.'
        ),
      });
    } catch (error) {
      this.formModel.addError(this.errorParser.getErrorMessage(error));
      return;
    }
  });

  public beforeRouteChangeHandler?: (transition: Transition) => void;

  private addRouteHandler() {
    if (!this.beforeRouteChangeHandler) {
      this.beforeRouteChangeHandler = (transition: Transition) => {
        // When we abort, it triggers a new transition to the same route
        // Which leads to an endless loop if we don't handle that here
        if (transition.from?.name === transition?.to.name) {
          return;
        }

        if (this.shouldAbort) {
          this.showDialog = true;
          transition.abort();
        }

        this.transition = transition;
        this.shouldAbort = true;
      };

      this.router.on('routeWillChange', this.beforeRouteChangeHandler);
    }
  }

  private resetRouteHandler() {
    if (this.beforeRouteChangeHandler) {
      this.router.off('routeWillChange', this.beforeRouteChangeHandler);
      this.beforeRouteChangeHandler = undefined;
    }
  }
}
