import { DateTimeString, PageInfo } from 'fabscale-app';

import { deserializeDate } from 'fabscale-app/utilities/utils/serialize-date';
import { DateTime } from 'luxon';

export class LabSample {
  id: string;
  createdDate: DateTime;
  pictureUrl?: string;
  lotNumber?: string;
  roastBatchNumber?: string;
  coffeeType?: string;
  coffeeVariety?: string;
  screenSizeMode?: number;
  screenSizeHist?: CountBinValue[];
  lengthAvg?: number;
  lengthDeviation?: number;
  lengthHist?: CountBinValue[];
  widthAvg?: number;
  widthDeviation?: number;
  widthHist?: CountBinValue[];
  totalEstimatedVolume?: number;
  sampleMass?: number;
  density?: number;
  gourmetColorHist?: CountBinValue[];
  lstarAvg?: number;
  lstarDeviation?: number;
  lstarHist?: CountBinValue[];
  astarAvg?: number;
  astarDeviation?: number;
  astarHist?: CountBinValue[];
  bstarAvg?: number;
  bstarDeviation?: number;
  bstarHist?: CountBinValue[];
  colorNameMode?: string;
  colorNameHist?: CountBinValue[];
  gourmetColorAvg?: number;
  gourmetColorDeviation?: number;
  numberDefects?: number;
  numberDefectsCatOne?: number;
  numberDefectsCatTwo?: number;
  defectsHist?: CountBinValue[];
  numberOfBeans?: number;
  comment?: string;

  constructor(dto: LabSampleDto) {
    this.id = dto.id;
    this.createdDate = deserializeDate(dto.createdDate);
    this.pictureUrl = dto.pictureUrl;
    this.lotNumber = dto.lotNumber;
    this.roastBatchNumber = dto.roastBatchNumber;
    this.coffeeType = dto.coffeeType;
    this.coffeeVariety = dto.coffeeVariety;
    this.screenSizeMode = dto.screenSizeMode;
    this.screenSizeHist = dto.screenSizeHist;
    this.lengthAvg = Number(dto.lengthAvg?.toFixed(2));
    this.lengthDeviation = Number(dto.lengthDeviation?.toFixed(2));
    this.lengthHist = dto.lengthHist;
    this.widthAvg = Number(dto.widthAvg?.toFixed(2));
    this.widthDeviation = Number(dto.widthDeviation?.toFixed(2));
    this.widthHist = dto.widthHist;
    this.totalEstimatedVolume = dto.totalEstimatedVolume;
    this.sampleMass = dto.sampleMass;
    this.density = Number(dto.density?.toFixed(2));
    this.gourmetColorHist = dto.gourmetColorHist;
    this.lstarAvg = Number(dto.lstarAvg?.toFixed(2));
    this.lstarDeviation = Number(dto.lstarDeviation?.toFixed(2));
    this.lstarHist = dto.lstarHist;
    this.astarAvg = Number(dto.astarAvg?.toFixed(2));
    this.astarDeviation = Number(dto.astarDeviation?.toFixed(2));
    this.astarHist = dto.astarHist;
    this.bstarAvg = Number(dto.bstarAvg?.toFixed(2));
    this.bstarDeviation = Number(dto.bstarDeviation?.toFixed(2));
    this.bstarHist = dto.bstarHist;
    this.colorNameMode = dto.colorNameMode;
    this.colorNameHist = dto.colorNameHist;
    this.gourmetColorAvg = Number(dto.gourmetColorAvg?.toFixed(2));
    this.gourmetColorDeviation = Number(dto.gourmetColorDeviation?.toFixed(2));
    this.numberDefects = dto.numberDefects;
    this.numberDefectsCatOne = dto.numberDefectsCatOne;
    this.numberDefectsCatTwo = dto.numberDefectsCatTwo;
    this.defectsHist = dto.defectsHist;
    this.numberOfBeans = dto.numberOfBeans;
    this.comment = dto.comment;
  }
}

export interface LabSampleDto {
  id: string;
  createdDate: DateTimeString;
  pictureUrl?: string;
  lotNumber?: string;
  roastBatchNumber?: string;
  coffeeType?: string;
  coffeeVariety?: string;
  screenSizeMode?: number;
  screenSizeHist?: CountBinValue[];
  lengthAvg?: number;
  lengthDeviation?: number;
  lengthHist?: CountBinValue[];
  widthAvg?: number;
  widthDeviation?: number;
  widthHist?: CountBinValue[];
  totalEstimatedVolume?: number;
  sampleMass?: number;
  density?: number;
  gourmetColorHist?: CountBinValue[];
  lstarAvg?: number;
  lstarDeviation?: number;
  lstarHist?: CountBinValue[];
  astarAvg?: number;
  astarDeviation?: number;
  astarHist?: CountBinValue[];
  bstarAvg?: number;
  bstarDeviation?: number;
  bstarHist?: CountBinValue[];
  colorNameMode?: string;
  colorNameHist?: CountBinValue[];
  gourmetColorAvg?: number;
  gourmetColorDeviation?: number;
  numberDefects?: number;
  numberDefectsCatOne?: number;
  numberDefectsCatTwo?: number;
  defectsHist?: CountBinValue[];
  numberOfBeans?: number;
  comment: string;
}

export interface PaginatedLabSamples {
  items: LabSample[];
  pageInfo: PageInfo;
}

interface CountBinValue {
  count: number;
  value: number;
}
