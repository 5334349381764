/* import __COLOCATED_TEMPLATE__ from './filters.hbs'; */
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { KpiType, KpiTypes } from 'fabscale-app/models/enums/kpi-types';
import {
  UnitSystem,
  UnitSystems,
} from 'fabscale-app/models/enums/unit-systems';
import { DateRangeOptional } from 'fabscale-app/models/date-range';
import { FormDataModel } from 'fabscale-app/models/form-data';
import { Location } from 'fabscale-app/models/location';
import { TimeRange, TimeRangeOptional } from 'fabscale-app/models/time-range';
import AnalyticsService from 'fabscale-app/services/analytics';
import EnumLabelsService from 'fabscale-app/services/enum-labels';
import PdfService from 'fabscale-app/services/pdf';
import UserSessionService from 'fabscale-app/services/user-session';
import { getDateRangeLength } from 'fabscale-app/utilities/utils/date-range';
import {
  DateFormat,
  formatDate,
} from 'fabscale-app/utilities/utils/format-date';
import { DateTime } from 'luxon';
import { PlantCompareReportFilters } from '.';

interface Args {
  filters: PlantCompareReportFilters;
  updateFilters: (filters: PlantCompareReportFilters) => void;
  defaultUnitSystem: UnitSystem;
  defaultTimeRange: TimeRangeOptional;
  canClose: boolean;
}

class PlantCompareFilterFormData {
  @tracked dateRange?: DateRangeOptional;
  @tracked timeRange: TimeRangeOptional;
  @tracked locationIds: string[];
  @tracked kpiTypes: KpiType[];
  @tracked unitSystem: UnitSystem;
}

export default class PlantCompareReportFiltersComponent extends Component<Args> {
  @service pdf: PdfService;
  @service analytics: AnalyticsService;
  @service userSession: UserSessionService;
  @service l10n: L10nService;
  @service enumLabels: EnumLabelsService;

  @tracked formModel: FormDataModel<PlantCompareFilterFormData>;
  @tracked formData: PlantCompareFilterFormData;

  // We want to take the full list of KPI types here, unrelated to which are available in the location(s)
  // to keep it simpler (instead of using availableData.getAvailableKpiTypes())
  availableKpiTypes = KpiTypes;
  availableUnitSystems = UnitSystems;

  get selectedLocations() {
    let { availableLocations } = this;
    let { locationIds } = this.formData;

    return locationIds
      .map((locationId) =>
        availableLocations.find((location) => location.id === locationId)
      )
      .filter(Boolean) as Location[];
  }

  get availableLocations() {
    return this.userSession.locations!;
  }

  get activeFilters(): { label: string; value: string }[] {
    let { l10n, enumLabels } = this;

    let dateStart = this.args.filters.dateRange?.start;
    let dateEnd = this.args.filters.dateRange?.end;

    let filters = [
      {
        label: l10n.t('Date from'),
        value: dateStart ? formatDate(dateStart, DateFormat.Date) : '-',
      },
      {
        label: l10n.t('Date to'),
        value: dateEnd ? formatDate(dateEnd, DateFormat.Date) : '-',
      },
      {
        label: l10n.t('KPIs'),
        value: this.formData.kpiTypes
          .map((kpiType) => enumLabels.kpiType(kpiType))
          .join(', '),
      },
      {
        label: l10n.t('Plants'),
        value:
          this.selectedLocations.length > 0
            ? this.selectedLocations.map((location) => location.name).join(', ')
            : l10n.t('All plants'),
      },
      {
        label: l10n.t('Unit system'),
        value: enumLabels.unitSystem(this.formData.unitSystem),
      },
    ];

    return filters;
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    let {
      kpiTypes,
      dateRange,
      timeRange = this.args.defaultTimeRange,
      locationIds,
      unitSystem = this.args.defaultUnitSystem,
    } = this.args.filters;
    this._initFormModel({
      kpiTypes,
      dateRange,
      timeRange,
      locationIds,
      unitSystem,
    });
  }

  @action
  updateDateRange(dateRange: DateRangeOptional) {
    if (dateRange.start && dateRange.end) {
      this.formModel.updateProperty('dateRange', dateRange);
    } else {
      this.formData.dateRange = dateRange;
    }

    this.analytics.addEvent('plant-compare-report-filter-date-range', [
      {
        name: 'dateRangeLength',
        value: dateRange ? getDateRangeLength(dateRange) : -1,
      },
    ]);
  }

  @action
  updateTimeRange(timeRange: TimeRange) {
    this.formModel.updateProperty('timeRange', timeRange);

    this.analytics.addEvent('plant-compare-report-filter-time-range', [
      {
        name: 'start',
        value: timeRange.start,
      },
      {
        name: 'end',
        value: timeRange.end,
      },
    ]);
  }

  @action
  updateKpiTypes(kpiTypes: KpiType[]) {
    this.formModel.updateProperty('kpiTypes', kpiTypes);

    this.analytics.addEvent('plant-compare-report-filter-kpi-types', [
      {
        name: 'kpiTypesLength',
        value: kpiTypes.length,
      },
    ]);
  }

  @action
  updateUnitSystem(unitSystem: UnitSystem) {
    this.formModel.updateProperty('unitSystem', unitSystem);

    this.analytics.addEvent('plant-compare-report-filter-unit-system', [
      {
        name: 'unitSystem',
        value: unitSystem,
      },
    ]);
  }

  @action
  updateLocations(locations: Location[]) {
    this.formModel.updateProperty(
      'locationIds',
      locations.map((location) => location.id)
    );
  }

  printPageTask = dropTask(async () => {
    let fileName = `fabscale-plant-compare-report-${this.args.filters.kpiTypes.join(
      '_'
    )}-${DateTime.local().toISODate()}.pdf`;

    await this.pdf.generateForCurrentPageTask.perform(fileName);
  });

  applyFiltersTask = dropTask(async () => {
    let isValid: boolean = await this.formModel.validate();

    if (!isValid) {
      return false;
    }

    let { kpiTypes, locationIds, dateRange, timeRange, unitSystem } =
      this.formData;

    let actualTimeRange =
      JSON.stringify(timeRange) === JSON.stringify(this.args.defaultTimeRange)
        ? undefined
        : timeRange;

    this.args.updateFilters({
      kpiTypes,
      locationIds,
      dateRange,
      timeRange: actualTimeRange,
      unitSystem:
        unitSystem === this.args.defaultUnitSystem ? undefined : unitSystem,
    });

    return true;
  });

  @action
  clearFilters() {
    this._initFormModel({
      dateRange: undefined,
      timeRange: this.args.defaultTimeRange,
      locationIds: [],
      kpiTypes: [],
      unitSystem: this.args.defaultUnitSystem,
    });
  }

  @action
  resetFilters() {
    let {
      kpiTypes,
      dateRange,
      timeRange = this.args.defaultTimeRange,
      locationIds,
      unitSystem = this.args.defaultUnitSystem,
    } = this.args.filters;

    this._initFormModel({
      kpiTypes,
      dateRange,
      timeRange,
      locationIds,
      unitSystem,
    });
  }

  _initFormModel({
    kpiTypes,
    dateRange,
    timeRange,
    locationIds,
    unitSystem,
  }: {
    dateRange?: DateRangeOptional;
    timeRange: TimeRangeOptional;
    locationIds: string[];
    kpiTypes: KpiType[];
    unitSystem: UnitSystem;
  }) {
    let { l10n } = this;

    this.formData = new PlantCompareFilterFormData();

    Object.assign(this.formData, {
      kpiTypes,
      dateRange,
      timeRange,
      locationIds,
      unitSystem,
    });

    this.formModel = new FormDataModel<PlantCompareFilterFormData>({
      data: this.formData,
      validations: [
        {
          propertyName: 'dateRange',
          validate(value: DateRangeOptional | undefined) {
            return Boolean(value?.start) && Boolean(value?.end);
          },

          message: l10n.t('You have to select a date range.'),
        },
        {
          propertyName: 'kpiTypes',
          validate(value: KpiType[]) {
            return value.length > 0;
          },

          message: l10n.t('You have to select at least one KPI.'),
        },
      ],
    });
  }
}
