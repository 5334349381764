/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import PdfService from 'fabscale-app/services/pdf';
import { DateTime } from 'luxon';

export default class PlantAssetAlertsDetails extends Component {
  @service pdf: PdfService;

  printPageTask = dropTask(async () => {
    let fileName = `fabscale-plant-alert-detail-${DateTime.local().toISODate()}.pdf`;

    await this.pdf.generateForCurrentPageTask.perform(fileName);
  });
}
