/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import CellAmount from 'fabscale-app/components/page/plant-compare-report/overview/cells/amount';
import CellKpiType from 'fabscale-app/components/page/plant-compare-report/overview/cells/kpi-type';
import LocationHeaderComponent from 'fabscale-app/components/page/plant-compare-report/overview/location-header';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import { Unit } from 'fabscale-app/models/enums/units';
import { KpiDataGrouped } from 'fabscale-app/models/kpi-data-grouped';
import { Location } from 'fabscale-app/models/location';
import { TableColumnDefinitionInput } from 'fabscale-app/models/table-column-definition';
import AnalyticsService from 'fabscale-app/services/analytics';
import EnumLabelsService from 'fabscale-app/services/enum-labels';
import ExcelService, { ExcelAmountCell } from 'fabscale-app/services/excel';

interface Args {
  // A group per Kpi Type
  // Each group has the values grouped by location
  kpiDataGrouped: KpiDataGrouped[];
  locations: Location[];
}

export default class PlantCompareReportOverviewTable extends Component<Args> {
  @service excel: ExcelService;
  @service l10n: L10nService;
  @service analytics: AnalyticsService;
  @service enumLabels: EnumLabelsService;

  @cached
  get kpiDataPerKpiType() {
    let { kpiDataGrouped } = this.args;
    let { enumLabels } = this;

    return kpiDataGrouped.map((group) => {
      let { type, unit, values } = group;

      let data: {
        kpiType: KpiType;
        label: string;
        perLocation: { [key: string]: { value: number; unit: Unit } };
      } = {
        kpiType: type,
        label: enumLabels.kpiType(type),
        perLocation: {},
      };

      values.forEach((group) => {
        let { value, id } = group;

        data.perLocation[getLocationColumnName(id)] = {
          value,
          unit,
        };
      });

      return data;
    });
  }

  @cached
  get columns(): TableColumnDefinitionInput[] {
    let { locations } = this.args;

    let columns: TableColumnDefinitionInput[] = [
      {
        propertyName: 'label',
        title: '',
        disableSorting: true,
        tdClass: 'min-width-4',
        component: CellKpiType,
      },
    ];

    locations.forEach((location) => {
      columns.push({
        propertyName: `perLocation.${getLocationColumnName(location.id)}`,
        title: location.name,
        disableSorting: true,
        component: CellAmount,
        tdClass: 'nowrap',
        headerComponent: LocationHeaderComponent,

        cellData: {
          location,
        },
      });
    });

    return columns;
  }

  downloadExcelTask = dropTask(async () => {
    let { excel, l10n, kpiDataPerKpiType, columns } = this;

    let rows = kpiDataPerKpiType.map((record) => {
      let { label, perLocation } = record;

      let row: { label: string } & {
        [key in string]?: ExcelAmountCell | string;
      } = {
        label,
      };

      Object.keys(perLocation).forEach((colKey) => {
        let kpiData = perLocation[colKey]!;

        row[colKey] = new ExcelAmountCell({
          amount: kpiData.value,
          unit: kpiData.unit,
        });
      });

      return row;
    });

    let sheetColumns = columns.map((column) => {
      let title = column.title || '';
      let propertyName = column.propertyName!;

      let id = propertyName.replace('perLocation.', '');

      return { header: title, id };
    });

    let sheetConfig = {
      sheetName: l10n.t('Fabscale report data'),
      autoFilter: true,
      columns: sheetColumns,
      rows,
    };

    this.analytics.addEvent('plant-compare-report-download-excel');

    await excel.create(sheetConfig);
  });
}

function getLocationColumnName(locationId: string) {
  return `LOCATION_${locationId}`;
}
