/* import __COLOCATED_TEMPLATE__ from './related-tasks.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { restartableTask, task, timeout } from 'ember-concurrency';
import { TIMEOUTS } from 'fabscale-app/utilities/fixtures/timeouts';
import { MaintenanceTask } from 'fabscale-app/models/maintenance-task';
import StoreMaintenanceTaskService from 'fabscale-app/services/store/maintenance-task';
import { filterRecords } from 'fabscale-app/utilities/utils/filter-records';
import { sentryCaptureException } from 'fabscale-app/utilities/utils/sentry-capture-exception';

interface Args {
  task: MaintenanceTask;
}

export default class MaintenanceTasksDetailPageRelatedTasks extends Component<Args> {
  @service('store/maintenance-task')
  maintenanceTaskStore: StoreMaintenanceTaskService;

  @tracked relatedTasksSearchTerm?: string;

  relatedTasks: MaintenanceTask[] = [];

  get previousMaintenanceTasks() {
    return this.args.task.previousMaintenanceTask
      ? [this.args.task.previousMaintenanceTask]
      : [];
  }

  get relatedTasksWithoutPreviousTasks() {
    let { relatedTasks } = this;
    let { previousMaintenanceTask } = this.args.task;

    if (!previousMaintenanceTask) {
      return relatedTasks;
    }

    let previousId = previousMaintenanceTask.id;
    return relatedTasks.filter((task) => task.id !== previousId);
  }

  get filteredRelatedTasks() {
    let { relatedTasksSearchTerm, relatedTasksWithoutPreviousTasks } = this;

    if (!relatedTasksSearchTerm) {
      return relatedTasksWithoutPreviousTasks;
    }

    return filterRecords(
      relatedTasksWithoutPreviousTasks,
      relatedTasksSearchTerm,
      {
        propertyName: 'title',
      }
    );
  }

  constructor(owner: any, args: Args) {
    super(owner, args);

    this.loadRelatedTasks.perform();
  }

  filterRelatedTasksTask = restartableTask(async (searchTerm: string) => {
    await timeout(TIMEOUTS.searchTypeDebounce);

    this.relatedTasksSearchTerm = searchTerm;
  });

  loadRelatedTasks = task(async () => {
    let plantAssetAreaId = this.args.task.plantAssetArea?.id;

    if (!plantAssetAreaId) {
      return;
    }

    let taskId = this.args.task.id;

    try {
      let relatedTasks: MaintenanceTask[] =
        await this.maintenanceTaskStore.queryRelated({
          plantAssetAreaId,
        });

      // Remove current task from list
      this.relatedTasks = relatedTasks.filter((task) => task.id !== taskId);
    } catch (error) {
      sentryCaptureException(error);
    }
  });
}
