/* import __COLOCATED_TEMPLATE__ from './parameter-goal-form.hbs'; */
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import {
  getUnitTypeForNumericParameterType,
  NumericRoastBatchParameterType,
} from 'fabscale-app/models/enums/roast-batch-parameter-type';
import { getDefaultUnit } from 'fabscale-app/models/enums/unit-systems';
import {
  Unit,
  UnitsRoastColor,
  UnitType,
} from 'fabscale-app/models/enums/units';
import { FormDataModel } from 'fabscale-app/models/form-data';
import {
  ParameterGoal,
  ParameterGoalInput,
} from 'fabscale-app/models/parameter-goal';
import ErrorParserService from 'fabscale-app/services/error-parser';
import SettingsService from 'fabscale-app/services/settings';
import { DateTime } from 'luxon';
import Transition from '@ember/routing/-private/transition';

interface Args {
  parameterGoal?: ParameterGoal;
  onSubmit: (data: ParameterGoalInput) => Promise<void>;
}

interface AssignedRecord {
  id: string;
  name: string;
}

class FormData {
  @tracked parameterType?: NumericRoastBatchParameterType;
  @tracked unit?: Unit;
  @tracked minValue?: number;
  @tracked maxValue?: number;
  @tracked recipes?: AssignedRecord[];
  @tracked plantAssets?: AssignedRecord[];

  @tracked assignedMode: 'RECIPES' | 'PLANT_ASSETS' = 'RECIPES';
}

export default class PlantParameterGoalsParameterGoalForm extends Component<Args> {
  @service l10n: L10nService;
  @service settings: SettingsService;
  @service router: RouterService;
  @service('error-parser') errorParser: ErrorParserService;

  @tracked formData: FormData;
  @tracked formModel: FormDataModel<FormData>;
  @tracked showDialog = false;

  private hasBeenSubmitted = false;
  private transition?: Transition;
  private shouldAbort = true;

  queryDateRange = {
    start: DateTime.local().minus({ years: 1 }),
    end: DateTime.local().endOf('day'),
  };

  @tracked availableUnits?: UnitType;

  get isNew() {
    return typeof this.args.parameterGoal === 'undefined';
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this._initializeFormData();
  }

  willDestroy(): void {
    this.resetRouteHandler();

    super.willDestroy();
  }

  @action
  updateParameterType(parameterType?: NumericRoastBatchParameterType) {
    let numericParameterType = parameterType;

    this.formModel.updateProperty('parameterType', numericParameterType);

    if (!numericParameterType) {
      return;
    }

    let unitType = this._prepareUnits(numericParameterType);
    this._setDefaultUnit(unitType);
    this.addRouteHandler();
  }

  @action
  updateAssignedMode(assignedMode: 'RECIPES' | 'PLANT_ASSETS') {
    this.formModel.updateProperty('assignedMode', assignedMode);
    this.formData.recipes = undefined;
    this.formData.plantAssets = undefined;
    this.addRouteHandler();
  }

  @action
  addRecipe(recipe: AssignedRecord) {
    let recipes = this.formData.recipes || [];
    recipes.push(recipe);

    this.formModel.updateProperty('recipes', recipes);
    this.addRouteHandler();
  }

  @action
  removeRecipe(recipe: AssignedRecord) {
    let recipes = this.formData.recipes || [];
    recipes = recipes.filter((r) => r.id !== recipe.id);

    this.formModel.updateProperty('recipes', recipes);
    this.addRouteHandler();
  }

  @action
  addPlantAsset(plantAsset: AssignedRecord) {
    let plantAssets = this.formData.plantAssets || [];
    plantAssets.push(plantAsset);

    this.formModel.updateProperty('plantAssets', plantAssets);
    this.addRouteHandler();
  }

  @action
  removePlantAsset(plantAsset: AssignedRecord) {
    let plantAssets = this.formData.plantAssets || [];
    plantAssets = plantAssets.filter((p) => p.id !== plantAsset.id);

    this.formModel.updateProperty('plantAssets', plantAssets);
    this.addRouteHandler();
  }

  @action
  updateMinValue(value: number) {
    this.formData.minValue = value;

    if (this.hasBeenSubmitted) {
      this.formModel.validateProperty('minValue');
    }
    this.formModel.hasChanges = true;
    this.addRouteHandler();
  }

  @action
  updateMaxValue(value: number) {
    this.formData.maxValue = value;

    if (this.hasBeenSubmitted) {
      this.formModel.validateProperty('minValue');
    }
    this.formModel.hasChanges = true;
  }

  @action
  updateUnit(unit: Unit) {
    this.formData.unit = unit;

    if (this.hasBeenSubmitted) {
      this.formModel.validateProperty('minValue');
    }
    this.formModel.hasChanges = true;
    this.addRouteHandler();
  }

  @action
  handleOnCancel() {
    this.shouldAbort = true;
    this.showDialog = false;
  }

  @action
  handleOnYes() {
    this.showDialog = false;
    this.shouldAbort = false;
    if (this.transition) {
      this.router.transitionTo(this.transition.to.name);
    }
  }

  submitFormTask = dropTask(async () => {
    await this.formModel.validate();

    this.hasBeenSubmitted = true;

    if (this.formModel.isInvalid) {
      return;
    }

    let { minValue, maxValue, unit, parameterType, plantAssets, recipes } =
      this.formData;

    let data: ParameterGoalInput = {
      minValue: minValue!,
      maxValue: maxValue!,
      unit: unit!,
      parameterType: parameterType!,
      recipeIds: recipes ? recipes.map((recipe) => recipe.id) : undefined,
      plantAssetIds: plantAssets
        ? plantAssets.map((plantAsset) => plantAsset.id)
        : undefined,
    };

    try {
      this.resetRouteHandler();
      await this.args.onSubmit(data);
    } catch (error) {
      this.formModel.addError(this.errorParser.getErrorMessage(error));
      return;
    }

    this._initializeFormData();
  });

  @action
  cancel() {
    if (this.args.parameterGoal) {
      this.router.transitionTo(
        'routes/plant-settings.parameter-goals.show',
        this.args.parameterGoal.id
      );
    } else {
      this.router.transitionTo('routes/plant-settings.parameter-goals.index');
    }
  }

  _initializeFormData() {
    let { l10n } = this;

    this.hasBeenSubmitted = false;
    this.availableUnits = undefined;

    this.formData = new FormData();

    let { parameterGoal } = this.args;
    if (parameterGoal) {
      let { minValue, maxValue, unit, parameterType, recipes, plantAssets } =
        parameterGoal;

      let assignedMode =
        Array.isArray(recipes) && recipes.length > 0
          ? 'RECIPES'
          : 'PLANT_ASSETS';

      Object.assign(this.formData, {
        minValue,
        maxValue,
        unit,
        parameterType,
        recipes: assignedMode === 'RECIPES' ? recipes : undefined,
        plantAssets: assignedMode === 'PLANT_ASSETS' ? plantAssets : undefined,
        assignedMode,
      });

      this._prepareUnits(parameterType);
    }

    this.formModel = new FormDataModel<FormData>({
      data: this.formData,
      validations: [
        {
          propertyName: 'parameterType',
          validate: (value?: NumericRoastBatchParameterType) => !!value,
          message: l10n.t('You have to select a parameter type.'),
        },

        {
          propertyName: 'minValue',
          validate: (_: any, { data }) =>
            typeof data.minValue === 'number' &&
            typeof data.maxValue === 'number' &&
            typeof data.unit === 'string',

          message: l10n.t('You have to enter a value range.'),
        },
        {
          propertyName: 'minValue',
          validate: (_: any, { data }) =>
            typeof data.minValue !== 'number' ||
            typeof data.maxValue !== 'number' ||
            data.minValue <= data.maxValue,

          message: l10n.t(
            'The min. value cannot be greater than the max. value.'
          ),
        },

        {
          propertyName: 'recipes',
          validate: (value: AssignedRecord[] | undefined, { data }) =>
            data.assignedMode !== 'RECIPES' ||
            (Array.isArray(value) && value.length > 0),

          message: l10n.t('You have to select at least one recipe.'),
        },
        {
          propertyName: 'plantAssets',
          validate: (value: AssignedRecord[] | undefined, { data }) =>
            data.assignedMode !== 'PLANT_ASSETS' ||
            (Array.isArray(value) && value.length > 0),

          message: l10n.t('You have to select at least one roaster.'),
        },
      ],
    });
  }

  _prepareUnits(numericParameterType: NumericRoastBatchParameterType) {
    let unitType = getUnitTypeForNumericParameterType(numericParameterType);

    this.availableUnits = unitType;

    return unitType;
  }

  _setDefaultUnit(unitType: UnitType) {
    let { unitSystem, defaultRoastColorUnit } = this.settings.locationSettings;

    let defaultUnit =
      unitType === UnitsRoastColor
        ? defaultRoastColorUnit
        : getDefaultUnit(unitSystem, unitType);

    this.formModel.updateProperty('unit', defaultUnit);
  }

  public beforeRouteChangeHandler?: (transition: Transition) => void;

  private addRouteHandler() {
    if (!this.beforeRouteChangeHandler) {
      this.beforeRouteChangeHandler = (transition: Transition) => {
        // When we abort, it triggers a new transition to the same route
        // Which leads to an endless loop if we don't handle that here
        if (transition.from?.name === transition?.to?.name) {
          return;
        }

        if (this.shouldAbort) {
          this.showDialog = true;
          transition.abort();
        }

        this.transition = transition;
        this.shouldAbort = true;
      };

      this.router.on('routeWillChange', this.beforeRouteChangeHandler);
    }
  }

  private resetRouteHandler() {
    if (this.beforeRouteChangeHandler) {
      this.router.off('routeWillChange', this.beforeRouteChangeHandler);
      this.beforeRouteChangeHandler = undefined;
    }
  }
}
