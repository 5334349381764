/* import __COLOCATED_TEMPLATE__ from './confirm.hbs'; */
import { service } from '@ember/service';
import { isTesting, macroCondition } from '@embroider/macros';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { restartableTask, timeout } from 'ember-concurrency';
import { TIMEOUTS } from 'fabscale-app/utilities/fixtures/timeouts';
import ScreenService from 'fabscale-app/services/screen';

interface Args {
  label?: string;
  labelMobile?: string;
  confirmationMessage: string;
  confirmationMessageMobile?: string;
  onClick: () => void;
  resetTime?: number;
}

export default class UiButtonConfirm extends Component<Args> {
  @service screen: ScreenService;

  @tracked showConfirmation = false;

  get triggerActionOnClick() {
    return macroCondition(isTesting()) ? true : this.showConfirmation;
  }

  get isMobile() {
    return this.screen.isMobile;
  }

  get label() {
    let { label, labelMobile } = this.args;

    return this.isMobile && labelMobile ? labelMobile : label;
  }

  get confirmationMessage() {
    let { confirmationMessage, confirmationMessageMobile } = this.args;

    return this.isMobile && confirmationMessageMobile
      ? confirmationMessageMobile
      : confirmationMessage;
  }

  onClick = restartableTask(async (event: Event) => {
    if (this.triggerActionOnClick) {
      this.showConfirmation = false;
      this.resetShowConfirmationTask.cancelAll();
      await this.args.onClick();

      return;
    }

    event.preventDefault();
    this.showConfirmation = true;
    this.resetShowConfirmationTask.perform();
  });

  resetShowConfirmationTask = restartableTask(async () => {
    let resetTime = this.args.resetTime || TIMEOUTS.confirmButtonReset;
    await timeout(resetTime);

    this.showConfirmation = false;
  });
}
