/* import __COLOCATED_TEMPLATE__ from './report.hbs'; */
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import ScreenService from 'fabscale-app/services/screen';

export default class ReportsOverview extends Component<{
  Args: {
    routeName: string;
  };
}> {
  @service screen: ScreenService;
  @service router: RouterService;

  get style() {
    return this.screen.isMobile ? undefined : htmlSafe('min-height: 24rem');
  }

  @action
  gotoRoute() {
    this.router.transitionTo(this.args.routeName);
  }
}
