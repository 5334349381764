/* import __COLOCATED_TEMPLATE__ from './my-profile.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { FormDataModel } from 'fabscale-app/models/form-data';
import { action } from '@ember/object';
import { cloneUser } from 'fabscale-app/models/user';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import UserSessionService from 'fabscale-app/services/user-session';
import StoreUserService from 'fabscale-app/services/store/user';
import {
  Locale,
  LocaleLabelPair,
  LocaleLabels,
} from 'fabscale-app/models/enums/locales';
import { SuccessMessage } from 'fabscale-app';
import { sortBy } from 'fabscale-app/utilities/utils/array';
import AnalyticsService from 'fabscale-app/services/analytics';
import ErrorParserService from 'fabscale-app/services/error-parser';
import Transition from '@ember/routing/-private/transition';
import RouterService from '@ember/routing/router-service';

class FormData {
  @tracked name: string;
  @tracked locale: Locale;
  email: string;
  id: string;
}

export default class SettingsMyProfile extends Component {
  @service l10n: L10nService;
  @service userSession: UserSessionService;
  @service('store/user') userStore: StoreUserService;
  @service analytics: AnalyticsService;
  @service('error-parser') errorParser: ErrorParserService;
  @service router: RouterService;

  // Properties
  @tracked successMessage?: SuccessMessage;
  @tracked showDialog = false;

  private transition?: Transition;
  private shouldAbort = true;

  public formData: FormData;
  public formModel: FormDataModel<FormData>;
  public availableLocales = sortBy(LocaleLabels, 'label');

  get selectedLocale() {
    let { locale } = this.formData;

    return locale
      ? this.availableLocales.find((localeData) => localeData.locale === locale)
      : undefined;
  }

  constructor(owner: unknown, args: any) {
    super(owner, args);

    let { l10n } = this;
    let user = this.userSession.user!;

    this.formData = new FormData();
    this.formData.id = user.id;
    this.formData.name = user.name;
    this.formData.locale = user.locale;
    this.formData.email = user.email;

    this.formModel = new FormDataModel({
      data: this.formData,
      validations: [
        {
          propertyName: 'name',
          message: l10n.t('The full name must not be blank.'),
          validate: (value) => value,
        },
      ],
    });
  }

  willDestroy(): void {
    this.resetRouteHandler();

    super.willDestroy();
  }

  @action
  updateName(name: string) {
    this.formModel.updateProperty('name', name);
    this.analytics.addEvent('settings-profile-update-name');
    this.addRouteHandler();
  }

  @action
  updateLocale(localeData: LocaleLabelPair) {
    this.formModel.updateProperty('locale', localeData.locale);
    this.analytics.addEvent('settings-profile-update-locale', [
      { name: 'locale', value: localeData.locale },
    ]);
    this.addRouteHandler();
  }

  @action
  hideSuccessMessage() {
    this.formModel.successMessage = undefined;
  }

  @action
  handleOnCancel() {
    this.shouldAbort = true;
    this.showDialog = false;
  }

  @action
  handleOnYes() {
    this.showDialog = false;
    this.shouldAbort = false;
    if (this.transition) {
      this.router.transitionTo(this.transition.to.name);
    }
  }

  updateUserTask = dropTask(async () => {
    await this.formModel.validate();

    if (this.formModel.isInvalid) {
      return;
    }

    let { l10n, userStore } = this;
    let { name, locale, id } = this.formData;
    let currentUser = this.userSession.user!;

    this.formModel.successMessage = undefined;

    let localeHasChanged = locale !== currentUser.locale;

    try {
      await userStore.update(id, { name, locale });
      this.resetRouteHandler();
    } catch (error) {
      this._handleError(error);
      return;
    }

    let newCurrentUser = cloneUser(currentUser, { name, locale });
    this.userSession.user = newCurrentUser;

    if (localeHasChanged) {
      await this.userSession.setLocale(locale);
    }

    this.formModel.hasChanges = false;

    this.formModel.hasSuccess({
      title: l10n.t('User details saved'),
      description: l10n.t('Your profile has been successfully updated.'),
    });
  });

  _handleError(error: any) {
    this.formModel.addError(this.errorParser.getErrorMessage(error));
  }

  public beforeRouteChangeHandler?: (transition: Transition) => void;

  private addRouteHandler() {
    if (!this.beforeRouteChangeHandler) {
      this.beforeRouteChangeHandler = (transition: Transition) => {
        // When we abort, it triggers a new transition to the same route
        // Which leads to an endless loop if we don't handle that here
        if (transition.from?.name === transition?.to.name) {
          return;
        }

        if (this.shouldAbort) {
          this.showDialog = true;
          transition.abort();
        }

        this.transition = transition;
        this.shouldAbort = true;
      };

      this.router.on('routeWillChange', this.beforeRouteChangeHandler);
    }
  }

  private resetRouteHandler() {
    if (this.beforeRouteChangeHandler) {
      this.router.off('routeWillChange', this.beforeRouteChangeHandler);
      this.beforeRouteChangeHandler = undefined;
    }
  }
}
