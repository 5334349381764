/* import __COLOCATED_TEMPLATE__ from './back-link.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import MaintenanceTaskManagerService from 'fabscale-app/services/maintenance-task-manager';

export default class MaintenanceTasksTaskBackLink extends Component {
  @service maintenanceTaskManager: MaintenanceTaskManagerService;

  get backData() {
    return this.maintenanceTaskManager.previousPageData;
  }
}
