/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import {
  PlantAssetStatus,
  PlantAssetStatuses,
} from 'fabscale-app/models/enums/plant-asset-status';
import { DateRangeOptional } from 'fabscale-app/models/date-range';
import { RelativeTimeframe } from 'fabscale-app/models/enums/relative-timeframe';
import { TimeRange } from 'fabscale-app/models/time-range';
import SettingsService from 'fabscale-app/services/settings';
import { getEndTimeIsoString } from 'fabscale-app/utilities/utils/parse-time';
import { relativeTimeframeToDateRange } from 'fabscale-app/utilities/utils/serialize-relative-timeframe';
import { use } from 'ember-could-get-used-to-this';
import LoadPlantAssetsResource from 'fabscale-app/helpers/resources/load-plant-assets';
import { getAsyncResourceData } from 'fabscale-app/utilities/utils/get-async-resource-value';

export interface StatusMapReportFilters {
  relativeTimeframe?: RelativeTimeframe;
  dateRange?: DateRangeOptional;
  timeRange?: TimeRange;
  plantAssetIds: string[];
  status: PlantAssetStatus[];
}

interface Args {
  filters: StatusMapReportFilters;
  updateFilters: (filters: StatusMapReportFilters) => void;
}

export default class PageReportsStatusMapIndex extends Component<{
  Args: Args;
}> {
  @service declare settings: SettingsService;

  @use
  allPlantAssetsAsyncResource = new LoadPlantAssetsResource(() => [
    { hasStatusInfo: true },
  ]);

  get allPlantAssets() {
    return getAsyncResourceData(this.allPlantAssetsAsyncResource, []);
  }

  get plantAssetIds() {
    let { allPlantAssets } = this;
    let { plantAssetIds } = this.args.filters;

    return plantAssetIds.length > 0
      ? plantAssetIds
      : allPlantAssets.map((plantAsset) => plantAsset.id);
  }

  get filters(): StatusMapReportFilters {
    let { filters } = this.args;
    let { defaultTimeRange } = this;

    let { dateRange, relativeTimeframe, timeRange, status, plantAssetIds } =
      filters;

    return {
      dateRange,
      relativeTimeframe,
      timeRange: Object.assign({}, defaultTimeRange, timeRange),
      status,
      plantAssetIds,
    };
  }

  get dateRange(): DateRangeOptional | undefined {
    let { dateRange, relativeTimeframe } = this.args.filters;

    if (relativeTimeframe) {
      return relativeTimeframeToDateRange(relativeTimeframe);
    }

    return dateRange;
  }

  get timeRange(): TimeRange {
    let timeRange = Object.assign(
      {},
      this.defaultTimeRange,
      this.args.filters.timeRange
    );

    return timeRange;
  }

  get plantAssetStatus(): PlantAssetStatus[] {
    return this.filters.status.length > 0
      ? this.filters.status
      : PlantAssetStatuses.slice();
  }

  get defaultTimeRange() {
    let { dayStartTime } = this.settings.locationSettings;
    let end = getEndTimeIsoString(dayStartTime);

    return { start: dayStartTime, end };
  }

  get hasAllRequiredData() {
    return Boolean(this.dateRange?.start && this.dateRange?.end);
  }
}
