/* import __COLOCATED_TEMPLATE__ from './edit-file-form.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask, Task } from 'ember-concurrency';
import { FileOrPendingFile } from './table';

interface Args {
  file: FileOrPendingFile;
  updateFileTask: Task<any, any>;
  close: () => void;
}

export default class FileUploadListEditFileForm extends Component<Args> {
  @tracked name: string;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.name = this.args.file.name;
  }

  @action
  updateName(name: string) {
    this.name = name;
  }

  submitForm = dropTask(async () => {
    if (!this.name) {
      return;
    }

    let { name } = this;

    // This does not throw again, as errors are already handled in the main task
    // It returns true/false if the update was successfull or not
    let hasUpdated: boolean = await this.args.updateFileTask.perform(
      this.args.file.fileWrapper!,
      {
        name,
      }
    );

    if (!hasUpdated) {
      return;
    }

    // For current view
    this.args.file.name = name;
    this.args.file.fileWrapper!.name = name;
    this.args.close();
  });
}
