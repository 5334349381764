/* import __COLOCATED_TEMPLATE__ from './goal-results.hbs'; */
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import ActionsCell from 'fabscale-app/components/page/roast-batches/details/goal-results/cell/actions';
import ParameterTypeCell from 'fabscale-app/components/page/roast-batches/details/goal-results/cell/parameter-type';
import RangeCell from 'fabscale-app/components/page/roast-batches/details/goal-results/cell/range';
import StatusCell from 'fabscale-app/components/page/roast-batches/details/goal-results/cell/status';
import ValueCell from 'fabscale-app/components/page/roast-batches/details/goal-results/cell/value';
import { GoalResultStatus } from 'fabscale-app/models/enums/goal-result-status';
import { NumericRoastBatchParameterType } from 'fabscale-app/models/enums/roast-batch-parameter-type';
import { Unit } from 'fabscale-app/models/enums/units';
import { RoastBatch } from 'fabscale-app/models/roast-batch';
import { TableColumnDefinitionInput } from 'fabscale-app/models/table-column-definition';
import EnumLabelsService from 'fabscale-app/services/enum-labels';
import { filterRecords } from 'fabscale-app/utilities/utils/filter-records';

interface Args {
  roastBatch: RoastBatch;
}

interface ParameterGoalResultWrapper {
  parameterType: NumericRoastBatchParameterType;
  status: GoalResultStatus;
  actualValue: number;
  actualUnit: Unit;
  targetMinValue: number;
  targetMaxValue: number;
  targetUnit: Unit;
  parameterGoalId: string;
}

export default class RoastBatchesDetailsGoalResults extends Component<Args> {
  @service l10n: L10nService;
  @service enumLabels: EnumLabelsService;

  @tracked searchMessage?: string;
  @tracked parameterType?: NumericRoastBatchParameterType;

  get allUsedParameterTypes(): NumericRoastBatchParameterType[] {
    let types = new Set(
      this.goalResults.map((goalResult) => goalResult.parameterType)
    );

    return Array.from(types);
  }

  @cached
  get goalResults(): ParameterGoalResultWrapper[] {
    let { roastBatch } = this.args;
    let { enumLabels } = this;

    return roastBatch.numericRoastBatchParameters.flatMap((param) =>
      param.goalResults
        ? param.goalResults.map((result) =>
            Object.assign(
              {
                parameterType: param.parameterType,
                // For filtering
                parameterTypeLabel: enumLabels.roastBatchParameterType(
                  param.parameterType
                ),
                parameterGoalId: result.goal!.id,
              },
              result,
              { actualValue: param.value, actualUnit: param.unit }
            )
          )
        : []
    );
  }

  get filteredGoalResults() {
    let { goalResults, searchMessage, parameterType } = this;

    if (parameterType) {
      goalResults = goalResults.filter(
        (result) => result.parameterType === parameterType
      );
    }

    if (searchMessage) {
      goalResults = filterRecords(goalResults, searchMessage, {
        propertyName: 'parameterTypeLabel',
      });
    }

    return goalResults;
  }

  @cached
  get columns(): TableColumnDefinitionInput[] {
    let { l10n } = this;

    return [
      {
        title: l10n.t('Status'),
        propertyName: 'status',
        component: StatusCell,
        tdClass: 'nowrap',
      },
      {
        title: l10n.t('Parameter'),
        propertyName: 'parameterType',
        component: ParameterTypeCell,
        tdClass: 'nowrap',
      },
      {
        title: l10n.t('Value'),
        propertyName: 'actualValue',
        component: ValueCell,
        thClass: 'text-right',
        tdClass: 'text-right nowrap',
      },
      {
        title: l10n.t('Range'),
        propertyName: 'targetMinValue',
        component: RangeCell,
        thClass: 'text-right',
        tdClass: 'text-right  nowrap',
      },
      {
        component: ActionsCell,
        tdClass: 'nowrap width-auto self-center mobile-order-first',
      },
    ];
  }

  @action
  updateSearchMessage(searchMessage: string) {
    this.searchMessage = searchMessage;
  }

  @action
  updateParameterType(parameterType?: NumericRoastBatchParameterType) {
    this.parameterType = parameterType;
  }
}
