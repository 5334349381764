/* import __COLOCATED_TEMPLATE__ from './tab-bar.hbs'; */
import Component from '@glimmer/component';
import { MaintenanceTaskDetailPageTab } from 'fabscale-app/components/page/maintenance-tasks/detail-page';

interface Args {
  details?: MaintenanceTaskDetailPageTab;
}

export default class MaintenanceTasksDetailPageTabBar extends Component<Args> {
  queryUpdates = { details: undefined };

  queryArea: { details: MaintenanceTaskDetailPageTab } = {
    details: 'AREA',
  };

  queryRelatedTasks: { details: MaintenanceTaskDetailPageTab } = {
    details: 'RELATED_TASKS',
  };

  get isActive() {
    let { details } = this.args;

    return {
      updates: details === undefined,
      area: details === 'AREA',
      relatedTasks: details === 'RELATED_TASKS',
    };
  }
}
