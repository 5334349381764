/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { PlantAsset } from 'fabscale-app/models/plant-asset';
import { PlantAssetArea } from 'fabscale-app/models/plant-asset-area';
import ErrorParserService from 'fabscale-app/services/error-parser';
import StorePlantAssetService from 'fabscale-app/services/store/plant-asset';
import { logError } from 'fabscale-app/utilities/utils/log-error';

interface Args {
  plantAssetArea: PlantAssetArea;
  plantAsset: PlantAsset;
}

export default class PlantPlantAssetsAreaDetailsIndex extends Component<Args> {
  @service router: RouterService;
  @service('store/plant-asset') plantAssetStore: StorePlantAssetService;
  @service('error-parser') errorParser: ErrorParserService;

  @tracked error?: string;

  deletePlantAssetAreaTask = dropTask(async () => {
    let { plantAssetStore } = this;
    let { id } = this.args.plantAssetArea;
    let plantAssetId = this.args.plantAsset.id;

    this.error = undefined;

    try {
      await plantAssetStore.deleteArea(id, { plantAssetId });
    } catch (error) {
      this._handleError(error);
      return;
    }

    this.router.transitionTo(
      'routes/plant-settings.plant-assets.show',
      plantAssetId
    );
  });

  _handleError(error: unknown) {
    logError(error);
    this.error = this.errorParser.getErrorMessage(error);
  }
}
