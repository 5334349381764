/* import __COLOCATED_TEMPLATE__ from './-plant-asset-area-form.hbs'; */
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import {
  PlantAssetAreaType,
  PlantAssetAreaTypes,
} from 'fabscale-app/models/enums/plant-asset-area-types';
import { FormDataModel } from 'fabscale-app/models/form-data';
import { PlantAsset } from 'fabscale-app/models/plant-asset';
import { PlantAssetArea } from 'fabscale-app/models/plant-asset-area';
import EnumLabelsService from 'fabscale-app/services/enum-labels';
import ErrorParserService from 'fabscale-app/services/error-parser';
import StorePlantAssetService from 'fabscale-app/services/store/plant-asset';
import { logError } from 'fabscale-app/utilities/utils/log-error';

interface Args {
  plantAsset: PlantAsset;
  plantAssetArea?: PlantAssetArea;
  onSubmit: (
    data: {
      name: string;
      type: PlantAssetAreaType;
      description?: string;
    },
    options: {
      plantAssetId: string;
      id?: string;
    }
  ) => void;
}

export default class PlantAssetAreaForm extends Component<Args> {
  @service l10n: L10nService;
  @service router: RouterService;
  @service('enum-labels') enumLabels: EnumLabelsService;
  @service('store/plant-asset') plantAssetStore: StorePlantAssetService;
  @service('error-parser') errorParser: ErrorParserService;

  @tracked formData: FormData;
  @tracked formModel: FormDataModel<FormData>;

  get availableTypes(): PlantAssetAreaType[] {
    if (this.args.plantAsset.type === 'OTHER') {
      return ['OTHER'];
    }

    return PlantAssetAreaTypes.slice();
  }

  get isNew() {
    return !this.args.plantAssetArea;
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this._initialiseFormData();
  }

  @action
  updateName(name: string) {
    this.formModel.updateProperty('name', name);
  }

  @action
  updateType(type: PlantAssetAreaType) {
    this.formModel.updateProperty('type', type);

    if (!this.formData.name && type !== 'OTHER') {
      let defaultName = this.enumLabels.plantAssetAreaType(type);
      this.formModel.updateProperty('name', defaultName);
    }
  }

  @action
  updateDescription(description: string) {
    this.formModel.updateProperty('description', description);
  }

  @action
  cancel() {
    if (this.isNew) {
      this.router.transitionTo(
        'routes/plant-settings.plant-assets.show',
        this.args.plantAsset.id
      );
    } else {
      this.router.transitionTo(
        'routes/plant-settings.plant-assets.areas.index',
        this.args.plantAssetArea!.id
      );
    }
  }

  submitFormTask = dropTask(async () => {
    let { formData } = this;

    await this.formModel.validate();

    if (this.formModel.isInvalid) {
      return;
    }

    let { plantAsset, plantAssetArea } = this.args;
    let { name, description, type } = formData;

    try {
      await this.args.onSubmit(
        {
          name: name!,
          type: type!,
          description,
        },
        {
          plantAssetId: plantAsset.id,
          id: plantAssetArea?.id,
        }
      );
    } catch (error) {
      this._handleError(error);
      return;
    }

    this._initialiseFormData();
  });

  _handleError(error: any) {
    logError(error);
    this.formModel.addError(this.errorParser.getErrorMessage(error));
  }

  _initialiseFormData() {
    let { l10n } = this;
    let { plantAssetArea } = this.args;

    let formData = new FormData();
    this.formData = formData;

    if (plantAssetArea) {
      formData.name = plantAssetArea.name;
      formData.type = plantAssetArea.type;
      formData.description = plantAssetArea.description;
    } else {
      formData.type =
        this.availableTypes.length === 1 ? this.availableTypes[0] : undefined;
    }

    this.formModel = new FormDataModel({
      data: this.formData,
      validations: [
        {
          propertyName: 'name',
          message: l10n.t('You must enter a name.'),

          validate: (value) => value,
        },
        {
          propertyName: 'type',
          message: l10n.t('You must select a type.'),

          validate: (value) => value,
        },
      ],
    });
  }
}

class FormData {
  @tracked name?: string;
  @tracked type?: PlantAssetAreaType;
  @tracked description?: string;
}
