/* import __COLOCATED_TEMPLATE__ from './permission-filter.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { Permission } from 'fabscale-app/models/enums/permissions';
import { PermissionDefinition } from 'fabscale-app/services/store/user-role';

interface Args {
  permissionDefinitions: PermissionDefinition[];
  selectedPermission?: Permission;
  updateSelectedPermission: (permission?: Permission) => void;
}

export default class SettingsRolesOverviewPermissionFilter extends Component<Args> {
  get selectedPermissionDefinition() {
    let { selectedPermission, permissionDefinitions } = this.args;

    return selectedPermission
      ? permissionDefinitions.find(
          (def) => def.permission === selectedPermission
        )
      : undefined;
  }

  @action
  updateSelectedPermissionDefinition(def?: PermissionDefinition) {
    this.args.updateSelectedPermission(def?.permission);
  }
}
