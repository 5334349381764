/* import __COLOCATED_TEMPLATE__ from './edit-reason.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import {
  PlantAssetStatusReason,
  PlantAssetStatusReasons,
} from 'fabscale-app/models/enums/plant-asset-status-reason';
import { PlantAssetStateInfo } from 'fabscale-app/models/plant-asset-state-info';
import ErrorParserService from 'fabscale-app/services/error-parser';
import StorePlantAssetInfoService from 'fabscale-app/services/store/plant-asset-info';
import { logError } from 'fabscale-app/utilities/utils/log-error';
import { serializeDate } from 'fabscale-app/utilities/utils/serialize-date';

interface Args {
  statusInfo: PlantAssetStateInfo;
  onSuccess: (updatedStatusInfo: PlantAssetStateInfo) => void;
  close: () => void;
}

export default class PlantAssetStatusMapEditReason extends Component<{
  Args: Args;
}> {
  @service('store/plant-asset-info')
  declare plantAssetInfoStore: StorePlantAssetInfoService;

  @service('error-parser') declare errorParser: ErrorParserService;

  @tracked error?: string;
  @tracked reason?: PlantAssetStatusReason;
  @tracked comment?: string;

  availableReasons = PlantAssetStatusReasons;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.reason = args.statusInfo.reason;
    this.comment = args.statusInfo.comment;
  }

  @action
  updateReason(reason: PlantAssetStatusReason | null) {
    this.reason = reason || undefined;
  }

  @action
  updateComment(comment: string) {
    this.comment = comment || undefined;
  }

  submitFormTask = dropTask(async () => {
    let { statusInfo } = this.args;
    let { reason, comment } = this;

    this.error = undefined;

    try {
      await this.plantAssetInfoStore.update(statusInfo.id, { reason, comment });
    } catch (error) {
      logError(error);

      this.error = this.errorParser.getErrorMessage(error);
      return;
    }

    let updated = new PlantAssetStateInfo({
      id: statusInfo.id,
      status: statusInfo.status,
      startDate: serializeDate(statusInfo.startDate),
      duration: statusInfo.duration,
      unit: statusInfo.durationUnit,
      reason,
      comment,
    });

    this.args.onSuccess(updated);
  });
}
