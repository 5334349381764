/* import __COLOCATED_TEMPLATE__ from './card-config-options.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { DashboardCardConfig } from 'fabscale-app/models/dashboard-config';

interface Args<T> {
  id: number;
  config: T;
  updateConfig: (config: DashboardCardConfig) => void;
  validateConfig: (config: DashboardCardConfig) => boolean;
}

export default class PageDashboardCustomizeCardConfigOptions<
  T extends DashboardCardConfig
> extends Component<{
  Args: Args<T>;
}> {
  get hasError() {
    return !this.args.validateConfig(this.args.config);
  }

  @action
  updateColor(color: string) {
    let { config } = this.args;

    let newConfig: T = Object.assign({}, config, { color });
    this.args.updateConfig(newConfig);
  }
}
