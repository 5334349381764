/* import __COLOCATED_TEMPLATE__ from './location-header.hbs'; */
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import { DateRange } from 'fabscale-app/models/date-range';
import { KpiDataFilterSettings } from 'fabscale-app/models/kpi-data-filter-settings';
import { Location } from 'fabscale-app/models/location';
import { TableColumnDefinition } from 'fabscale-app/models/table-column-definition';
import { TimeRange } from 'fabscale-app/models/time-range';
import UserSessionService from 'fabscale-app/services/user-session';
import { serializeKpiDataFilterSettings } from 'fabscale-app/utilities/utils/serialize-kpi-data-filter-settings';
import { DateTime } from 'luxon';

interface Args {
  columnDefinition: TableColumnDefinition;
  tableData: {
    dateRange: DateRange;
    timeRange: TimeRange;
    kpiTypes: KpiType[];
  };
}

export default class PlantCompareReportOverviewLocationHeader extends Component<Args> {
  @service userSession: UserSessionService;
  @service router: RouterService;

  get location() {
    return this.args.columnDefinition.cellData.location as Location;
  }

  get date() {
    let { timezoneName } = this.location;

    return DateTime.local().setZone(timezoneName);
  }

  @action
  async viewKpiCompareReport() {
    let { dateRange, timeRange, kpiTypes } = this.args.tableData;
    await this.userSession.setCurrentLocation(this.location);

    let kpiDataFilterSettings = new KpiDataFilterSettings({
      absoluteTimeframe: dateRange,
      timeRange,
      kpiTypes,
    });

    this.router.transitionTo('reports.kpi-compare', {
      queryParams: {
        settings: serializeKpiDataFilterSettings(kpiDataFilterSettings),
      },
    });
  }
}
