/* import __COLOCATED_TEMPLATE__ from './overview.hbs'; */
import Component from '@glimmer/component';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import {
  configIsKpiCard,
  DashboardCard,
  DashboardConfig,
} from 'fabscale-app/models/dashboard-config';

interface Args {
  dashboardConfig: DashboardConfig;
  availableKpiTypes: KpiType[];
}

export default class DashboardKpiOverviewOverview extends Component<{
  Args: Args;
}> {
  get availableKpiTypes(): { kpiType: KpiType; usedCards: DashboardCard[] }[] {
    let { availableKpiTypes, dashboardConfig } = this.args;

    return availableKpiTypes.map((kpiType) => {
      let usedCards =
        dashboardConfig.cards?.filter(
          (card) =>
            configIsKpiCard(card.config) &&
            'kpiType' in card.config &&
            card.config.kpiType === kpiType
        ) || [];

      return {
        kpiType,
        usedCards,
      };
    });
  }
}
