/* import __COLOCATED_TEMPLATE__ from './login-successful.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import CognitoService from 'fabscale-app/services/cognito';

interface Args {
  message: string;
}

export default class LoginLoginSuccessful extends Component<Args> {
  @service cognito: CognitoService;

  @tracked mfaIsEnabled?: boolean;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this._checkMfa();
  }

  async _checkMfa() {
    this.mfaIsEnabled = await this.cognito.cognitoData!.mfa.isEnabled();
  }
}
