/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { NumericRoastBatchParameterType } from 'fabscale-app/models/enums/roast-batch-parameter-type';
import {
  ParameterGoalSortOption,
  SortDirection,
} from 'fabscale-app/models/enums/sort-options';
import { TableColumnDefinitionInput } from 'fabscale-app/models/table-column-definition';
import CellActions from 'fabscale-app/components/page/plant/parameter-goals/overview/table/cell/actions';
import CellParameterType from 'fabscale-app/components/page/plant/parameter-goals/overview/table/cell/parameter-type';
import CellRange from 'fabscale-app/components/page/plant/parameter-goals/overview/table/cell/range';
import CellRecipesPlantAssets from 'fabscale-app/components/page/plant/parameter-goals/overview/table/cell/recipes-plant-assets';

const DEFAULT_SORT_BY: ParameterGoalSortOption = 'LAST_MODIFIED_DATE';
const DEFAULT_SORT_DIRECTION: SortDirection = 'DESC';

interface Args {
  parameterType?: NumericRoastBatchParameterType;
  search?: string;
  page?: number;
  pageSize?: number;
  sortBy?: ParameterGoalSortOption;
  sortDirection?: SortDirection;
  updatePage: (page?: number) => void;
  updatePageSize: (pageSize?: number) => void;
  updateSortBy: (sortBy?: ParameterGoalSortOption) => void;
  updateSortDirection: (sortDirection?: SortDirection) => void;
}

export default class PlantParameterGoalsOverviewTable extends Component<Args> {
  @service l10n: L10nService;

  get page() {
    return this.args.page || 1;
  }

  get pageSize() {
    return this.args.pageSize || 25;
  }

  get sortBy() {
    return this.args.sortBy || DEFAULT_SORT_BY;
  }

  get sortDirection() {
    return this.args.sortDirection || DEFAULT_SORT_DIRECTION;
  }

  get columns(): TableColumnDefinitionInput[] {
    let { l10n } = this;

    return [
      {
        title: l10n.t('Parameter'),
        propertyName: 'parameterType',
        sortBy: 'PARAMETER_TYPE',
        component: CellParameterType,
      },
      {
        title: l10n.t('Recipe / Roaster'),
        propertyName: '',
        disableSorting: true,
        component: CellRecipesPlantAssets,
      },
      {
        title: l10n.t('Range'),
        propertyName: '',
        disableSorting: true,
        component: CellRange,
        tdClass: 'nowrap',
      },
      {
        title: l10n.t('Last modified'),
        propertyName: 'lastModifiedDate',
        sortBy: 'LAST_MODIFIED_DATE',
        cellType: 'DATE',
      },
      {
        propertyName: '',
        component: CellActions,
        tdClass: 'nowrap width-auto self-center mobile-order-first',
      },
    ];
  }

  @action
  updateSort(sortBy: ParameterGoalSortOption, sortDirection: SortDirection) {
    if (sortBy !== this.args.sortBy) {
      this.args.updateSortBy(sortBy);
    }

    if (sortDirection !== this.args.sortDirection) {
      this.args.updateSortDirection(sortDirection);
    }
  }
}
