/* import __COLOCATED_TEMPLATE__ from './text-input.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { restartableTask, timeout } from 'ember-concurrency';
import AnalyticsService from 'fabscale-app/services/analytics';
import {
  getDatePatternString,
  parseDate,
  parseDateString,
} from 'fabscale-app/utilities/utils/date-picker';
import { DateTime } from 'luxon';

interface Args {
  value: DateTime;
  onUpdate: (value: DateTime | undefined) => void;
  onEnter?: () => void;
  onTab?: () => void;
  allowClear?: boolean;
  minDate?: DateTime;
  maxDate?: DateTime;
}

export default class UiDatePickerTextInput extends Component<Args> {
  @service analytics: AnalyticsService;

  datePattern: string;

  @tracked _dateString?: string;

  get dateString() {
    if (typeof this._dateString === 'string') {
      return this._dateString;
    }

    if (this.args.value) {
      return parseDate(this.datePattern, this.args.value);
    }

    return '';
  }

  constructor(owner: any, args: Args) {
    super(owner, args);

    this.datePattern = getDatePatternString();
  }

  @action
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.args.onEnter) {
      this.args.onEnter();
      event.preventDefault();
      event.stopPropagation();
      return false;
    }

    if (event.key === 'Tab' && this.args.onTab) {
      this.args.onTab();
    }

    return true;
  }

  @action
  onInput(value: string) {
    if (!/^[0-9-./]*$/.test(value) || value.length > 10) {
      this._dateString = this._dateString as string;
      return;
    }

    const date = this._parseDateString(value);

    this._dateString = value;

    if (date) {
      this.sendUpdateTask.perform(500, date);
    }
  }

  @action
  async onChange(value: string) {
    const date = this._parseDateString(value);
    if (date || this.args.allowClear) {
      await this.sendUpdateTask.perform(0, date);
    }

    this.resetDateString.perform();
  }

  resetDateString = restartableTask(async () => {
    await timeout(1);

    this._dateString = undefined;
  });

  sendUpdateTask = restartableTask(
    async (wait: number, date: DateTime | undefined) => {
      await timeout(wait);

      if (date?.valueOf() !== this.args.value?.valueOf()) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.args.onUpdate(date);

        this.analytics.addEvent('date-picker-text-input');
      }
    }
  );

  _parseDateString(value: string) {
    const { minDate, maxDate } = this.args;
    const date = parseDateString(this.datePattern, value);

    if (date && minDate && minDate > date) {
      return undefined;
    }

    if (date && maxDate && maxDate < date) {
      return undefined;
    }

    return date;
  }
}
