/* import __COLOCATED_TEMPLATE__ from './color.hbs'; */
import Component from '@glimmer/component';
import { LabSample } from 'fabscale-app/models/lab-sample';
import { DateTime } from 'luxon';
import { deserializeDate } from 'fabscale-app/utilities/utils/serialize-date';
import { Chart } from 'chart.js/auto';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { service } from '@ember/service';
import PdfService from 'fabscale-app/services/pdf';
import { formatNumber } from 'fabscale-app/utilities/utils/format-number';
import { chartColors } from 'fabscale-app/utilities/fixtures/chart-colors';
import EnumLabelsService from 'fabscale-app/services/enum-labels';

interface Args {
  labSample: LabSample;
}

export default class RoastPicDetailsColor extends Component<Args> {
  @service pdf: PdfService;
  @service enumLabels: EnumLabelsService;

  printPageTask = dropTask(async () => {
    let fileName = `fabscale-roastpic-color-${DateTime.local().toISODate()}.pdf`;

    await this.pdf.generateForCurrentPageTask.perform(fileName);
  });

  @action
  drawRoastScoreChart(element: any) {
    const labels = Array.from(
      new Set(
        this.args.labSample.gourmetColorHist?.map((countBin) => countBin.count)
      )
    );
    const ctx = element.getContext('2d');

    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            data: this.args.labSample.gourmetColorHist,
            backgroundColor: '#0F7799',
          },
        ],
      },
      options: {
        parsing: {
          xAxisKey: 'count',
          yAxisKey: 'value',
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }

  @action
  drawLStarChart(element: any) {
    const labels = Array.from(
      new Set(this.args.labSample.lstarHist?.map((countBin) => countBin.count))
    );
    const ctx = element.getContext('2d');

    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            data: this.args.labSample.lstarHist,
            backgroundColor: '#0F7799',
          },
        ],
      },
      options: {
        parsing: {
          xAxisKey: 'count',
          yAxisKey: 'value',
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }

  @action
  drawAStarChart(element: any) {
    const labels = Array.from(
      new Set(this.args.labSample.astarHist?.map((countBin) => countBin.count))
    );
    const ctx = element.getContext('2d');

    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            data: this.args.labSample.astarHist,
            backgroundColor: '#0F7799',
          },
        ],
      },
      options: {
        parsing: {
          xAxisKey: 'count',
          yAxisKey: 'value',
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }

  @action
  drawBStarChart(element: any) {
    const labels = Array.from(
      new Set(this.args.labSample.bstarHist?.map((countBin) => countBin.count))
    );
    const ctx = element.getContext('2d');

    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            data: this.args.labSample.bstarHist,
            backgroundColor: '#0F7799',
          },
        ],
      },
      options: {
        parsing: {
          xAxisKey: 'count',
          yAxisKey: 'value',
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }

  get formattedCreatedDate() {
    const date = deserializeDate(this.args.labSample.createdDate as any);
    return `Recorded at ${date.toLocaleString(DateTime.DATETIME_SHORT)}`;
  }

  get colorNamesHistItems() {
    const numberBeans = this.args.labSample.numberOfBeans;
    const colorNameHist = this.args.labSample.colorNameHist;

    return colorNameHist?.map((item) => ({
      label: this.enumLabels.labSampleColorName(item.count.toString()),
      value: item.value,
      percentage: `${formatNumber((item.value / numberBeans!) * 100)}`,
      color: chartColors[0],
    }));
  }
}
