/* import __COLOCATED_TEMPLATE__ from './goal-results.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class GoalResults extends Component<GoalResults> {
  @tracked
  displayTooltip = false;

  @action
  showTip() {
    this.displayTooltip = true;
  }

  @action
  hideTip() {
    this.displayTooltip = false;
  }
}
