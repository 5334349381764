/* import __COLOCATED_TEMPLATE__ from './edit-filters.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import { FormDataModel } from 'fabscale-app/models/form-data';

interface Args {
  formModel: FormDataModel<any>;
  templateNameProperty?: string;
  canClose: boolean;
  label: string;
  allowSaveTemplate: boolean;
  onSubmit: () => Promise<boolean>;
  onReset: () => void;
  endEditMode: () => void;
}

export default class ModuleReportFiltersEditFilters extends Component<Args> {
  get templateNameProperty() {
    return this.args.templateNameProperty || 'templateName';
  }

  get isSaveAndApply() {
    return (
      this.args.allowSaveTemplate &&
      this.args.formModel.data[this.templateNameProperty]
    );
  }

  @action
  resetAndEndEditMode() {
    this.args.onReset();
    this.args.endEditMode();
  }

  submitFormTask = dropTask(async () => {
    const isSuccessful: boolean = await this.args.onSubmit();

    if (isSuccessful) {
      this.args.formModel.data[this.templateNameProperty] = null;
      this.args.endEditMode();
    }
  });
}
