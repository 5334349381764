/* import __COLOCATED_TEMPLATE__ from './paginated-table.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { restartableTask } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { sentryCaptureException } from 'fabscale-app/utilities/utils/sentry-capture-exception';
import StoreMaintenanceTaskService, {
  MaintenanceTaskQueryOptions,
} from 'fabscale-app/services/store/maintenance-task';
import { MaintenanceTask } from 'fabscale-app/models/maintenance-task';
import { SortDirection } from 'fabscale-app/models/enums/sort-options';
import { TaskStatus } from 'fabscale-app/models/enums/task-status';
import { DateRangeOptional } from 'fabscale-app/models/date-range';
import { deserializeOptionalDate } from 'fabscale-app/utilities/utils/serialize-date';
import { PageInfo } from 'fabscale-app';
import ErrorParserService from 'fabscale-app/services/error-parser';
import { MaintenanceTaskFiltersSerializable } from './filters';

interface Args {
  defaultSortBy: string;
  defaultSortDirection: SortDirection;
  pageSize?: number;
  filters: null | MaintenanceTaskFiltersSerializable;
  status: TaskStatus;
}

export default class MaintenanceTasksPaginatedTable extends Component<Args> {
  @service('store/maintenance-task')
  maintenanceTaskStore: StoreMaintenanceTaskService;

  @service('error-parser') errorParser: ErrorParserService;

  @tracked tasks: MaintenanceTask[] = [];
  @tracked pageInfo?: PageInfo;
  @tracked page = 1;
  @tracked error?: string;
  @tracked sortBy;
  @tracked sortDirection;
  @tracked _pageSize: number;

  _defaultPageSize = 10;

  get pageSize() {
    return this._pageSize || this.args.pageSize || this._defaultPageSize;
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.sortBy = this.args.defaultSortBy;
    this.sortDirection = this.args.defaultSortDirection;

    this.loadTasksTask.perform();
  }

  get query(): MaintenanceTaskQueryOptions {
    let { filters, status } = this.args;

    if (!filters) {
      return { status };
    }

    let dateRange =
      filters.dateFrom || filters.dateTo
        ? new DateRangeOptional({
            start: deserializeOptionalDate(filters?.dateFrom),
            end: deserializeOptionalDate(filters?.dateTo),
          })
        : undefined;

    // eslint-disable-next-line unused-imports/no-unused-vars
    let { dateFrom, dateTo, ...otherFilterProps } = filters;

    let query: MaintenanceTaskQueryOptions = Object.assign(
      {
        status,
      },
      otherFilterProps
    );

    if (dateRange) {
      query.dateRange = dateRange;
    }

    return query;
  }

  @action
  updatePageSize(pageSize: number) {
    this._pageSize = pageSize;
    this.page = 1;
    this.loadTasksTask.perform();
  }

  @action
  updateQuery() {
    this.page = 1;
    this.loadTasksTask.perform();
  }

  loadTasksTask = restartableTask(async () => {
    let { page, sortBy, sortDirection, query, pageSize } = this;

    this.error = undefined;

    try {
      let {
        tasks,
        pageInfo,
      }: {
        tasks: MaintenanceTask[];
        pageInfo: PageInfo;
      } = await this.maintenanceTaskStore.queryPaginated(
        query,
        {
          page,
          pageSize,
        },
        { sortBy, sortDirection }
      );

      this.tasks = tasks;
      this.pageInfo = pageInfo;
    } catch (error) {
      this.error = this.errorParser.getErrorMessage(error);
      sentryCaptureException(error);
    }
  });

  @action
  updatePage(page: number) {
    this.page = page;
    this.loadTasksTask.perform();
  }

  @action
  updateSort(sortBy: string, sortDirection: SortDirection) {
    this.sortBy = sortBy;
    this.sortDirection = sortDirection;
    this.page = 1;

    this.loadTasksTask.perform();
  }
}
