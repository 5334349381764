/* import __COLOCATED_TEMPLATE__ from './create.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { PlantAssetType } from 'fabscale-app/models/enums/plant-asset-types';
import { RecipeIdentificationStrategy } from 'fabscale-app/models/enums/recipe-identification-strategies';
import StorePlantAssetService from 'fabscale-app/services/store/plant-asset';

export default class PlantPlantAssetCreateIndex extends Component {
  @service('store/plant-asset') plantAssetStore: StorePlantAssetService;

  @tracked createdPlantAsset?: { id: string; name: string };

  createPlantAssetTask = dropTask(
    async (data: {
      name: string;
      type: PlantAssetType;
      modelIdentifier?: string;
      year?: string;
      referenceNumber?: string;
      position?: string;
      idPerLocation?: string;
      recipeIdentificationStrategy?: RecipeIdentificationStrategy;
    }) => {
      this.createdPlantAsset = undefined;

      let { id }: { id: string } = await this.plantAssetStore.create(data);

      this.createdPlantAsset = { id, name: data.name };
    }
  );
}
