/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';

interface Args {
  onSubmit: () => void;
}

export default class Form extends Component<Args> {
  @action
  submitForm(event: Event) {
    event.preventDefault();

    this.args.onSubmit();
  }
}
