/* import __COLOCATED_TEMPLATE__ from './radio-buttons.hbs'; */
import Component from '@glimmer/component';
import { assert } from '@ember/debug';
import { guidFor } from '@ember/object/internals';
import { action } from '@ember/object';
import { getScrollParent } from 'fabscale-app/utilities/utils/dom/get-scroll-parent';

interface Args {
  value: string;
  items: any[];
  labelClass?: string;
  containerClass?: string;
  onChange: (value: string) => void;
  autoFocus?: boolean;
  scrollIntoView?: boolean;
}

export default class UiRadioButtons extends Component<Args> {
  groupId: string;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.groupId = guidFor(this);

    assert(
      '<Ui::RadioButtons @onChange> onChange must be set',
      typeof this.args.onChange === 'function'
    );
  }

  @action
  autoFocusOption(element: HTMLElement) {
    // Take the currently checked item, fall back to first item
    let activeInput = (element.querySelector('input:checked') ||
      element.querySelector('input')) as HTMLInputElement | undefined;

    if (!activeInput) {
      return;
    }

    if (this.args.scrollIntoView) {
      this._scrollIntoView(activeInput);
    }

    if (this.args.autoFocus) {
      activeInput.focus({
        preventScroll: true,
      });
    }
  }

  // Note: Just using `activeInput.scrollIntoView()` does not work well for us here
  // As this not only scrolls inside of the dropdown, but it also scrolls the overall window
  // Which feels off in this scenario
  _scrollIntoView(activeInput: HTMLElement) {
    let scrollContainer = getScrollParent(activeInput);

    let scrollPos = activeInput.offsetTop - scrollContainer.offsetTop;
    scrollContainer.scrollTo(0, scrollPos - 8);
  }
}
