/* import __COLOCATED_TEMPLATE__ from './edit-density.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import LabSampleStore from 'fabscale-app/services/store/lab-sample';
import { LabSample } from 'fabscale-app/models/lab-sample';
import { FormDataModel } from 'fabscale-app/models/form-data';
import { Unit, UnitsEnums, UnitWeight } from 'fabscale-app/models/enums/units';
import { dropTask } from 'ember-concurrency';
import ErrorParserService from 'fabscale-app/services/error-parser';

interface Args {
  labSample: LabSample;
  labSampleUpdate: (labSample: LabSample) => void;
}

class FormData {
  @tracked sampleMass?: number;
  @tracked unit?: Unit;
}

export default class RoastPicDetailsEditDensity extends Component<Args> {
  @tracked units?: any;

  @tracked formData: FormData;
  @tracked formModel: FormDataModel<FormData>;

  @service errorParser: ErrorParserService;
  @service('store/lab-sample') labSamplesStore: LabSampleStore;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.formData = new FormData();
    this.formData.unit = new UnitsEnums().getLabel('GRAM' as UnitWeight);

    let { labSample } = this.args;
    if (labSample) {
      this.formData.sampleMass = labSample.sampleMass;
    }

    this.formModel = new FormDataModel<FormData>({
      data: this.formData,
      validations: [
        {
          propertyName: 'sampleMass',
          validate: (value: any) => typeof value === 'number',
          message: 'You have to enter a value range.',
        },
      ],
    });
  }

  submitFormTask = dropTask(async () => {
    await this.formModel.validate();

    if (this.formModel.isInvalid) {
      return;
    }

    let { sampleMass } = this.formData;

    let input: any = {
      sampleMass,
    };

    try {
      await this.labSamplesStore
        .updateLabSample(this.args.labSample.id, input)
        .then((labSample) => {
          this.args.labSampleUpdate(labSample);
        });
    } catch (error) {
      this.formModel.addError(this.errorParser.getErrorMessage(error));
      return;
    }
  });

  @action
  updateSampleMass(sampleMass: number) {
    this.formModel.updateProperty('sampleMass', sampleMass);
  }

  @action
  updateUnit(unit: Unit) {
    this.formModel.updateProperty('unit', unit);
  }
}
