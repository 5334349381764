/* import __COLOCATED_TEMPLATE__ from './edit-form.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import UserSessionService from 'fabscale-app/services/user-session';

interface Args {
  message: string;
}

export default class ModuleTimelineCommentEditForm extends Component<Args> {
  @service userSession: UserSessionService;

  @tracked message = '';

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.message = this.args.message;
  }

  @action
  updateMessage(message: string) {
    this.message = message;
  }
}
