/* import __COLOCATED_TEMPLATE__ from './field.hbs'; */
import Component from '@glimmer/component';
import { guidFor } from '@ember/object/internals';
import { assert } from '@ember/debug';
import { action } from '@ember/object';

interface Args {
  inputId?: string;
  error?: Error;
  label: string;
  onClickLabel?: (event: MouseEvent) => void;
}

export default class FormField extends Component<Args> {
  inputId: string;
  labelId: string;

  get hasError() {
    return !!this.args.error;
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.inputId = this.args.inputId || guidFor(this);
    this.labelId = `${this.inputId}-label`;

    assert(
      'Form field must have a @label',
      typeof this.args.label === 'string'
    );
  }

  @action
  onClickLabel(event: MouseEvent) {
    if (this.args.onClickLabel) {
      this.args.onClickLabel(event);
    }
  }
}
