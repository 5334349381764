/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { restartableTask, timeout } from 'ember-concurrency';
import { ChartTooltipItem } from 'fabscale-app/components/chart';
import { DateRange } from 'fabscale-app/models/date-range';
import { calculateDomXPosOffset } from 'fabscale-app/utilities/utils/dom/calculate-dom-x-pos-offset';

interface Args {
  xPos: number;
  yPos: number;
  dateRange: DateRange;
  items: ChartTooltipItem[];
}

export default class ChartTooltipIndex extends Component<Args> {
  @tracked xPosOffset = 0;
  container: HTMLElement;

  // Properties
  get style() {
    let { xPos, yPos } = this.args;
    let { xPosOffset } = this;

    let parts = [`left: ${xPos + xPosOffset}px;`, `top: ${yPos}px;`];
    return htmlSafe(parts.join(' '));
  }

  @action
  initializeXPosOffset(container: HTMLElement) {
    let xPosOffset = calculateDomXPosOffset(container, this.xPosOffset);

    this.xPosOffset = xPosOffset;
    this.container = container;
  }

  updateXPosOffsetTask = restartableTask(async () => {
    if (!this.container) {
      return;
    }

    // Ensure initial positioning is completed
    // Also avoid changing position multiple times in a tick
    await timeout(1);

    let xPosOffset = calculateDomXPosOffset(this.container, this.xPosOffset);

    if (xPosOffset !== this.xPosOffset) {
      this.xPosOffset = xPosOffset;
    }
  });
}
