/* import __COLOCATED_TEMPLATE__ from './card-list.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { isTesting, macroCondition } from '@embroider/macros';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { DashboardDateOption } from 'fabscale-app/routes/dashboard/index/controller';
import { DashboardCard } from 'fabscale-app/models/dashboard-config';
import BackgroundJobService from 'fabscale-app/services/background-job';
import ScreenService from 'fabscale-app/services/screen';
import SettingsService from 'fabscale-app/services/settings';
import { queryDateRangeRelative } from 'fabscale-app/utilities/utils/date-range';
import { getDashboardDateOptionDateRange } from 'fabscale-app/utils/mock-graphql/dashboard-date-option';
import { DateTime } from 'luxon';

interface Args {
  dashboardCards: DashboardCard[];
  dateOption: DashboardDateOption;
}

const DASHBOARD_REFETCH_INTERVAL = 90;
const JOB_ID = 'DashboardCardList';

export default class PageDashboardCardList extends Component<{ Args: Args }> {
  @service screen: ScreenService;
  @service backgroundJob: BackgroundJobService;
  @service settings: SettingsService;

  @tracked now = DateTime.local();

  get cutOffDate(): DateTime | undefined {
    let { dateOption } = this.args;

    return dateOption === 'TODAY' || dateOption === 'THIS_WEEK'
      ? this.now
      : undefined;
  }

  @cached
  get exactDateRange() {
    return getDashboardDateOptionDateRange(
      this.args.dateOption,
      this.settings.locationSettings,
      this.now
    );
  }

  @cached
  get overTimeDateRange() {
    let today = this.now.startOf('day');

    let overTimeDateRange = queryDateRangeRelative(today, {
      days: 14,
    });

    // If selecting LAST_WEEK, the normal 2 week range could be too little to cover the previous week
    let previousStartDate = this.exactDateRange.start
      .minus({ days: 7 })
      .startOf('day');

    if (previousStartDate.valueOf() < overTimeDateRange.start.valueOf()) {
      overTimeDateRange.start = previousStartDate;
    }

    return overTimeDateRange;
  }

  get useCompactView() {
    return this.screen.width > 1000 && this.screen.width < 1400;
  }

  @cached
  get wrappedItems(): WrappedCard[] {
    return this.args.dashboardCards.map(
      (card) =>
        new WrappedCard(card, macroCondition(isTesting()) ? true : false)
    );
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.backgroundJob.registerJob({
      id: JOB_ID,
      intervalSeconds: DASHBOARD_REFETCH_INTERVAL,
      callback: () => {
        this.now = DateTime.local();
      },
    });
  }

  willDestroy(): void {
    super.willDestroy();

    this.backgroundJob.unregisterJob(JOB_ID);
  }

  @action
  loadAllCards() {
    this.wrappedItems.forEach((item) => {
      item.isVisible = true;
    });
  }

  @action
  loadCard(opt: { elementWasEverVisible: boolean }, item: WrappedCard) {
    if (opt.elementWasEverVisible && !item.isVisible) {
      item.isVisible = true;
    }
  }
}

class WrappedCard {
  card: DashboardCard;
  @tracked isVisible = false;

  constructor(card: DashboardCard, isVisible: boolean) {
    this.card = card;
    this.isVisible = isVisible;
  }
}
