/* import __COLOCATED_TEMPLATE__ from './options.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { removeItem } from 'fabscale-app/utilities/utils/array';

interface Args {
  select: {
    selected?: any[];
    actions: {
      highlight: (value: any) => void;
      select: (values: any[]) => void;
    };
  };
}

export default class UiSelectMultipleOptions extends Component<Args> {
  get currentSelection() {
    return this.args.select.selected || [];
  }

  @action
  onChange(value: any, isChecked: boolean) {
    let selection = this.currentSelection.slice();

    if (isChecked && !selection.includes(value)) {
      selection.push(value);
    }

    if (!isChecked && selection.includes(value)) {
      removeItem(selection, value);
    }

    this.args.select.actions.highlight(value);
    this.args.select.actions.select(selection);
  }
}
