/* import __COLOCATED_TEMPLATE__ from './line-groups.hbs'; */
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { ScaleLinear, ScaleTime } from 'd3-scale';
import { area, line, curveMonotoneX } from 'd3-shape';
import {
  LineChartData,
  LineChartDataGroup,
} from 'fabscale-app/components/chart';
import { sortBy } from 'fabscale-app/utilities/utils/array';

interface Args {
  data: LineChartData[];
  xScale: ScaleTime<Date, number>;
  yScale: ScaleLinear<number, number>;
  height: number;
  highlightedLabel?: string;
  showAreas?: boolean;
  curveStyle?: 'ROUNDED';
}

export default class LineChartLineGroups extends Component<Args> {
  guid = guidFor(this);

  @cached
  get groupedData(): LineChartDataGroup[] {
    let { guid } = this;
    let { data } = this.args;

    let groupedData: LineChartDataGroup[] = [];

    sortBy(data, 'label').forEach((d) => {
      let group = groupedData.find((group) => group.label === d.label);
      if (group) {
        group.items.push(d);
      } else {
        let groupIndex = groupedData.length;
        group = {
          label: d.label,
          color: d.color,
          items: [d],
          id: `${guid}-group-${groupIndex}`,
        };
        groupedData.push(group);
      }
    });

    return splitAtZeroValues(groupedData);
  }

  get lineGenerator() {
    let { xScale, yScale } = this.args;

    let lineGenerator = line<LineChartData>()
      .x((d) => xScale(d.jsDate) as number)
      .y((d) => yScale(d.value) as number);

    if (this.args.curveStyle === 'ROUNDED') {
      lineGenerator.curve(curveMonotoneX);
    }

    return lineGenerator;
  }

  get areaGenerator() {
    let { xScale, yScale, height } = this.args;

    return area<LineChartData>()
      .x((d) => xScale(d.jsDate) as number)
      .y0(height)
      .y1((d) => yScale(d.value) as number);
  }
}

function splitAtZeroValues(
  groupedData: LineChartDataGroup[]
): LineChartDataGroup[] {
  groupedData = groupedData.slice();

  groupedData.forEach((group) => {
    let { color, label, items, id } = group;

    if (!items.some((item) => item.value === 0)) {
      return;
    }

    let remainingItems = items.slice();
    let zeroIndex = remainingItems.findIndex((item) => item.value === 0);

    while (zeroIndex >= 0) {
      let indexEnd = zeroIndex + 1;
      let items = remainingItems.slice(0, zeroIndex);

      remainingItems = remainingItems.slice(indexEnd);

      groupedData.push({
        label,
        color,
        items,
        id: `${id}-${zeroIndex}`,
      });

      zeroIndex = remainingItems.findIndex((item) => item.value === 0);
    }

    if (remainingItems.length > 0) {
      groupedData.push({
        label,
        color,
        items: remainingItems,
        id: `${id}-rest`,
      });
    }

    group.items = [];
  });

  return groupedData.filter((group) => group.items.length > 0);
}
