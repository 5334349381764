/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import {
  DashboardCard,
  DashboardCardAddConfig,
  DashboardConfig,
  getDashboardCardForType,
  getDashboardCardForTypeVariant,
} from 'fabscale-app/models/dashboard-config';
import DashboardConfigManagerService from 'fabscale-app/services/dashboard-config-manager';
import { addCardOnTop } from 'fabscale-app/utilities/utils/dashboard/add-new-card';

interface Args {
  kpiType: KpiType;
  dashboardConfig: DashboardConfig;
  usedCards: DashboardCard[];
}

export default class DashboardKpiOverviewCard extends Component<{
  Args: Args;
}> {
  @service router: RouterService;
  @service l10n: L10nService;
  @service dashboardConfigManager: DashboardConfigManagerService;

  @tracked selectedCardType = this.availableCardTypes[0]!;

  @cached
  get availableCardTypes() {
    let { l10n } = this;
    return [
      getDashboardCardForType('KPI_OVER_TIME', l10n),
      getDashboardCardForTypeVariant('KPI_OVER_TIME', l10n, {
        width: 2,
        height: 2,
        label: l10n.t('KPI over time (Large)'),
      }),
      getDashboardCardForType('KPI_PER_PLANT_ASSET', l10n),
      getDashboardCardForType('KPI_PER_RECIPE', l10n),
      getDashboardCardForType('KPI_TOTAL_VALUE', l10n),
    ];
  }

  _getLabelForCardType(card: DashboardCard): string {
    let { cardType } = card.config;
    let { width, height } = card;

    let configs = this.availableCardTypes.filter(
      (addCardConfig) => addCardConfig.type === cardType
    );

    if (configs.length === 1) {
      return configs[0]!.label;
    }

    // Find most fitting
    if (configs.length > 1) {
      let fittingConfig =
        configs.find(
          (config) => config.width === width && config.height === height
        ) || configs[0]!;
      return fittingConfig.label;
    }

    // Fallback to just the enum...
    return cardType;
  }

  get usedCardTypes(): string[] {
    let { usedCards } = this.args;

    return usedCards.map((card) => {
      return this._getLabelForCardType(card);
    });
  }

  @action
  updateCardType(selectedCardType: DashboardCardAddConfig) {
    this.selectedCardType = selectedCardType;
  }

  submitTask = dropTask(async () => {
    let newCards = await this.#addCardOnTop();

    // Do not wait on that, as that triggers a reload...
    this.dashboardConfigManager.saveDashboardCards(newCards);

    this.router.transitionTo('routes/dashboard.customize', {
      queryParams: { newCardId: newCards[0]!.id },
    });
  });

  async #addCardOnTop() {
    let { dashboardConfigManager, selectedCardType } = this;
    let { dashboardConfig, kpiType } = this.args;

    let cards =
      dashboardConfig.cards ||
      (await dashboardConfigManager.getDefaultDashboardCards());

    let size = {
      width: selectedCardType.width,
      height: selectedCardType.height,
    };
    let config = {
      cardType: selectedCardType.type,
      kpiType,
    };
    return addCardOnTop(cards, size, config);
  }
}
