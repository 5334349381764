/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { service } from '@ember/service';
import { compare } from '@ember/utils';
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import { RoastBatch } from 'fabscale-app/models/roast-batch';
import PdfService from 'fabscale-app/services/pdf';

export type RoastBatchDetailPageTab = 'CURVES' | 'GOAL_RESULTS';

interface Args {
  roastBatch: RoastBatch;
  details?: RoastBatchDetailPageTab;
}

export default class RoastBatchesDetails extends Component<Args> {
  @service pdf: PdfService;

  get hasCurves() {
    return this.args.roastBatch.numericCurveRoastBatchParameters.length > 0;
  }

  get sortedCurves() {
    let curves = this.args.roastBatch.numericCurveRoastBatchParameters;

    return curves.sort((a, b) => {
      let typeA = a.parameterType;
      let typeB = b.parameterType;

      if (typeA === typeB) {
        return 0;
      }

      if (typeA === 'PRODUCT_TEMPERATURE') {
        return -1;
      }

      if (typeB === 'PRODUCT_TEMPERATURE') {
        return 1;
      }

      return compare(typeA, typeB);
    });
  }

  get currentTab(): RoastBatchDetailPageTab {
    let { details } = this.args;

    if (!details) {
      return this.hasCurves ? 'CURVES' : 'GOAL_RESULTS';
    }

    return details;
  }

  get showCurves() {
    return this.currentTab === 'CURVES';
  }

  printPageTask = dropTask(async () => {
    let fileName = `fabscale-roast-batch-${this.args.roastBatch.id}.pdf`;

    await this.pdf.generateForCurrentPageTask.perform(fileName);
  });
}
