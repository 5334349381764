/* import __COLOCATED_TEMPLATE__ from './month-year-select.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import AnalyticsService from 'fabscale-app/services/analytics';
import { DateTime, Info } from 'luxon';

interface Args {
  centerMonth: DateTime;
  updateCenterMonth: (date: DateTime) => void;
  maxDate?: DateTime;
  minDate?: DateTime;
}

export default class DatePickerMonthYearSelect extends Component<Args> {
  @service analytics: AnalyticsService;

  get months() {
    return Info.months('short');
  }

  get years() {
    let currentYear = DateTime.local().year;
    let years = [];

    let minYear = this.args?.minDate?.year;
    let maxYear = this.args?.maxDate?.year;

    for (let i = -20; i <= 20; i++) {
      let year = currentYear + i;
      if (minYear && minYear > year) {
        continue;
      }

      if (maxYear && maxYear < year) {
        continue;
      }

      years.push(year);
    }

    return years;
  }

  get centerMonth() {
    let { month } = this.args.centerMonth;
    return this.months[month - 1];
  }

  get centerYear() {
    return this.args.centerMonth.year;
  }

  @action
  updateMonth(monthName: string) {
    let month = this.months.indexOf(monthName) + 1;
    let newCenter = this.args.centerMonth.set({ month });

    this.args.updateCenterMonth(newCenter);

    this.analytics.addEvent('date-picker-center-month', [
      {
        name: 'monthOffset',
        value: Math.floor(DateTime.local().diff(newCenter, 'months').months),
      },
    ]);
  }

  @action
  updateYear(year: number) {
    let newCenter = this.args.centerMonth.set({ year });
    this.args.updateCenterMonth(newCenter);

    this.analytics.addEvent('date-picker-center-year', [
      {
        name: 'yearOffset',
        value: Math.floor(DateTime.local().diff(newCenter, 'years').years),
      },
    ]);
  }
}
