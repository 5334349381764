/* import __COLOCATED_TEMPLATE__ from './options.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';

interface Args {
  options: { value: any }[];
  select: { actions: { select: (value: any) => void; close: () => void } };
}

export default class UiSelectOptions extends Component<Args> {
  get radioOptions() {
    let { options } = this.args;

    return options.map((option) => {
      return { value: option };
    });
  }

  @action
  onChange(value: any) {
    this.args.select.actions.select(value);
    this.args.select.actions.close();
  }
}
