/* import __COLOCATED_TEMPLATE__ from './empty-card.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { DashboardCardType } from 'fabscale-app/models/dashboard-config';

export default class PageDashboardCustomizeEmptyCard extends Component<{
  Args: {
    x: number;
    y: number;
    moveCard: (cardId: number, opt: { x: number; y: number }) => void;
    addCard: (config: {
      cardType: DashboardCardType;
      x: number;
      y: number;
      width: number;
      height: number;
    }) => void;
  };
}> {
  @action
  onDrop(event: DragEvent) {
    if (!event.dataTransfer) {
      return;
    }

    let mode = event.dataTransfer.getData('mode');

    if (mode === 'add') {
      return this._addCard(event.dataTransfer);
    }

    this._moveCard(event.dataTransfer);
  }

  @action
  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  _addCard(dataTransfer: DataTransfer) {
    let cardType = dataTransfer.getData('cardType') as DashboardCardType;
    let width = parseInt(dataTransfer.getData('width'));
    let height = parseInt(dataTransfer.getData('height'));

    if (Number.isNaN(width) || Number.isNaN(height)) {
      return;
    }

    let { x, y } = this.args;

    this.args.addCard({ cardType, x, y, width, height });
  }

  _moveCard(dataTransfer: DataTransfer) {
    let { x, y } = this.args;

    let id = parseInt(dataTransfer.getData('id'));

    if (Number.isNaN(id)) {
      return;
    }

    let offsetX = parseInt(dataTransfer.getData('offsetX'));
    let offsetY = parseInt(dataTransfer.getData('offsetY'));

    let cardX = x - offsetX;
    let cardY = y - offsetY;

    this.args.moveCard(id, { x: cardX, y: cardY });
  }
}
