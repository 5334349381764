/* import __COLOCATED_TEMPLATE__ from './invite-user.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import { UserFormData } from 'fabscale-app/components/module/user-form';
import StoreUserService from 'fabscale-app/services/store/user';

export default class CompanyUsersInviteUser extends Component {
  @service('store/user') userStore: StoreUserService;

  inviteUserTask = dropTask(
    async ({ email, name, locale, roleId, locationIds }: UserFormData) => {
      await this.userStore.create({
        email,
        name,
        locale,
        roleId,
        locationIds,
      });
    }
  );
}
