/* import __COLOCATED_TEMPLATE__ from './update-password.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { FormDataModel } from 'fabscale-app/models/form-data';
import { action } from '@ember/object';
import UserSessionService from 'fabscale-app/services/user-session';
import CognitoService from 'fabscale-app/services/cognito';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import AnalyticsService from 'fabscale-app/services/analytics';
import { getOwnConfig, macroCondition } from '@embroider/macros';
import {
  InvalidAuthorizationError,
  InvalidPasswordError,
} from 'fabscale-app/models/errors/cognito';
import Transition from '@ember/routing/-private/transition';
import RouterService from '@ember/routing/router-service';

type Args = any;

class FormData {
  @tracked oldPassword?: string;
  @tracked newPassword?: string;
}

export default class SettingsSecurityUpdatePassword extends Component<Args> {
  @service userSession: UserSessionService;
  @service cognito: CognitoService;
  @service l10n: L10nService;
  @service analytics: AnalyticsService;
  @service router: RouterService;

  @tracked formPristine = true;
  @tracked showDialog = false;

  // Properties
  private transition?: Transition;
  private shouldAbort = true;

  public formData: FormData;
  public formModel: FormDataModel<FormData>;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    let { l10n } = this;

    this.formData = new FormData();
    this.formModel = new FormDataModel({
      data: this.formData,
      validations: [
        {
          propertyName: 'newPassword',
          message: l10n.t('The new password must not be blank.'),
          validate: (value) => value,
        },
        {
          propertyName: 'newPassword',
          message: l10n.t(
            'The new password must be at least 8 characters long.'
          ),

          validate: (value) => value && value.length >= 8,
        },
        {
          propertyName: 'oldPassword',
          message: l10n.t('The current password must not be blank.'),
          validate: (value) => value,
        },
      ],
    });
  }

  willDestroy(): void {
    this.resetRouteHandler();

    super.willDestroy();
  }

  @action
  updateOldPassword(oldPassword: string) {
    this.formModel.updateProperty('oldPassword', oldPassword);
    this.addRouteHandler();
  }

  @action
  updateNewPassword(newPassword: string) {
    this.formModel.updateProperty('newPassword', newPassword);
    this.addRouteHandler();
  }

  @action
  hideSuccessMessage() {
    this.formModel.successMessage = undefined;
  }

  @action
  handleOnCancel() {
    this.shouldAbort = true;
    this.showDialog = false;
  }

  @action
  handleOnYes() {
    this.showDialog = false;
    this.shouldAbort = false;
    if (this.transition) {
      this.router.transitionTo(this.transition.to.name);
    }
  }

  updatePassword = dropTask(async () => {
    await this.formModel.validate();

    if (this.formModel.isInvalid) {
      return;
    }

    let { cognito, l10n } = this;
    let { oldPassword, newPassword } = this.formData;

    this.formModel.successMessage = undefined;

    try {
      if (macroCondition(!getOwnConfig<any>().isSimulation)) {
        await cognito.updatePassword({
          oldPassword: oldPassword!,
          newPassword: newPassword!,
        });
        this.resetRouteHandler();
      }
    } catch (error) {
      this._handleError(error);
      return;
    }

    this.formModel.hasChanges = false;
    this.formData.oldPassword = undefined;
    this.formData.newPassword = undefined;

    this.formModel.hasSuccess({
      title: l10n.t('Password saved'),
      description: l10n.t('Your password has been successfully updated.'),
    });

    this.analytics.addEvent('settings-profile-update-password');
  });

  _handleError(error: any) {
    let { l10n } = this;

    if (error instanceof InvalidAuthorizationError) {
      this.formModel.addError(
        l10n.t('The password you provided is incorrect.'),
        'oldPassword'
      );
      return;
    }

    if (error instanceof InvalidPasswordError) {
      this.formModel.addError(
        l10n.t(
          'The provided password is invalid - it needs to be at least 8 characters long.'
        ),
        'newPassword'
      );
      return;
    }

    this.formModel.addError(error.message || l10n.t('An error occurred!'));
  }

  public beforeRouteChangeHandler?: (transition: Transition) => void;

  private addRouteHandler() {
    if (!this.beforeRouteChangeHandler) {
      this.beforeRouteChangeHandler = (transition: Transition) => {
        // When we abort, it triggers a new transition to the same route
        // Which leads to an endless loop if we don't handle that here
        if (transition.from?.name === transition?.to.name) {
          return;
        }

        if (this.shouldAbort) {
          this.showDialog = true;
          transition.abort();
        }

        this.transition = transition;
        this.shouldAbort = true;
      };

      this.router.on('routeWillChange', this.beforeRouteChangeHandler);
    }
  }

  private resetRouteHandler() {
    if (this.beforeRouteChangeHandler) {
      this.router.off('routeWillChange', this.beforeRouteChangeHandler);
      this.beforeRouteChangeHandler = undefined;
    }
  }
}
