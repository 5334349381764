/* import __COLOCATED_TEMPLATE__ from './task-checkbox.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { TaskStatus } from 'fabscale-app/models/enums/task-status';
import StoreMaintenanceTaskService from 'fabscale-app/services/store/maintenance-task';
import { DateTime } from 'luxon';

interface Args {
  id: string;
  isCompleted: boolean;
  onSuccess: ({
    id,
    status,
    completionDate,
  }: {
    id: string;
    status: TaskStatus;
    completionDate?: DateTime;
  }) => void;
}

export default class MaintenanceTasksTaskCheckbox extends Component<Args> {
  @service('store/maintenance-task')
  maintenanceTaskStore: StoreMaintenanceTaskService;

  toggleStatusTask = dropTask(async () => {
    let { id, isCompleted } = this.args;

    let status: TaskStatus = isCompleted ? 'OPEN' : 'COMPLETED';

    let {
      completionDate,
    }: {
      completionDate?: DateTime;
    } = await this.maintenanceTaskStore.updateStatus(id, {
      status,
    });

    this.args.onSuccess({ id, status, completionDate });
  });
}
