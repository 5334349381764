/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import NavSidebar from 'fabscale-app/nav-sidebar/service';
import ScreenService from 'fabscale-app/services/screen';
import UserAuthenticationService from 'fabscale-app/services/user-authentication';

export default class SiteWrapperIndex extends Component {
  @service userAuthentication: UserAuthenticationService;
  @service screen: ScreenService;
  @service('nav-sidebar') navSidebarService: NavSidebar;

  @tracked navigationDesktopPinned = true;

  get isAuthenticated() {
    return this.userAuthentication.isAuthenticated;
  }

  get navigationMode(): 'MOBILE' | 'TABLET' | 'DESKTOP' {
    let { screen } = this;

    if (screen.isMobile) {
      return 'MOBILE';
    }

    if (screen.hasMobileMenu) {
      return 'TABLET';
    }

    return 'DESKTOP';
  }

  get navigationModeMobile() {
    return this.navigationMode === 'MOBILE';
  }

  get navigationModeTablet() {
    return this.navigationMode === 'TABLET';
  }

  get navigationModeDesktop() {
    return this.navigationMode === 'DESKTOP';
  }

  @action
  pinDesktopNavigation() {
    this.navigationDesktopPinned = true;
    this.navSidebarService.toggleNavSidebar(true);
  }

  @action
  unpinDesktopNavigation() {
    this.navigationDesktopPinned = false;
    this.navSidebarService.toggleNavSidebar(false);
  }
}
