/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { AbilitiesService } from 'ember-can';
import {
  DashboardCardType,
  getDashboardCardForType,
  getDashboardCardForTypeVariant,
} from 'fabscale-app/models/dashboard-config';

type Category = 'KPI' | 'ROAST' | 'TASKS' | 'STATUS_MAP' | 'OEE';

interface Configuration {
  category: Category;
  label: string;
  icon: string;
  cards: {
    type: DashboardCardType;
    width: number;
    height: number;
    label: string;
    icon: string;
  }[];
}

export default class PageDashboardCustomizeHeaderAdd extends Component {
  @service l10n: L10nService;
  @service abilities: AbilitiesService;

  @tracked selectedCategory: Category = 'KPI';

  config: Configuration[];

  get availableCards() {
    let { selectedCategory } = this;

    let config = this.config.find(
      (config) => config.category === selectedCategory
    )!;

    return config.cards;
  }

  get categoryWidth() {
    let { selectedCategory, config } = this;

    let categoryIndex = config.findIndex(
      (config) => config.category === selectedCategory
    );

    return categoryIndex + 1;
  }

  get cardsWidth() {
    return this.availableCards.length;
  }

  get separatorWidth() {
    return Math.max(this.categoryWidth, this.cardsWidth);
  }

  constructor(owner: unknown, args: any) {
    super(owner, args);

    let { l10n } = this;

    let config: Configuration[] = [
      {
        category: 'KPI',
        label: l10n.t('KPIs'),
        icon: 'dashboard',
        cards: [
          getDashboardCardForType('KPI_OVER_TIME', l10n),
          getDashboardCardForTypeVariant('KPI_OVER_TIME', l10n, {
            width: 2,
            height: 2,
            label: l10n.t('KPI over time (Large)'),
          }),
          getDashboardCardForType('KPI_PER_PLANT_ASSET', l10n),
          getDashboardCardForType('KPI_PER_RECIPE', l10n),
          getDashboardCardForType('KPI_TOTAL_VALUE', l10n),
        ],
      },
      {
        category: 'ROAST',
        label: l10n.t('Roasts'),
        icon: 'notepad',
        cards: [getDashboardCardForType('ROAST_SUMMARY', l10n)],
      },
    ];

    if (this.abilities.can('view plant-asset-info')) {
      config.push({
        category: 'STATUS_MAP',
        label: l10n.t('Status map'),
        icon: 'map',
        cards: [getDashboardCardForType('STATUS_MAP', l10n)],
      });
    }

    if (this.abilities.can('view maintenance-task')) {
      config.push({
        category: 'TASKS',
        label: l10n.t('Tasks'),
        icon: 'maintenance',
        cards: [
          getDashboardCardForType('TASKS_OPEN', l10n),
          getDashboardCardForType('TASKS_MY_OPEN', l10n),
        ],
      });
    }

    if (this.abilities.can('view oee')) {
      config.push({
        category: 'OEE',
        label: l10n.t('OEE'),
        icon: 'oee',
        cards: [
          getDashboardCardForType('OEE_KPI', l10n),
          getDashboardCardForTypeVariant('OEE_KPI_LARGE', l10n, {
            width: 2,
            height: 2,
            label: l10n.t('OEE KPI (Large)'),
          }),
        ],
      });
    }

    this.config = config;
  }

  @action
  updateSelectedCategory(category: Category) {
    this.selectedCategory = category;
  }
}
