import { modifier } from 'ember-could-get-used-to-this';
import IntersectionObserverProxy from 'fabscale-app/components/functional/intersection-observer-proxy';
import { assert } from '@ember/debug';

export function observeElement(
  element: HTMLElement,
  [intersectionObserveProxy, ...args]: [IntersectionObserverProxy | null, any[]]
) {
  if (intersectionObserveProxy === null) {
    return;
  }

  assert(
    '{{observe-element}}: You have to specify an IntersectionObserverProxy from <Functional::IntersectionObserver>',
    intersectionObserveProxy instanceof IntersectionObserverProxy
  );

  intersectionObserveProxy.observeElement(element, ...args);

  return () => intersectionObserveProxy.unobserveElement(element);
}

export default modifier(observeElement);
