/* import __COLOCATED_TEMPLATE__ from './date-picker.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { assert } from '@ember/debug';
import { tracked } from '@glimmer/tracking';
import { DateTime } from 'luxon';
import { service } from '@ember/service';
import AnalyticsService from 'fabscale-app/services/analytics';

interface Args {
  value?: DateTime;
  onChange: (date?: DateTime) => void;
  minDate?: DateTime;
  maxDate?: DateTime;
}

export default class UiDatePicker extends Component<Args> {
  @service analytics: AnalyticsService;

  @tracked _centerMonth?: DateTime;

  constructor(owner: any, args: Args) {
    super(owner, args);

    assert(
      '<Ui::DatePicker>: You have to specify an `onChange` action',
      typeof this.args.onChange === 'function'
    );
  }

  get centerMonth() {
    if (this._centerMonth) {
      return this._centerMonth;
    }

    return (
      this.args.value?.startOf('month') || DateTime.local().startOf('month')
    );
  }

  set centerMonth(date: DateTime | undefined) {
    this._centerMonth = date;
  }

  @action
  async updateDate(closeFn: () => void, date?: DateTime) {
    await this.args.onChange(date);

    this.centerMonth = undefined;

    closeFn();

    this.analytics.addEvent('date-picker-update');
  }

  @action
  updateCenterMonth(date?: DateTime) {
    this.centerMonth = date;
  }
}
