/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { checkDateRange } from 'fabscale-app/utilities/utils/check-date-range';
import { service } from '@ember/service';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { DateRange } from 'fabscale-app/models/date-range';
import { KpiCompareReportTableType } from 'fabscale-app/routes/reports/kpi-compare/index/controller';

interface Args {
  exactDateRange: DateRange;
  tableId: KpiCompareReportTableType;
}

export default class KpiCompareReportDetailsIndex extends Component<Args> {
  @service l10n: L10nService;

  get checkDates() {
    return checkDateRange(this.args.exactDateRange);
  }

  get tableIsOverTime() {
    let { tableId } = this.args;

    return (
      tableId === KpiCompareReportTableType.PerHour ||
      tableId === KpiCompareReportTableType.PerDay ||
      tableId === KpiCompareReportTableType.PerWeek ||
      tableId === KpiCompareReportTableType.PerMonth
    );
  }

  get tableIsPerRecipe() {
    return this.args.tableId === KpiCompareReportTableType.PerRecipe;
  }

  get tableIsPerMachine() {
    return this.args.tableId === KpiCompareReportTableType.PerPlantAsset;
  }

  get printHeading(): string {
    let { l10n } = this;
    let { tableId } = this.args;

    let map = {
      [KpiCompareReportTableType.PerHour]: l10n.t('Report data per hour'),
      [KpiCompareReportTableType.PerDay]: l10n.t('Report data per day'),
      [KpiCompareReportTableType.PerWeek]: l10n.t('Report data per week'),
      [KpiCompareReportTableType.PerMonth]: l10n.t('Report data per month'),
      [KpiCompareReportTableType.PerPlantAsset]: l10n.t(
        'Report data per roaster'
      ),

      [KpiCompareReportTableType.PerRecipe]: l10n.t('Report data per recipe'),
    };

    return map[tableId];
  }
}
