/* import __COLOCATED_TEMPLATE__ from './filters.hbs'; */
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { use } from 'ember-could-get-used-to-this';
import {
  PlantAssetStatus,
  PlantAssetStatuses,
} from 'fabscale-app/models/enums/plant-asset-status';
import {
  ALL_RELATIVE_TIMEFRAMES,
  RelativeTimeframe,
  RelativeTimeframeId,
} from 'fabscale-app/models/enums/relative-timeframe';
import getExactDateRangeHelper from 'fabscale-app/helpers/get-exact-date-range';
import LoadPlantAssetsResource from 'fabscale-app/helpers/resources/load-plant-assets';
import { DateRange, DateRangeOptional } from 'fabscale-app/models/date-range';
import { FormDataModel } from 'fabscale-app/models/form-data';
import { PlantAsset } from 'fabscale-app/models/plant-asset';
import { TimeRange, TimeRangeOptional } from 'fabscale-app/models/time-range';
import AnalyticsService from 'fabscale-app/services/analytics';
import AvailableDataService from 'fabscale-app/services/available-data';
import EnumLabelsService from 'fabscale-app/services/enum-labels';
import KpiDataFilterService from 'fabscale-app/services/kpi-data-filter';
import PdfService from 'fabscale-app/services/pdf';
import { getDateRangeLength } from 'fabscale-app/utilities/utils/date-range';
import {
  DateFormat,
  formatDate,
} from 'fabscale-app/utilities/utils/format-date';
import { getAsyncResourceData } from 'fabscale-app/utilities/utils/get-async-resource-value';
import {
  relativeTimeframeToDateRange,
  serializeRelativeTimeframe,
} from 'fabscale-app/utilities/utils/serialize-relative-timeframe';
import { DateTime } from 'luxon';
import { StatusMapReportFilters } from '.';
import StoreTemplate, {
  ITemplate,
  ITemplateInput,
  TemplateInput,
} from 'fabscale-app/services/store/template';
import UserSessionService from 'fabscale-app/services/user-session';
import { ReportType } from 'fabscale-app/models/enums/template';
import jsonParse from 'fabscale-app/helpers/json-parse';
import { KpiDataFilterTemplate } from 'fabscale-app/models/kpi-data-filter-template';
import { deserializeDateRangeObject } from 'fabscale-app/utilities/utils/serialize-date-range';

interface Args {
  filters: StatusMapReportFilters;
  updateFilters: (filters: StatusMapReportFilters) => void;
  defaultTimeRange: TimeRange;
  canClose: boolean;
  dateRange: DateRangeOptional | undefined;
}

class StatusMapReportFilterFormData implements StatusMapReportFilters {
  @tracked dateRange?: DateRangeOptional;
  @tracked relativeTimeframe?: RelativeTimeframe;
  @tracked timeRange: TimeRange;
  @tracked recipeIds: string[];
  @tracked plantAssetIds: string[];
  @tracked status: PlantAssetStatus[];
  @tracked templateName = '';
}

export default class PageStatusMapReportFiltersIndex extends Component<{
  Args: Args;
}> {
  @service pdf: PdfService;
  @service analytics: AnalyticsService;
  @service l10n: L10nService;
  @service availableData: AvailableDataService;
  @service kpiDataFilter: KpiDataFilterService;
  @service enumLabels: EnumLabelsService;

  @tracked formModel: FormDataModel<StatusMapReportFilterFormData>;
  @tracked formData: StatusMapReportFilterFormData;
  @tracked label: string;
  availableTemplates: KpiDataFilterTemplate[];
  @service userSession: UserSessionService;
  @service('store/template')
  templateService: StoreTemplate;

  availableRelativeTimeframes: RelativeTimeframeId[] =
    ALL_RELATIVE_TIMEFRAMES.map((timeframe) => timeframe.id);

  availablePlantAssetStatus = PlantAssetStatuses;

  @use
  allPlantAssetsAsyncResource = new LoadPlantAssetsResource(() => [
    { hasStatusInfo: true },
  ]);

  get allPlantAssets() {
    return getAsyncResourceData(this.allPlantAssetsAsyncResource, []);
  }

  get selectedRelativeTimeframeId() {
    let { relativeTimeframe } = this.formData;
    return relativeTimeframe
      ? serializeRelativeTimeframe(relativeTimeframe)
      : undefined;
  }

  @cached
  get exactDateRange(): DateRange | undefined {
    let { dateRange, timeRange } = this.formData;

    if (!dateRange || !dateRange.start || !dateRange.end) {
      return undefined;
    }

    return getExactDateRangeHelper({
      dateRange: dateRange as DateRange,
      timeRange,
      maxNow: true,
    });
  }

  get activeFilters(): { label: string; value: string }[] {
    let { l10n, enumLabels, allPlantAssets } = this;

    let dateStart = this.args.dateRange?.start;
    let dateEnd = this.args.dateRange?.end;
    let { status, plantAssetIds } = this.args.filters;

    let selectedPlantAssets = allPlantAssets.filter((plantAsset) =>
      plantAssetIds.includes(plantAsset.id)
    );

    let filters = [
      {
        label: l10n.t('Date from'),
        value: dateStart ? formatDate(dateStart, DateFormat.Date) : '-',
      },
      {
        label: l10n.t('Date to'),
        value: dateEnd ? formatDate(dateEnd, DateFormat.Date) : '-',
      },
      {
        label: l10n.t('Plant assets'),
        value:
          selectedPlantAssets.length > 0
            ? selectedPlantAssets
                .map((plantAsset) => plantAsset.name)
                .join(', ')
            : l10n.t('All plant assets'),
      },
      {
        label: l10n.t('Status'),
        value:
          status.length > 0
            ? status
                .map((status) => enumLabels.plantAssetStatus(status))
                .join(', ')
            : l10n.t('Any status'),
      },
    ];

    return filters;
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    let {
      dateRange,
      relativeTimeframe,
      timeRange = this.args.defaultTimeRange,
      plantAssetIds,
      status,
    } = this.args.filters;
    this._initFormModel({
      dateRange,
      relativeTimeframe,
      timeRange,
      plantAssetIds,
      status,
    });

    this.availableTemplates = this.kpiDataFilter.getAvailableTemplates();
    this.label =
      this.availableTemplates.length > 0
        ? 'Choose template or Time frame'
        : 'Choose timeframe';
  }

  @action
  updateDateRange(dateRange: DateRangeOptional) {
    if (dateRange.start && dateRange.end) {
      this.formModel.updateProperty('dateRange', dateRange);
    } else {
      this.formData.dateRange = dateRange;
    }

    this.formData.relativeTimeframe = undefined;

    this.analytics.addEvent('status-map-report-filter-date-range', [
      {
        name: 'dateRangeLength',
        value: getDateRangeLength(dateRange),
      },
    ]);
  }

  @action
  updateTimeRange(timeRange: TimeRange) {
    this.formModel.updateProperty('timeRange', timeRange);

    this.analytics.addEvent('status-map-report-filter-time-range', [
      {
        name: 'start',
        value: timeRange.start,
      },
      {
        name: 'end',
        value: timeRange.end,
      },
    ]);
  }

  @action
  updatePlantAssets(plantAssets: PlantAsset[]) {
    let plantAssetIds = plantAssets.map((plantAsset) => plantAsset.id);
    this.formModel.updateProperty('plantAssetIds', plantAssetIds);

    this.analytics.addEvent('status-map-report-filter-plant-assets', [
      { name: 'selectedCount', value: plantAssets.length },
    ]);
  }

  @action
  updateStatus(status: PlantAssetStatus[]) {
    this.formModel.updateProperty('status', status);

    this.analytics.addEvent('status-map-report-filter-status', [
      { name: 'selectedCount', value: status.length },
    ]);
  }

  @action
  handleTemplateChange(template: ITemplate) {
    const {
      relativeTimeframe,
      dateRange,
      timeRange,
      status,
      plantAssetIds,
      recipeIds,
    } = jsonParse(template.templateFilters);

    this.formData.relativeTimeframe = relativeTimeframe;
    this.formData.dateRange = relativeTimeframe
      ? relativeTimeframeToDateRange(relativeTimeframe)
      : deserializeDateRangeObject(dateRange);

    this.formData.timeRange =
      timeRange && timeRange.start && timeRange.end
        ? (timeRange as TimeRange)
        : this.args.defaultTimeRange;

    this.formData.status = status;
    this.formData.plantAssetIds = plantAssetIds || [];
    this.formData.recipeIds = recipeIds || [];
  }

  @action
  onSelectRelativeTimeframe(relativeTimeframe?: RelativeTimeframe) {
    this.formData.relativeTimeframe = relativeTimeframe;
    const { timeRange } = this.formData;

    this.formData.dateRange = relativeTimeframe
      ? relativeTimeframeToDateRange(relativeTimeframe, timeRange)
      : { start: undefined, end: undefined };
  }

  printPageTask = dropTask(async () => {
    let fileName = `fabscale-status-map-report-${DateTime.local().toISODate()}.pdf`;

    await this.pdf.generateForCurrentPageTask.perform(fileName);
  });

  applyFiltersTask = dropTask(async () => {
    let isValid: boolean = await this.formModel.validate();

    if (!isValid) {
      return false;
    }

    let {
      dateRange,
      relativeTimeframe,
      timeRange,
      plantAssetIds,
      status,
      templateName,
    } = this.formData;

    if (templateName) {
      const templateFilters = JSON.stringify({
        dateRange,
        relativeTimeframe,
        absoluteTimeframe: relativeTimeframe ? undefined : dateRange,
        timeRange,
        plantAssetIds,
        status,
      });

      const template: ITemplateInput = new TemplateInput({
        userId: this.userSession.user!.id,
        templateName: templateName,
        templateFilters: templateFilters,
        reportType: ReportType.STATUS_MAP_REPORT,
      });

      await this.templateService.createTemplate(template);
      this.availableTemplates = this.kpiDataFilter.getAvailableTemplates();
    }

    this.args.updateFilters({
      dateRange,
      relativeTimeframe,
      timeRange,
      plantAssetIds,
      status,
    });

    return true;
  });

  @action
  clearFilters() {
    this._initFormModel({
      dateRange: undefined,
      relativeTimeframe: undefined,
      timeRange: this.args.defaultTimeRange,
      plantAssetIds: [],
      status: [],
    });
  }

  @action
  resetFilters() {
    let {
      dateRange,
      relativeTimeframe,
      timeRange = this.args.defaultTimeRange,
      plantAssetIds,
      status,
    } = this.args.filters;

    this._initFormModel({
      dateRange,
      relativeTimeframe,
      timeRange,
      plantAssetIds,
      status,
    });
  }

  _initFormModel(initialData: {
    dateRange?: DateRangeOptional;
    relativeTimeframe?: RelativeTimeframe;
    timeRange: TimeRangeOptional;
    plantAssetIds: string[];
    status: PlantAssetStatus[];
  }) {
    let { l10n } = this;
    let { dateRange, relativeTimeframe, timeRange, status, plantAssetIds } =
      initialData;

    this.formData = new StatusMapReportFilterFormData();

    if (!dateRange && relativeTimeframe) {
      dateRange = relativeTimeframeToDateRange(relativeTimeframe);
    }

    Object.assign(this.formData, {
      dateRange,
      relativeTimeframe,
      timeRange,
      status,
      plantAssetIds,
    });

    this.formModel = new FormDataModel<StatusMapReportFilterFormData>({
      data: this.formData,
      validations: [
        {
          propertyName: 'dateRange',
          validate(value: DateRangeOptional | undefined) {
            return Boolean(value?.start) && Boolean(value?.end);
          },

          message: l10n.t('You have to select a date range.'),
        },
      ],
    });
  }
}
