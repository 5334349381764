/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';

export default class SiteWrapperMobileIndex extends Component {
  @tracked navigationIsActive = false;

  _outsideClickElement = document.documentElement;
  _sidebarElement?: HTMLElement;
  _outsideClickHandler?: (event: MouseEvent) => void;

  willDestroy() {
    super.willDestroy();

    this._removeOutsideClickHandler();
  }

  @action
  showNavigation() {
    this.navigationIsActive = true;

    this.addOutsideClickHandlerTask.perform();
  }

  @action
  closeNavigation() {
    this.navigationIsActive = false;

    this._removeOutsideClickHandler();
  }

  @action
  registerElement(element: HTMLElement) {
    this._sidebarElement = element;
  }

  addOutsideClickHandlerTask = task(async () => {
    // We wait here a tick, otherwise this picks up the initial click of opening the navigation
    // and closes it right away (as the click is outside of the tab-bar)
    await timeout(1);

    if (!this._sidebarElement) {
      return;
    }

    let _outsideClickHandler = (event: MouseEvent) => {
      if (!this._sidebarElement!.contains(event.target as HTMLElement)) {
        this.closeNavigation();
      }
    };
    this._outsideClickHandler = _outsideClickHandler;

    this._outsideClickElement.addEventListener('click', _outsideClickHandler);
  });

  _removeOutsideClickHandler() {
    if (this._outsideClickHandler) {
      this._outsideClickElement.removeEventListener(
        'click',
        this._outsideClickHandler
      );
      this._outsideClickHandler = undefined;
    }
  }
}
