/* import __COLOCATED_TEMPLATE__ from './filters.hbs'; */
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { use } from 'ember-could-get-used-to-this';
import {
  PlantAssetAlertLevel,
  PlantAssetAlertLevels,
} from 'fabscale-app/models/enums/plant-asset-alert-level';
import {
  PlantAssetAlarmType,
  PlantAssetAlarmTypes,
} from 'fabscale-app/models/enums/plant-asset-alarm-type';
import getExactDateRangeHelper from 'fabscale-app/helpers/get-exact-date-range';
import LoadPlantAssetsResource from 'fabscale-app/helpers/resources/load-plant-assets';
import { DateRange, DateRangeOptional } from 'fabscale-app/models/date-range';
import { FormDataModel } from 'fabscale-app/models/form-data';
import { PlantAsset } from 'fabscale-app/models/plant-asset';
import { TimeRange } from 'fabscale-app/models/time-range';
import AnalyticsService from 'fabscale-app/services/analytics';
import AvailableDataService from 'fabscale-app/services/available-data';
import EnumLabelsService from 'fabscale-app/services/enum-labels';
import PdfService from 'fabscale-app/services/pdf';
import { getDateRangeLength } from 'fabscale-app/utilities/utils/date-range';
import {
  DateFormat,
  formatDate,
} from 'fabscale-app/utilities/utils/format-date';
import { getAsyncResourceData } from 'fabscale-app/utilities/utils/get-async-resource-value';
import { PlantAssetAlertOverviewFilters } from '.';
import AvailableAlertExternalIdsResource from 'fabscale-app/helpers/resources/load-available-alert-external-ids';
import * as _ from 'lodash-es';
import { getActualDateRange } from 'fabscale-app/utilities/utils/actual-date-range';

interface Args {
  filters: PlantAssetAlertOverviewFilters;
  defaultTimeRange: TimeRange;
  updateFilters: (filters: PlantAssetAlertOverviewFilters) => void;
}

class PlantAssetAlertOverviewFilterFormData {
  @tracked dateRange?: DateRangeOptional;
  @tracked timeRange: TimeRange;
  @tracked plantAssetIds: string[];
  @tracked alarmLevels?: PlantAssetAlertLevel[];
  @tracked alarmTypes?: PlantAssetAlarmType[];
  @tracked alarmExternalIds?: string[];
  @tracked alarmTitle?: string;
}

export default class PlantAssetAlertOverviewFiltersComponent extends Component<Args> {
  @service availableData: AvailableDataService;
  @service analytics: AnalyticsService;
  @service l10n: L10nService;
  @service pdf: PdfService;
  @service enumLabels: EnumLabelsService;

  @tracked formModel: FormDataModel<PlantAssetAlertOverviewFilterFormData>;
  @tracked formData: PlantAssetAlertOverviewFilterFormData;

  @cached
  get exactDateRange(): DateRange | undefined {
    const { timeRange, dateRange } = this.formData;

    return getExactDateRangeHelper({
      dateRange: getActualDateRange(dateRange),
      timeRange,
      maxNow: true,
    });
  }

  allAlarmLevels = PlantAssetAlertLevels;
  allAlarmTypes = PlantAssetAlarmTypes;

  @use
  allPlantAssetsAsyncResource = new LoadPlantAssetsResource(() => [{}]);

  @use
  availableAlarmExternalIdsAsyncResource = new AvailableAlertExternalIdsResource(
    () => [{}]
  );

  get allPlantAssets() {
    return getAsyncResourceData(this.allPlantAssetsAsyncResource, []);
  }

  get availableAlarmExternalIds() {
    return getAsyncResourceData(
      this.availableAlarmExternalIdsAsyncResource,
      []
    );
  }

  get selectedPlantAssets(): PlantAsset[] {
    const {
      allPlantAssets,
      formData: { plantAssetIds },
    } = this;

    return (
      allPlantAssets?.filter(({ id }) => plantAssetIds?.includes(id)) ?? []
    );
  }

  get selectedExternalIds(): string[] {
    const {
      availableAlarmExternalIds,
      formData: { alarmExternalIds },
    } = this;

    return availableAlarmExternalIds.filter((externalId) =>
      alarmExternalIds?.includes(externalId)
    );
  }

  get activeFilters(): { label: string; value: string }[] {
    const { l10n, selectedPlantAssets, enumLabels } = this;
    const { alarmTypes, alarmLevels, alarmTitle } = this.formData;
    const dateStart = this.args.filters.dateRange?.start;
    const dateEnd = this.args.filters.dateRange?.end;

    return [
      {
        label: l10n.t('Date from'),
        value: dateStart ? formatDate(dateStart, DateFormat.Date) : '-',
      },
      {
        label: l10n.t('Date to'),
        value: dateEnd ? formatDate(dateEnd, DateFormat.Date) : '-',
      },
      {
        label: l10n.t('Alarm title'),
        value: alarmTitle?.length ? alarmTitle : '-',
      },
      {
        label: l10n.t('Plant assets'),
        value:
          selectedPlantAssets.length > 0
            ? selectedPlantAssets
                .map((plantAsset) => plantAsset.name)
                .join(', ')
            : l10n.t('All plant assets'),
      },
      {
        label: l10n.t('Alarm type'),
        value:
          alarmTypes && alarmTypes.length > 0
            ? alarmTypes
                .map((alertType: PlantAssetAlarmType) =>
                  enumLabels.plantAssetAlarmType(alertType)
                )
                .join(', ')
            : l10n.t('All alarm types'),
      },
      {
        label: l10n.t('Alarm level'),
        value:
          alarmLevels && alarmLevels.length > 0
            ? alarmLevels
                .map((alarmLevel: PlantAssetAlertLevel) =>
                  enumLabels.plantAssetAlertLevel(alarmLevel)
                )
                .join(', ')
            : l10n.t('All alarm levels'),
      },
    ];
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    const {
      dateRange,
      timeRange = this.args.defaultTimeRange,
      plantAssetIds = [],
      alarmLevels,
      alarmTypes,
      alarmExternalIds = [],
      alarmTitle,
    } = this.args.filters;

    this.initFormModel({
      dateRange,
      timeRange,
      plantAssetIds,
      alarmLevels,
      alarmTypes,
      alarmExternalIds,
      alarmTitle,
    });
  }

  @action
  updateDateRange(dateRange?: DateRangeOptional) {
    this.formModel.updateProperty('dateRange', dateRange);
    this.analytics.addEvent('roast-batches-filter-date-range', [
      {
        name: 'dateRangeLength',
        value: dateRange ? getDateRangeLength(dateRange) : -1,
      },
    ]);
  }

  @action
  updateTimeRange(timeRange: TimeRange) {
    this.formModel.updateProperty('timeRange', timeRange);
    this.analytics.addEvent('roast-batches-filter-time-range', [
      {
        name: 'start',
        value: timeRange.start,
      },
      {
        name: 'end',
        value: timeRange.end,
      },
    ]);
  }

  @action
  updatePlantAssets(plantAssets: PlantAsset[]) {
    const plantAssetIds = plantAssets.map((plantAsset) => plantAsset.id);
    this.formModel.updateProperty('plantAssetIds', plantAssetIds);
  }

  @action
  updateAlertLevel(alarmLevels?: PlantAssetAlertLevel[]) {
    this.formModel.updateProperty('alarmLevels', alarmLevels);
  }

  @action
  updateAlarmTypes(alarmTypes?: PlantAssetAlarmType[]) {
    this.formModel.updateProperty('alarmTypes', alarmTypes);
  }

  @action
  updateExternalId(alarmExternalIds?: string[]) {
    this.formModel.updateProperty('alarmExternalIds', alarmExternalIds);
  }

  @action
  updateAlarmTitle(alarmTitle?: string) {
    this.formModel.updateProperty('alarmTitle', alarmTitle);
  }

  applyFiltersTask = dropTask(async () => {
    const isValid = await this.formModel.validate();

    if (!isValid) {
      return false;
    }

    const {
      dateRange,
      timeRange,
      plantAssetIds,
      alarmLevels,
      alarmTypes,
      alarmExternalIds,
      alarmTitle,
    } = this.formData;

    const actualTimeRange = _.isEqual(timeRange, this.args.defaultTimeRange)
      ? undefined
      : timeRange;

    this.args.updateFilters({
      dateRange,
      timeRange: actualTimeRange,
      plantAssetIds,
      alarmLevels,
      alarmTypes,
      alarmExternalIds,
      alarmTitle,
    });

    return true;
  });

  @action
  clearFilters() {
    this.initFormModel({
      dateRange: undefined,
      timeRange: this.args.defaultTimeRange,
      plantAssetIds: [],
      alarmTypes: [],
      alarmLevels: [],
      alarmExternalIds: [],
      alarmTitle: '',
    });
  }

  @action
  resetFilters() {
    const {
      dateRange,
      timeRange = this.args.defaultTimeRange,
      plantAssetIds = [],
      alarmLevels = [],
      alarmTypes = [],
      alarmExternalIds = [],
      alarmTitle = '',
    } = this.args.filters;

    this.initFormModel({
      dateRange,
      timeRange,
      plantAssetIds,
      alarmLevels,
      alarmTypes,
      alarmExternalIds,
      alarmTitle,
    });
  }

  printPageTask = dropTask(async () => {
    const fileName = `fabscale-plant-asset-alerts.pdf`;

    await this.pdf.generateForCurrentPageTask.perform(fileName);
  });

  private initFormModel(initialData: {
    dateRange?: DateRangeOptional;
    timeRange: TimeRange;
    plantAssetIds: string[];
    alarmLevels?: PlantAssetAlertLevel[];
    alarmTypes?: PlantAssetAlarmType[];
    alarmExternalIds: string[];
    alarmTitle?: string;
  }) {
    const {
      dateRange,
      timeRange,
      alarmLevels,
      plantAssetIds,
      alarmTypes,
      alarmExternalIds,
      alarmTitle,
    } = initialData;

    this.formData = new PlantAssetAlertOverviewFilterFormData();

    Object.assign(this.formData, {
      dateRange,
      timeRange,
      plantAssetIds,
      alarmLevels,
      alarmTypes,
      alarmExternalIds,
      alarmTitle,
    });

    this.formModel = new FormDataModel<PlantAssetAlertOverviewFilterFormData>({
      data: this.formData,
      validations: [],
    });
  }
}
