/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import {
  PlantAssetStatusReason,
  PlantAssetStatusReasons,
} from 'fabscale-app/models/enums/plant-asset-status-reason';
import { PlantAssetStateInfo } from 'fabscale-app/models/plant-asset-state-info';
import ErrorParserService from 'fabscale-app/services/error-parser';
import StorePlantAssetInfoService from 'fabscale-app/services/store/plant-asset-info';
import { logError } from 'fabscale-app/utilities/utils/log-error';

interface Args {
  plantAssetStateInfo: PlantAssetStateInfo;
  onComplete: () => void;
}

export default class PageDashboardCardsStatusMapForm extends Component<{
  Args: Args;
}> {
  @service('store/plant-asset-info')
  plantAssetInfoStore: StorePlantAssetInfoService;

  @service errorParser: ErrorParserService;

  availableReasons = PlantAssetStatusReasons;

  @tracked reason?: PlantAssetStatusReason;
  @tracked comment?: string;
  @tracked error?: string;

  get showLoadingSpinner() {
    return (
      this.submitFormTask.isRunning || !!this.submitFormTask.lastSuccessful
    );
  }

  submitFormTask = dropTask(async () => {
    let { plantAssetStateInfo } = this.args;
    let { reason } = this;
    let comment = this.comment || undefined;

    if (!reason) {
      return;
    }

    this.error = undefined;

    try {
      await this.plantAssetInfoStore.update(plantAssetStateInfo.id, {
        reason,
        comment,
      });
    } catch (error) {
      logError(error);

      this.error = this.errorParser.getErrorMessage(error);
      return;
    }

    this.args.onComplete();
  });

  @action
  updateReason(reason?: PlantAssetStatusReason) {
    this.reason = reason;
  }

  @action
  updateComment(comment: string) {
    this.comment = comment;
  }
}
