/* import __COLOCATED_TEMPLATE__ from './line-chart-two-axes.hbs'; */
import { uniq } from 'fabscale-app/utilities/utils/array';
import Component from '@glimmer/component';
import { chartColors } from 'fabscale-app/utilities/fixtures/chart-colors';
import { LineChartItemWithTooltipInput } from 'fabscale-app/components/chart';

interface Args {
  data: LineChartItemWithTooltipInput[];
}

export default class LineChartTwoAxes extends Component<Args> {
  colorA = chartColors[0];
  colorB = chartColors[1];

  // In contrast to the default one, do not sort by label here to keep the "natural" order of the items
  get dataLabels() {
    return uniq(this.args.data.map((item) => item.label));
  }

  get labelA() {
    return this.dataLabels[0];
  }

  get labelB() {
    return this.dataLabels[1];
  }

  get dataA() {
    let { labelA } = this;

    if (!labelA) {
      return [];
    }

    return this.args.data.filter((item) => item.label === labelA);
  }

  get dataB() {
    let { labelB } = this;

    if (!labelB) {
      return [];
    }

    return this.args.data.filter((item) => item.label === labelB);
  }

  get unitA() {
    return this.dataA[0]?.unit;
  }

  get unitB() {
    return this.dataB[0]?.unit;
  }
}
