/* import __COLOCATED_TEMPLATE__ from './pagination-horizontal.hbs'; */
import { action } from '@ember/object';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { assert } from '@ember/debug';

interface Args {
  totalItems: number;
  pageSize: number;
  // 0 based
  currentOffset: number;
  paginationType: 'items' | 'pages';

  moveTo: (currentOffset: number) => void;
}

export default class UiPaginationHorizontal extends Component<Args> {
  constructor(owner: unknown, args: Args) {
    super(owner, args);

    const { paginationType } = this.args;

    assert(
      'You have to specify a paginationType (items | pages)',
      paginationType === 'items' || paginationType === 'pages'
    );
  }

  get itemsPaginationCurrentOffsetEnd() {
    return Math.min(
      this.args.currentOffset + this.args.pageSize,
      this.args.totalItems
    );
  }

  get pagesPagenationCurrentOffsetEnd() {
    return Math.min(
      this.args.currentOffset * this.args.pageSize + this.args.pageSize,
      this.args.totalItems
    );
  }

  get currentOffsetEnd() {
    const { paginationType } = this.args;

    if ('items' === paginationType) {
      return this.itemsPaginationCurrentOffsetEnd;
    }
    if ('pages' === paginationType) {
      return this.pagesPagenationCurrentOffsetEnd;
    }

    return;
  }

  get itemsSliderMaxValue() {
    return this.args.totalItems - this.args.pageSize;
  }

  get pagessSliderMaxValue() {
    return Math.floor(this.args.totalItems / this.args.pageSize);
  }

  get sliderMaxValue() {
    const { paginationType } = this.args;

    if ('items' === paginationType) {
      return this.itemsSliderMaxValue;
    }
    if ('pages' === paginationType) {
      return this.pagessSliderMaxValue;
    }

    return;
  }

  get sliderWidth() {
    let { pageSize, totalItems } = this.args;

    return htmlSafe(`${(100 * pageSize) / totalItems}%`);
  }

  get canMovePrevious() {
    return this.args.currentOffset > 0;
  }

  get canMoveNext() {
    return (
      this.currentOffsetEnd && this.currentOffsetEnd < this.args.totalItems
    );
  }

  @action
  onUpdateSlider(event: InputEvent) {
    let { value } = event.target as HTMLInputElement;

    this.args.moveTo(parseInt(value));
  }

  @action
  moveToNext(event: MouseEvent) {
    event.preventDefault();
    let newOffset = this.args.currentOffset + 1;
    this.args.moveTo(newOffset);
  }

  @action
  moveToPrevious(event: MouseEvent) {
    event.preventDefault();
    let newOffset = this.args.currentOffset - 1;
    this.args.moveTo(newOffset);
  }

  @action
  moveToNextPage(event: MouseEvent) {
    event.preventDefault();
    if (this.sliderMaxValue) {
      let newOffset = Math.min(
        this.args.currentOffset + this.args.pageSize,
        this.sliderMaxValue
      );
      this.args.moveTo(newOffset);
    }
  }

  @action
  moveToPreviousPage(event: MouseEvent) {
    event.preventDefault();
    let newOffset = Math.max(this.args.currentOffset - this.args.pageSize, 0);
    this.args.moveTo(newOffset);
  }
}
