/* import __COLOCATED_TEMPLATE__ from './edit.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import RouterService from '@ember/routing/router-service';
import StorePlantAssetService from 'fabscale-app/services/store/plant-asset';
import { PlantAsset } from 'fabscale-app/models/plant-asset';
import { PlantAssetType } from 'fabscale-app/models/enums/plant-asset-types';
import { RecipeIdentificationStrategy } from 'fabscale-app/models/enums/recipe-identification-strategies';

interface Args {
  plantAsset: PlantAsset;
}

export default class PlantPlantAssetEdit extends Component<Args> {
  @service router: RouterService;
  @service('store/plant-asset') plantAssetStore: StorePlantAssetService;

  updatePlantAssetTask = dropTask(
    async (data: {
      name: string;
      type: PlantAssetType;
      modelIdentifier?: string;
      year?: string;
      referenceNumber?: string;
      position?: string;
      idPerLocation?: string;
      recipeIdentificationStrategy?: RecipeIdentificationStrategy;
    }) => {
      let { id } = this.args.plantAsset;

      await this.plantAssetStore.update(id, data);

      this.router.transitionTo(
        'routes/plant-settings.plant-assets.show.index',
        id
      );
    }
  );
}
