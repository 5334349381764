/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
import Component from '@glimmer/component';
import { KpiData } from 'fabscale-app/models/kpi-data';
import { KpiDataGrouped } from 'fabscale-app/models/kpi-data-grouped';
import {
  calculateTendency,
  checkTendencyIsPositive,
} from 'fabscale-app/utilities/utils/transform-kpi-data';

export default class PageDashboardCardsKpiPerRecipeCard extends Component<{
  Args: {
    kpiDataPerRecipe: KpiDataGrouped;
    overallData: KpiData;
    comparativeData: KpiData;
  };
}> {
  get overallValue() {
    let { value } = this.args.overallData;
    return value;
  }

  get unit() {
    return this.args.overallData.unit;
  }

  get comparativeValue() {
    let { overallValue } = this;
    let { comparativeData } = this.args;
    let { higherIsBetter } = this.args.overallData;

    if (!comparativeData) {
      return undefined;
    }

    let previousValue = comparativeData.value;

    let tendency =
      !overallValue && !previousValue
        ? undefined
        : calculateTendency(previousValue, overallValue);
    let tendencyIsPositive =
      typeof tendency === 'number'
        ? checkTendencyIsPositive(tendency, higherIsBetter)
        : true;

    return {
      tendency: tendency ? tendency * 100 : tendency,
      tendencyIsPositive,
    };
  }
}
