/* import __COLOCATED_TEMPLATE__ from './group.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { TaskStatus } from 'fabscale-app/models/enums/task-status';
import { MaintenanceTask } from 'fabscale-app/models/maintenance-task';
import { DateTime } from 'luxon';

interface Args {
  tasks: MaintenanceTask[];
}

export default class TaskCalendarMonthPopover extends Component<Args> {
  @action
  onToggleStatus({
    id,
    completionDate,
    status,
  }: {
    id: string;
    completionDate?: DateTime;
    status: TaskStatus;
  }) {
    this.args.tasks
      .filter((task) => task.id === id)
      .forEach((task) => {
        task.status = status;
        task.completionDate = completionDate;
      });
  }
}
