/* import __COLOCATED_TEMPLATE__ from './roles-table.hbs'; */
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { SuccessMessage } from 'fabscale-app';
import CellActions from 'fabscale-app/components/page/settings/roles/overview/cells/actions';
import CellName from 'fabscale-app/components/page/settings/roles/overview/cells/name';
import CellPermissions from 'fabscale-app/components/page/settings/roles/overview/cells/permissions';
import { Permission } from 'fabscale-app/models/enums/permissions';
import { TableColumnDefinitionInput } from 'fabscale-app/models/table-column-definition';
import { UserRole } from 'fabscale-app/models/user-role';
import ErrorParserService from 'fabscale-app/services/error-parser';
import StoreUserRoleService, {
  PermissionDefinition,
} from 'fabscale-app/services/store/user-role';
import UserSessionService from 'fabscale-app/services/user-session';
import { filterRecords } from 'fabscale-app/utilities/utils/filter-records';

interface Args {
  roles: UserRole[];
  availablePermissionDefinitions: PermissionDefinition[];
}

export default class SettingsRolesOverviewRolesTable extends Component<Args> {
  @service userSession: UserSessionService;
  @service l10n: L10nService;
  @service('store/user-role') userRoleStore: StoreUserRoleService;
  @service('error-parser') errorParser: ErrorParserService;

  @tracked searchTerm?: string;
  @tracked selectedPermission?: Permission;
  @tracked error?: string;
  @tracked successMessage?: SuccessMessage;

  // We manually remove them from the filtered rows, to avoid re-fetching data unnecessarily
  @tracked _deletedRoleIds: string[] = [];

  get filteredRoles() {
    let { roles } = this.args;
    let { searchTerm, selectedPermission, _deletedRoleIds } = this;

    if (_deletedRoleIds) {
      roles = roles.filter((role) => !_deletedRoleIds.includes(role.id));
    }

    if (searchTerm) {
      roles = filterRecords(roles, searchTerm, {
        propertyNames: ['name'],
      });
    }

    if (selectedPermission) {
      roles = roles.filter((role) =>
        role.permissions.includes(selectedPermission!)
      );
    }

    return roles;
  }

  get roleColumns() {
    let { l10n } = this;

    let columns: TableColumnDefinitionInput[] = [
      {
        title: l10n.t('Name'),
        propertyName: 'name',
        component: CellName,
        noCompactTitle: true,
        tdClass: 'min-width-5',
      },
      {
        title: l10n.t('Permissions'),
        propertyName: 'permissions',
        component: CellPermissions,
        sortBy: 'permissions.length',
        noCompactTitle: true,
      },
      {
        component: CellActions,
        tdClass: 'nowrap mobile-width-100p',
      },
    ];

    return columns;
  }

  @action
  updateSearchTerm(searchTerm: string) {
    this.searchTerm = searchTerm;
  }

  @action
  updateSelectedPermission(permission?: Permission) {
    this.selectedPermission = permission;
  }

  deleteRoleTask = dropTask(async (role: UserRole) => {
    let { userRoleStore, l10n, errorParser } = this;

    this.error = undefined;
    this.successMessage = undefined;

    try {
      await userRoleStore.delete(role.id);
    } catch (error) {
      this.error = errorParser.getErrorMessage(error);
      return;
    }

    this.successMessage = {
      title: l10n.t('Role successfully deleted'),
      description: l10n.t(
        'The role "{{name}}" was successfully deleted from your company.',
        { name: role.name }
      ),
    };

    let deletedRoleIds = this._deletedRoleIds.slice();
    deletedRoleIds.push(role.id);
    this._deletedRoleIds = deletedRoleIds;
  });
}
