/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { TaskGroup } from 'fabscale-app/helpers/maintenance-tasks/group-by-plant-asset-for-date';
import { DateTime } from 'luxon';

interface Args {
  date: DateTime;
  groups: TaskGroup[];
}

export default class TaskCalendarMonthDayList extends Component<Args> {
  maxShowCount = 4;

  get mainGroups() {
    let { groups } = this.args;
    let { maxShowCount } = this;

    return groups.length <= maxShowCount
      ? groups
      : groups.slice(0, maxShowCount - 1);
  }

  get otherGroups() {
    let { groups } = this.args;
    let { maxShowCount } = this;

    return groups.length <= maxShowCount ? [] : groups.slice(maxShowCount - 1);
  }
}
