/* import __COLOCATED_TEMPLATE__ from './login-form.hbs'; */
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import {
  InvalidAuthorizationError,
  MfaCodeRequiredError,
  NewPasswordRequiredError,
  UserNotFoundError,
} from 'fabscale-app/models/errors/cognito';
import { FormDataModel } from 'fabscale-app/models/form-data';
import UserAuthenticationService from 'fabscale-app/services/user-authentication';

interface Args {
  username?: string;
  onMustSetInitialPassword: ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) => void;
  onMustEnterMfaCode: ({ username }: { username: string }) => void;
}

export default class LoginForm extends Component<Args> {
  @service userAuthentication: UserAuthenticationService;
  @service l10n: L10nService;

  formData: FormData;
  formModel: FormDataModel<FormData>;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.formData = new FormData();

    if (this.args.username) {
      this.formData.username = this.args.username;
    }

    this.formModel = new FormDataModel({
      data: this.formData,
      validations: [],
    });
  }

  loginTask = dropTask(async () => {
    await this.formModel.validate();

    if (this.formModel.isInvalid) {
      return;
    }

    let { userAuthentication } = this;
    let { username, password } = this.formData;

    try {
      await userAuthentication.login({ username, password });
    } catch (error) {
      this._handleError(error);
    }
  });

  _handleError(error: any) {
    let { l10n } = this;
    let { username, password } = this.formData;

    if (error instanceof NewPasswordRequiredError) {
      this.args.onMustSetInitialPassword({ username, password });
      return;
    }

    if (error instanceof MfaCodeRequiredError) {
      this.args.onMustEnterMfaCode({ username });
      return;
    }

    if (error instanceof UserNotFoundError) {
      this.formModel.addError(
        l10n.t('No user with this email address was found.'),
        'username'
      );
      return;
    }

    if (error instanceof InvalidAuthorizationError) {
      this.formModel.addError(
        l10n.t('The password you provided is incorrect.'),
        'password'
      );
      return;
    }

    this.formModel.addError(error.message || l10n.t('An error occurred!'));
  }

  @action
  updateUsername(username: string) {
    this.formModel.updateProperty('username', username);
  }

  @action
  updatePassword(password: string) {
    this.formModel.updateProperty('password', password);
  }
}

class FormData {
  @tracked username = '';
  @tracked password = '';
}
