/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import EnumLabelsService from 'fabscale-app/services/enum-labels';
import { deserializeOptionalDate } from 'fabscale-app/utilities/utils/serialize-date';
import { MaintenanceTaskFiltersSerializable } from '..';

interface Args {
  filters: MaintenanceTaskFiltersSerializable | null;
  updateFilters: (filters: MaintenanceTaskFiltersSerializable) => void;
}

export default class MaintenanceTasksFiltersSelectedFilters extends Component<Args> {
  @service enumLabels: EnumLabelsService;

  get dateFrom() {
    return deserializeOptionalDate(this.args.filters?.dateFrom);
  }

  get dateTo() {
    return deserializeOptionalDate(this.args.filters?.dateTo);
  }

  get requirements() {
    let requirements = this.args.filters?.requirements || [];
    return requirements
      .map((requirement) => this.enumLabels.taskRequirement(requirement))
      .join(', ');
  }
}
