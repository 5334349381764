/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import 'fabscale-app/scss/fabscale/modules/task-calendar.scss';
import { MaintenanceTaskQueryOptions } from 'fabscale-app/services/store/maintenance-task';
import { DateTime } from 'luxon';
import { MaintenanceTaskFiltersSerializable } from '../filters';

export type CalendarMode = undefined | 'DAY';

interface Args {
  mode: CalendarMode;
  date: DateTime;
  filters: MaintenanceTaskFiltersSerializable | null;
  updateMode: (mode: CalendarMode) => void;
  updateDate: (date: DateTime) => void;
  updateFilters: (filters: MaintenanceTaskFiltersSerializable | null) => void;
}

export default class TaskCalendarComponent extends Component<Args> {
  get modeDay() {
    return this.args.mode === 'DAY';
  }

  get query(): MaintenanceTaskQueryOptions {
    let { filters } = this.args;

    if (!filters) {
      return { status: 'OPEN' };
    }

    // eslint-disable-next-line unused-imports/no-unused-vars
    let { status = 'OPEN', dateFrom, dateTo, ...otherFilterProps } = filters;

    let query: MaintenanceTaskQueryOptions = Object.assign(
      {
        status,
      },
      otherFilterProps
    );

    return query;
  }

  @action
  selectDate(date: DateTime) {
    if (this.args.mode !== 'DAY') {
      this.args.updateMode('DAY');
    }

    this.args.updateDate(date);
  }
}
