/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { DateTimeString } from 'fabscale-app';
import {
  TaskCategories,
  TaskCategory,
} from 'fabscale-app/models/enums/task-categories';
import {
  TaskRequirement,
  TaskRequirements,
} from 'fabscale-app/models/enums/task-requirements';
import { TaskStatus } from 'fabscale-app/models/enums/task-status';
import { DateRangeOptional } from 'fabscale-app/models/date-range';
import { FormDataModel } from 'fabscale-app/models/form-data';
import { PlantAsset } from 'fabscale-app/models/plant-asset';
import { PlantAssetArea } from 'fabscale-app/models/plant-asset-area';
import { UserInfo } from 'fabscale-app/models/user-info';
import AnalyticsService from 'fabscale-app/services/analytics';
import AvailableDataService from 'fabscale-app/services/available-data';
import EnumLabelsService from 'fabscale-app/services/enum-labels';
import KpiDataFilterService from 'fabscale-app/services/kpi-data-filter';
import PdfService from 'fabscale-app/services/pdf';
import {
  deserializeOptionalDate,
  serializeOptionalDate,
} from 'fabscale-app/utilities/utils/serialize-date';
import { DateTime } from 'luxon';

export interface MaintenanceTaskFiltersSerializable {
  search?: string;
  status?: TaskStatus;
  category?: TaskCategory;
  assignedUserIds?: string[];
  plantAssetIds?: string[];
  plantAssetAreaIds?: string[];
  requirements?: TaskRequirement[];
  dateFrom?: DateTimeString;
  dateTo?: DateTimeString;
}

interface Args {
  hideDateRange?: boolean;
  showStatus?: boolean;
  filters: null | MaintenanceTaskFiltersSerializable;
  updateFilters: (filters: MaintenanceTaskFiltersSerializable) => void;
}

class MaintenanceTaskFilterData {
  @tracked search?: string;
  @tracked dateRange?: DateRangeOptional;
  @tracked assignedUserId?: string;
  @tracked taskStatus: TaskStatus = 'OPEN';
  @tracked category?: TaskCategory;
  @tracked status?: TaskStatus;
  @tracked plantAssetId?: string;
  @tracked plantAssetAreaId?: string;
  @tracked requirements: TaskRequirement[] = [];

  constructor(data: {
    search?: string;
    category?: TaskCategory;
    status?: TaskStatus;
    assignedUserIds: string[];
    plantAssetIds: string[];
    plantAssetAreaIds: string[];
    requirements: TaskRequirement[];
    dateFrom?: DateTimeString;
    dateTo?: DateTimeString;
  }) {
    this.search = data.search;
    this.status = data.status;
    this.category = data.category;
    this.assignedUserId = data.assignedUserIds[0];
    this.plantAssetId = data.plantAssetIds[0];
    this.plantAssetAreaId = data.plantAssetAreaIds[0];
    this.requirements = data.requirements;
    this.dateRange =
      data.dateFrom || data.dateTo
        ? {
            start: deserializeOptionalDate(data.dateFrom),
            end: deserializeOptionalDate(data.dateTo),
          }
        : undefined;
  }
}

export default class MaintenanceTaskCalendarFilters extends Component<{
  Args: Args;
}> {
  @service pdf: PdfService;
  @service analytics: AnalyticsService;
  @service l10n: L10nService;
  @service availableData: AvailableDataService;
  @service kpiDataFilter: KpiDataFilterService;
  @service enumLabels: EnumLabelsService;

  @tracked formModel: FormDataModel<MaintenanceTaskFilterData>;
  @tracked formData: MaintenanceTaskFilterData;

  availableCategories = TaskCategories;
  availableRequirements = TaskRequirements;
  availableStatus: TaskStatus[] = ['OPEN', 'COMPLETED'];

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    let {
      search,
      category,
      status,
      assignedUserIds = [],
      plantAssetIds = [],
      plantAssetAreaIds = [],
      requirements = [],
      dateFrom,
      dateTo,
    } = this.args.filters || {};

    this._initFormModel({
      search,
      status,
      category,
      assignedUserIds,
      plantAssetIds,
      plantAssetAreaIds,
      requirements,
      dateFrom,
      dateTo,
    });
  }

  @action
  updateAssignedUser(user?: UserInfo) {
    this.formModel.updateProperty('assignedUserId', user?.id);
  }

  @action
  updatePlantAsset(plantAsset?: PlantAsset) {
    this.formModel.updateProperty('plantAssetId', plantAsset?.id);
  }

  @action
  updatePlantAssetArea(plantAssetArea?: PlantAssetArea) {
    this.formModel.updateProperty('plantAssetAreaId', plantAssetArea?.id);
  }

  printPageTask = dropTask(async () => {
    let fileName = `fabscale-kpi-compare-${DateTime.local().toISODate()}.pdf`;

    await this.pdf.generateForCurrentPageTask.perform(fileName);
  });

  applyFiltersTask = dropTask(async () => {
    let isValid: boolean = await this.formModel.validate();

    if (!isValid) {
      return false;
    }

    let {
      search,
      category,
      status,
      assignedUserId,
      plantAssetId,
      plantAssetAreaId,
      requirements,
      dateRange,
    } = this.formData;

    this.args.updateFilters({
      search,
      status,
      category,
      assignedUserIds: assignedUserId ? [assignedUserId] : undefined,
      plantAssetIds: plantAssetId ? [plantAssetId] : undefined,
      plantAssetAreaIds: plantAssetAreaId ? [plantAssetAreaId] : undefined,
      requirements: requirements.length > 0 ? requirements : undefined,
      dateFrom: serializeOptionalDate(dateRange?.start),
      dateTo: serializeOptionalDate(dateRange?.end),
    });

    // Push selected filters to the GTM data layer
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
      event: 'filter_used',
      reportName: 'MAINTENANCE_TASKS',
      filters: {
        search,
        category,
        status,
        assignedUserId,
        plantAssetId,
        plantAssetAreaId,
        requirements,
        dateRange: dateRange
          ? {
              start: serializeOptionalDate(dateRange.start),
              end: serializeOptionalDate(dateRange.end),
            }
          : undefined,
      },
    });

    return true;
  });

  @action
  clearFilters() {
    this._initFormModel({
      search: undefined,
      status: undefined,
      category: undefined,
      assignedUserIds: [],
      plantAssetIds: [],
      plantAssetAreaIds: [],
      requirements: [],
      dateFrom: undefined,
      dateTo: undefined,
    });
  }

  @action
  resetFilters() {
    let {
      search,
      category,
      status,
      assignedUserIds = [],
      plantAssetIds = [],
      plantAssetAreaIds = [],
      requirements = [],
      dateFrom,
      dateTo,
    } = this.args.filters || {};

    this._initFormModel({
      search,
      category,
      status,
      assignedUserIds,
      plantAssetIds,
      plantAssetAreaIds,
      requirements,
      dateFrom,
      dateTo,
    });
  }

  _initFormModel(initialData: {
    search?: string;
    category?: TaskCategory;
    status?: TaskStatus;
    assignedUserIds: string[];
    plantAssetIds: string[];
    plantAssetAreaIds: string[];
    requirements: TaskRequirement[];
    dateFrom?: DateTimeString;
    dateTo?: DateTimeString;
  }) {
    let {
      search,
      category,
      status,
      assignedUserIds,
      plantAssetIds,
      plantAssetAreaIds,
      requirements,
      dateFrom,
      dateTo,
    } = initialData;

    this.formData = new MaintenanceTaskFilterData({
      search,
      category,
      status,
      assignedUserIds,
      plantAssetIds,
      plantAssetAreaIds,
      requirements,
      dateFrom,
      dateTo,
    });

    this.formModel = new FormDataModel<MaintenanceTaskFilterData>({
      data: this.formData,
      validations: [],
    });
  }
}
