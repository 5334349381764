/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { DateRange } from 'fabscale-app/models/date-range';
import { StatusMapReportTableType } from 'fabscale-app/routes/reports/status-map/controller';
import { checkDateRange } from 'fabscale-app/utilities/utils/check-date-range';

interface Args {
  exactDateRange: DateRange;
  tableId: StatusMapReportTableType;
}

export default class StatusMapReportDetailsIndex extends Component<Args> {
  @service l10n: L10nService;

  get checkDates() {
    return checkDateRange(this.args.exactDateRange);
  }

  get printHeading(): string {
    let { l10n } = this;
    let { tableId } = this.args;

    let map: { [key in StatusMapReportTableType]: string } = {
      PER_DAY: l10n.t('Report data per day'),
      PER_WEEK: l10n.t('Report data per week'),
      PER_MONTH: l10n.t('Report data per month'),
    };

    return map[tableId];
  }
}
