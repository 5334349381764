/* import __COLOCATED_TEMPLATE__ from './filters.hbs'; */
import Component from '@glimmer/component';
import { restartableTask, timeout } from 'ember-concurrency';
import { NumericRoastBatchParameterType } from 'fabscale-app/models/enums/roast-batch-parameter-type';
import { TIMEOUTS } from 'fabscale-app/utilities/fixtures/timeouts';

interface Args {
  parameterType?: NumericRoastBatchParameterType;
  search?: string;
  updateParameterType: (parameterType?: NumericRoastBatchParameterType) => void;
  updateSearch: (search?: string) => void;
}

export default class PlantParameterGoalsOverviewFilters extends Component<Args> {
  updateSearchTask = restartableTask(async (search: string) => {
    await timeout(TIMEOUTS.searchTypeDebounce);

    this.args.updateSearch(search);
  });
}
