import { service } from '@ember/service';
import LabSampleStore from 'fabscale-app/services/store/lab-sample';
import RoastPicShowIndexController from 'fabscale-app/routes/roast-pic/show/controller';
import Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';

export default class RoastPicShowRoute extends Route {
  @service('store/lab-sample') roastPicStore: LabSampleStore;

  resetController(
    controller: RoastPicShowIndexController,
    isExiting: boolean,
    transition: Transition
  ) {
    super.resetController(controller, isExiting, transition);

    if (isExiting) {
      controller.tab = undefined;
    }
  }

  async model(params: { id: string }) {
    let { roastPicStore } = this;
    let { id } = params;
    return await roastPicStore.getLabSampleById(id);
  }
}
