/* import __COLOCATED_TEMPLATE__ from './picture-data.hbs'; */
import Component from '@glimmer/component';
import { LabSample } from 'fabscale-app/models/lab-sample';
import { DateTime } from 'luxon';
import { deserializeDate } from 'fabscale-app/utilities/utils/serialize-date';
import { service } from '@ember/service';
import PdfService from 'fabscale-app/services/pdf';
import { dropTask } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface Args {
  firstLabSample: LabSample;
  secondLabSample: LabSample;
}

export default class RoastPicDetailsPictureData extends Component<Args> {
  @service pdf: PdfService;
  @tracked currentOffset = 0;

  @action
  moveTo(offset: number) {
    this.currentOffset = offset;
  }

  get formattedFirstCreatedDate() {
    const date = deserializeDate(this.args.firstLabSample.createdDate as any);
    return `Recorded at ${date.toLocaleString(DateTime.DATETIME_SHORT)}`;
  }

  get formattedSecondCreatedDate() {
    const date = deserializeDate(this.args.secondLabSample.createdDate as any);
    return `Recorded at ${date.toLocaleString(DateTime.DATETIME_SHORT)}`;
  }

  printPageTask = dropTask(async () => {
    let fileName = `fabscale-roastpic-picture-data-${DateTime.local().toISODate()}.pdf`;

    await this.pdf.generateForCurrentPageTask.perform(fileName);
  });
}
