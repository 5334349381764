/* import __COLOCATED_TEMPLATE__ from './table-over-time.hbs'; */
import Component from '@glimmer/component';
import { DateTime } from 'luxon';
import { service } from '@ember/service';
import { Interval } from 'fabscale-app/models/enums/intervals';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { KpiDataOverTime } from 'fabscale-app/models/kpi-data-over-time';
import { KpiCompareReportTableData } from 'fabscale-app/helpers/kpi-compare-report/table-columns';
import { TableColumnDefinitionInput } from 'fabscale-app/models/table-column-definition';

interface Args {
  data: KpiDataOverTime[];
  interval: Interval;
}

export default class KpiCompareReportDetailsTableOverTime extends Component<Args> {
  @service l10n: L10nService;

  get data() {
    let { data } = this.args;
    let { dateFormatString } = this;

    let allDates: DateTime[] = [];

    data
      .map((item) => item.dateBinValues)
      .forEach((dateBinValues) => {
        dateBinValues.forEach((dateBinValue) => {
          let { dateRange } = dateBinValue;

          if (!allDates.find((dateB) => +dateRange.start === +dateB)) {
            allDates.push(dateRange.start);
          }
        });
      });

    // Remove dates that are after today
    let today = +DateTime.local();
    allDates = allDates.filter((date) => +date <= today).sort();

    return allDates.map((date) => {
      let searchLabel = date.toFormat(dateFormatString);

      let item: KpiCompareReportTableData = {
        label: date,
        searchLabel: searchLabel.toLowerCase(),
      };

      data.forEach((kpiDataItem) => {
        let dateBinValue = kpiDataItem.dateBinValues.find(
          (item) => +date === +item.dateRange.start
        );

        let { unit } = kpiDataItem;

        if (dateBinValue) {
          item[kpiDataItem.type] = { value: dateBinValue.value, unit };
        }
      });

      return item;
    });
  }

  get dateFormatString() {
    let { l10n } = this;
    let { interval } = this.args;

    if (interval === 'HOUR') {
      return 'f';
    }

    if (interval === 'WEEK') {
      return `'${l10n.t('Week')}' W - D`;
    }

    if (interval === 'MONTH') {
      return 'LLLL yyyy';
    }

    // Default: day
    return 'D (EEE)';
  }

  get labelColumn(): TableColumnDefinitionInput {
    let { l10n, dateFormatString } = this;

    return {
      title: l10n.t('Date'),
      propertyName: 'label',
      cellType: 'DATE',
      cellData: {
        dateFormatString,
      },
    };
  }
}
