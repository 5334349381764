import { assert } from '@ember/debug';
import { DashboardCardPosition } from 'fabscale-app/models/dashboard-config';
import { CardGrid } from './card-grid';
import { logError } from '../log-error';
import { tryReplaceCardPositionsManually } from './cards-replace-manually';

interface Pos {
  x: number;
  y: number;
}

export function moveCardToPos<T extends DashboardCardPosition>(
  cards: T[],
  cardId: number,
  pos: Pos
): undefined | T[] {
  let cardIndex = cards.findIndex((card) => card.id === cardId);

  if (cardIndex < 0) {
    logError(`Could not find dashboard card with id ${cardId} to move.`);
    return undefined;
  }

  let card: T = cards[cardIndex]!;

  // Simple case: At target there is a card with same size (but not the same card)
  // In this case, replace them
  let cardAtTarget = findCardAtPos(cards, pos);
  if (
    cardAtTarget &&
    cardAtTarget.id !== cardId &&
    cardAtTarget.height === card.height &&
    cardAtTarget.width === card.width
  ) {
    return swapCards(cards, card, cardAtTarget);
  }

  let movedCard = Object.assign({}, card, { x: pos.x, y: pos.y });

  if (!cardIsInsideGrid(movedCard)) {
    return undefined;
  }

  let movedCards = cards.slice();
  movedCards.splice(cardIndex, 1, movedCard);

  let overlappingCards = movedCards.filter((cardCompare) => {
    return cardCompare.id !== cardId && cellsOverlap(movedCard, cardCompare);
  });

  if (overlappingCards.length === 0) {
    return movedCards;
  }

  // If all overlapping cards are contained in moved card, swap them all
  let minX = Math.min(...overlappingCards.map((card) => card.x));
  let minY = Math.min(...overlappingCards.map((card) => card.y));
  let maxX = Math.max(...overlappingCards.map((card) => card.x + card.width));
  let maxY = Math.max(...overlappingCards.map((card) => card.y + card.height));
  let fullyOverlapping =
    minX >= movedCard.x &&
    minY >= movedCard.y &&
    maxX <= movedCard.x + movedCard.width &&
    maxY <= movedCard.y + movedCard.height;

  let baseOffset = getOffset(card, pos);

  // Just flip it!
  if (fullyOverlapping) {
    let moved = tryMoveOverlappingCards(
      card,
      movedCard,
      overlappingCards,
      movedCards,
      baseOffset
    );

    if (moved) {
      return moved;
    }
  }

  let offset = invertOffset(baseOffset);

  // Else, we try to move cards by their offset
  // Here we need to make sure to verify if it nothing is overlapping when moving
  let moved = tryMoveCardsByOffset(movedCards, overlappingCards, offset);

  if (moved) {
    return moved;
  }

  // Finally, we have some special case handling when shifting cards
  if (overlappingCards.length === 1) {
    return tryShiftingCards(
      movedCards,
      movedCard,
      overlappingCards[0]!,
      offset
    );
  }

  return undefined;
}

export function addRowOnTop<T extends DashboardCardPosition>(
  dashboardCards: T[]
): T[] {
  return dashboardCards.map((card) => {
    return Object.assign({}, card, { y: card.y + 1 });
  });
}

function tryMoveOverlappingCards<T extends DashboardCardPosition>(
  card: T,
  movedCard: T,
  overlappingCards: T[],
  movedCards: T[],
  offset: Pos
): T[] | undefined {
  let cardsToMove: T[] = [card].concat(overlappingCards);

  if (needsManualReplacement(movedCard, cardsToMove)) {
    return tryReplaceCardPositionsManually(
      movedCard,
      card,
      movedCards,
      overlappingCards
    );
  }

  let grid = new CardGrid(cardsToMove, movedCard);

  // first special case: if the grid is "complex" (related to the ratio of the grid)
  // three cases: flip horizontally, flip vertically, or diagonally
  if (offset.x === 0) {
    grid.flipVertically();
  } else if (offset.y === 0) {
    grid.flipHorizontally();
  } else {
    grid.flipDiagonally();
  }

  let updatedCards = grid.getUpdatedCards();

  updatedCards.forEach((updatedCard) => {
    let pos = movedCards.findIndex((card) => card.id === updatedCard.id);

    assert(`Could not find card with id ${updatedCard.id}`, pos > -1);

    movedCards.splice(pos, 1, updatedCard);
  });

  return movedCards;
}

function tryShiftingCards<T extends DashboardCardPosition>(
  cards: T[],
  movedCard: T,
  cardToSwap: T,
  offset: Pos
) {
  /*
    Example case we want to handle
    A A B C
    --> move B to 0/0
    --> expected:
    B A A C

    A A B -
    --> move B to 1/0
    --> expected:
    - B A A
  */

  // Try to position it directly left/right of the new card
  let tryCard = Object.assign({}, cardToSwap);

  // Was moved to the left, try to position on right side of new card
  if (offset.x > 0) {
    tryCard.x = movedCard.x + movedCard.width;
  }

  // Was moved to the right, try to position on left side of new card
  if (offset.x < 0) {
    tryCard.x = movedCard.x - movedCard.width - 1;
  }

  // Was moved up, try to position on below new card
  if (offset.y > 0) {
    tryCard.y = movedCard.y + movedCard.height;
  }

  // Was moved to bottom, try to position above new card
  if (offset.y < 0) {
    tryCard.y = movedCard.y - movedCard.height - 1;
  }

  if (cardIsInsideGrid(tryCard) && cardHasNoOverlap(tryCard, cards)) {
    let pos = cards.indexOf(cardToSwap);
    cards.splice(pos, 1, tryCard);
    return cards;
  }

  return undefined;
}

function tryMoveCardsByOffset<T extends DashboardCardPosition>(
  cards: T[],
  cardsToSwap: T[],
  offset: Pos
) {
  for (let card of cardsToSwap) {
    let index = cards.indexOf(card);

    let swappedCard = Object.assign({}, card, {
      x: card.x + offset.x,
      y: card.y + offset.y,
    });

    if (
      !cardIsInsideGrid(swappedCard) ||
      !cardHasNoOverlap(swappedCard, cards)
    ) {
      return undefined;
    }

    cards.splice(index, 1, swappedCard);
  }

  return cards;
}

function swapCards<T extends DashboardCardPosition>(
  cards: T[],
  card1: T,
  card2: T
) {
  let x1 = card1.x;
  let x2 = card2.x;
  let y1 = card1.y;
  let y2 = card2.y;

  let swappedCard1 = Object.assign({}, card1, { x: x2, y: y2 });
  let swappedCard2 = Object.assign({}, card2, { x: x1, y: y1 });

  let pos1 = cards.indexOf(card1);
  let pos2 = cards.indexOf(card2);

  let movedCards = cards.slice();
  movedCards.splice(pos1, 1, swappedCard1);
  movedCards.splice(pos2, 1, swappedCard2);

  return movedCards;
}

function findCardAtPos<T extends DashboardCardPosition>(cards: T[], pos: Pos) {
  let tempCard = Object.assign({ id: 0, width: 1, height: 1 }, pos);
  return cards.find((card) => cellsOverlap(card, tempCard));
}

function cardIsInsideGrid(card: DashboardCardPosition): boolean {
  if (card.x + card.width > 4) {
    return false;
  }

  if (card.y < 0 || card.x < 0) {
    return false;
  }

  return true;
}

function getOffset<T extends DashboardCardPosition>(card: T, pos: Pos) {
  return {
    x: pos.x - card.x,
    y: pos.y - card.y,
  };
}

function invertOffset(baseOffset: Pos) {
  let offset = {
    x: baseOffset.x * -1,
    y: baseOffset.y * -1,
  };

  return offset;
}

export function cardHasNoOverlap(
  card: DashboardCardPosition,
  cards: DashboardCardPosition[]
) {
  return !cards.some((compareCard) => {
    if (compareCard.id === card.id) {
      return false;
    }

    return cellsOverlap(card, compareCard);
  });
}

function cellsOverlap(
  card1: DashboardCardPosition,
  card2: DashboardCardPosition
): boolean {
  // top left & bottom right corner of both cells
  let l1 = {
    x: card1.x,
    y: card1.y,
  };
  let r1 = {
    x: card1.x + card1.width,
    y: card1.y + card1.height,
  };
  let l2 = {
    x: card2.x,
    y: card2.y,
  };
  let r2 = {
    x: card2.x + card2.width,
    y: card2.y + card2.height,
  };

  // If one rectangle is on left side of other
  if (l1.x >= r2.x || l2.x >= r1.x) {
    return false;
  }

  // If one rectangle is above other
  if (r1.y <= l2.y || r2.y <= l1.y) {
    return false;
  }

  return true;
}

function needsManualReplacement<T extends DashboardCardPosition>(
  movedCard: T,
  cardsToMove: T[]
): boolean {
  let minX = Math.min(...cardsToMove.map((card) => card.x), movedCard.x);
  let minY = Math.min(...cardsToMove.map((card) => card.y), movedCard.y);
  let maxX = Math.max(
    ...cardsToMove.map((card) => card.x + card.width),
    movedCard.x + movedCard.width
  );
  let maxY = Math.max(
    ...cardsToMove.map((card) => card.y + card.height),
    movedCard.y + movedCard.height
  );

  let xDiff = maxX - minX;
  let yDiff = maxY - minY;

  let xRatio = xDiff / movedCard.width;
  let yRatio = yDiff / movedCard.height;

  // When the ratio is too small, it means reordering by flipping will not work, as it will split cards
  if (xDiff > movedCard.width && xRatio < 1.5) {
    return true;
  }

  if (yDiff > movedCard.height && yRatio < 1.5) {
    return true;
  }

  return false;
}
