/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { DateRange } from 'fabscale-app/models/date-range';
import { PlantAsset } from 'fabscale-app/models/plant-asset';
import { DateTime } from 'luxon';

interface Args {
  plantAsset: PlantAsset;
}

export default class PlantPlantAssetDetailsStatusInfoIndex extends Component<Args> {
  get dateRange() {
    return new DateRange({
      start: DateTime.local().minus({ days: 90 }),
      end: DateTime.local(),
    });
  }
}
