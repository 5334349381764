/* import __COLOCATED_TEMPLATE__ from './user-image.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { hashStringToColor } from 'fabscale-app/utilities/utils/color';
import { assert } from '@ember/debug';
import UserSessionService from 'fabscale-app/services/user-session';

interface Args {
  userName?: string;
}

export default class UserImage extends Component<Args> {
  @service userSession: UserSessionService;

  get userName() {
    if (this.args.userName) {
      return this.args.userName.trim();
    }

    assert(
      `<Module::UserImage>: No logged in user found`,
      Boolean(this.userSession.user?.name)
    );

    return this.userSession.user!.name.trim();
  }

  get initials(): string {
    let { userName } = this;
    let nameParts = userName.split(' ');

    let firstName = nameParts[0]!.trim().toUpperCase();

    if (nameParts.length < 2) {
      return firstName[0]!;
    }

    let lastName = nameParts[nameParts.length - 1]!.trim().toUpperCase();
    return `${firstName[0]}${lastName[0]}`;
  }

  get backgroundColor() {
    return hashStringToColor(this.userName);
  }
}
