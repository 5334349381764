/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { FormDataModel } from 'fabscale-app/models/form-data';

interface Args {
  formModel: FormDataModel<any>;
}

export default class ModuleReportFilters extends Component<Args> {
  @tracked isEditMode = true;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this._initEditMode();
  }

  async _initEditMode() {
    this.isEditMode = await this.args.formModel.hasValidationErrors();
  }

  @action
  startEditMode() {
    this.isEditMode = true;
  }

  @action
  async endEditMode() {
    this.isEditMode = false;
  }
}
