/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class LoginIndex extends Component {
  @tracked mustEnterNewPassword = false;
  @tracked newPasswordData?: { username: string; password: string };
  @tracked mfaCodeData?: { username: string };
  @tracked mustEnterMfaCode = false;

  @action
  toggleSetInitialPassword({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) {
    this.mustEnterMfaCode = false;
    this.mustEnterNewPassword = true;
    this.newPasswordData = { username, password };
  }

  @action
  toggleEnterMfaCode({ username }: { username: string }) {
    this.mustEnterMfaCode = true;
    this.mustEnterNewPassword = false;
    this.mfaCodeData = { username };
  }
}
