/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask, task } from 'ember-concurrency';
import { SuccessMessage } from 'fabscale-app';
import { User } from 'fabscale-app/models/user';
import ErrorParserService from 'fabscale-app/services/error-parser';
import StoreUserService from 'fabscale-app/services/store/user';
import UserSessionService from 'fabscale-app/services/user-session';
import { removeItem } from 'fabscale-app/utilities/utils/array';
import { logError } from 'fabscale-app/utilities/utils/log-error';

export default class SettingsCompanyUsers extends Component<any> {
  @service l10n: L10nService;
  @service userSession: UserSessionService;
  @service('store/user') userStore: StoreUserService;
  @service('error-parser') errorParser: ErrorParserService;

  @tracked allUsers: User[] = [];
  @tracked error?: string;
  @tracked successMessage?: SuccessMessage;

  get company() {
    return this.userSession.company;
  }

  constructor(owner: unknown, args: any) {
    super(owner, args);

    this.loadAllUsersTask.perform();
  }

  loadAllUsersTask = task(async () => {
    let { userStore } = this;

    this.error = undefined;

    try {
      this.allUsers = await userStore.findAll();
    } catch (error) {
      this._handleError(error);
    }
  });

  deleteUserTask = dropTask(async (email: string) => {
    let { userStore, l10n } = this;

    this.error = undefined;
    this.successMessage = undefined;

    try {
      await userStore.delete(email);
    } catch (error) {
      this._handleError(error);
      return;
    }

    this.successMessage = {
      title: l10n.t('User successfully removed'),
      description: l10n.t(
        'The user {{email}} was successfully removed from your company.',
        { email }
      ),
    };

    // Manually remove the user from the list
    let { allUsers } = this;
    let user = allUsers.find((user) => user.email === email);

    if (user) {
      removeItem(allUsers, user);
      this.allUsers = allUsers;
    }
  });

  reInviteUserTask = dropTask(async (email: string) => {
    let { userStore, l10n } = this;

    let { allUsers } = this;
    let user = allUsers.find((user) => user.email === email);

    if (!user) {
      this._handleError(`Could not find user ${email}`);
      return;
    }

    let { name, role, locale } = user;
    let locationIds = user.locations.map((location) => location.id);

    this.error = undefined;
    this.successMessage = undefined;

    try {
      await userStore.delete(email);
      await userStore.create({
        email,
        name,
        roleId: role.id,
        locale,
        locationIds,
      });
    } catch (error) {
      this._handleError(error);
      return;
    }

    this.successMessage = {
      title: l10n.t('User successfully re-invited'),
      description: l10n.t(
        'The user {{email}} has been sent a new invitation email.',
        { email }
      ),
    };

    this.loadAllUsersTask.perform();
  });

  _handleError(error: any) {
    logError(error);
    this.error = this.errorParser.getErrorMessage(error);
  }
}
