/* import __COLOCATED_TEMPLATE__ from './tab-bar.hbs'; */
import Component from '@glimmer/component';
import { RoastBatchDetailPageTab } from '.';

interface Args {
  currentTab: RoastBatchDetailPageTab;
  hasCurves: boolean;
}

export default class RoastBatchesDetailsTabBar extends Component<Args> {
  queryCurves: { details: RoastBatchDetailPageTab } = {
    details: 'CURVES',
  };

  queryGoalResults: { details: RoastBatchDetailPageTab } = {
    details: 'GOAL_RESULTS',
  };

  get isActive() {
    let { currentTab } = this.args;

    return {
      curves: currentTab === 'CURVES',
      goalResults: currentTab === 'GOAL_RESULTS',
    };
  }
}
