/* import __COLOCATED_TEMPLATE__ from './send-verification-code.hbs'; */
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import { service } from '@ember/service';
import { FormDataModel } from 'fabscale-app/models/form-data';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import CognitoService from 'fabscale-app/services/cognito';
import {
  PasswordCannotBeResetError,
  UserNotFoundError,
} from 'fabscale-app/models/errors/cognito';

interface Args {
  username: string;
  toggleShowPasswordForm: (username: string) => void;
}

export default class ResetPasswordSendVerificationCode extends Component<Args> {
  @service cognito: CognitoService;
  @service l10n: L10nService;

  formData: FormData;
  formModel: FormDataModel<FormData>;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    let { l10n } = this;

    this.formData = new FormData();
    this.formData.username = this.args.username;

    this.formModel = new FormDataModel({
      data: this.formData,
      validations: [
        {
          propertyName: 'username',
          message: l10n.t('The username must be a valid email address.'),
          validate: (value) => value && value.includes('@'),
        },
      ],
    });
  }

  @action
  updateUsername(username: string) {
    this.formModel.updateProperty('username', username);
  }

  sendVerificationCodeTask = dropTask(async () => {
    await this.formModel.validate();

    if (this.formModel.isInvalid) {
      return;
    }

    let { cognito } = this;
    let { username } = this.formData;

    try {
      await cognito.triggerResetPasswordMail({ username });
    } catch (error) {
      this._handleError(error);
      return;
    }

    this.args.toggleShowPasswordForm(username);
  });

  toggleShowPasswordFormTask = dropTask(async () => {
    await this.formModel.validate();

    if (this.formModel.isInvalid) {
      return;
    }

    this.args.toggleShowPasswordForm(this.formData.username);
  });

  _handleError(error: any) {
    let { l10n } = this;

    if (error instanceof UserNotFoundError) {
      this.formModel.addError(
        l10n.t('No user with this email address was found.'),
        'username'
      );
      return;
    }

    if (error instanceof PasswordCannotBeResetError) {
      this.formModel.addError(
        l10n.t(
          'The user you are trying to reset the password for is not active. Either it has been deactivated, or you have never signed in before.'
        ),
        'username'
      );
      return;
    }

    this.formModel.addError(error.message || l10n.t('An error occurred!'));
  }
}

class FormData {
  @tracked username: string;
}
