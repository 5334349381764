/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import { UnitSystem } from 'fabscale-app/models/enums/unit-systems';
import { DateRangeOptional } from 'fabscale-app/models/date-range';
import { Location } from 'fabscale-app/models/location';
import { TimeRange, TimeRangeOptional } from 'fabscale-app/models/time-range';
import SettingsService from 'fabscale-app/services/settings';
import UserSessionService from 'fabscale-app/services/user-session';
import { getEndTimeIsoString } from 'fabscale-app/utilities/utils/parse-time';

export interface PlantCompareReportFilters {
  dateRange?: DateRangeOptional;
  timeRange?: TimeRangeOptional;
  locationIds: string[];
  kpiTypes: KpiType[];
  unitSystem?: UnitSystem;
}

interface Args {
  filters: PlantCompareReportFilters;
  updateFilters: (filters: PlantCompareReportFilters) => void;
}

export default class PlantCompareReportIndex extends Component<Args> {
  @service settings: SettingsService;
  @service userSession: UserSessionService;

  defaultUnitSystem: UnitSystem;

  get unitSystem() {
    return this.args.filters.unitSystem || this.defaultUnitSystem;
  }

  get filters(): PlantCompareReportFilters {
    return Object.assign({}, this.args.filters, { timeRange: this.timeRange });
  }

  get timeRange(): TimeRange {
    return Object.assign(
      {},
      this.defaultTimeRange,
      this.args.filters.timeRange
    );
  }

  get allCurrentLocations() {
    return this.userSession.locations!;
  }

  get locations() {
    let { allCurrentLocations } = this;
    let { locationIds } = this.args.filters;

    return locationIds.length > 0
      ? (locationIds
          .map((locationId) =>
            allCurrentLocations.find((location) => location.id === locationId)
          )
          .filter(Boolean) as Location[])
      : allCurrentLocations;
  }

  @cached
  get defaultTimeRange() {
    let { dayStartTime } = this.settings.locationSettings;
    let end = getEndTimeIsoString(dayStartTime);

    return { start: dayStartTime, end };
  }

  get hasAllRequiredData() {
    return Boolean(
      this.args.filters.dateRange?.start &&
        this.args.filters.dateRange?.end &&
        this.args.filters.kpiTypes.length > 0
    );
  }

  get numberOfSelectedKpiTypes() {
    return this.filters.kpiTypes.length;
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    // Note: We cache this instead of directly access it in the getter to avoid a reload when the location is changed
    this.defaultUnitSystem = this.settings.locationSettings.unitSystem;
  }
}
