/* import __COLOCATED_TEMPLATE__ from './company-user-edit.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import { UserFormData } from 'fabscale-app/components/module/user-form';
import { Locale } from 'fabscale-app/models/enums/locales';
import { cloneUser, User } from 'fabscale-app/models/user';
import { UserRole } from 'fabscale-app/models/user-role';
import AnalyticsService from 'fabscale-app/services/analytics';
import StoreUserService from 'fabscale-app/services/store/user';
import StoreUserRoleService from 'fabscale-app/services/store/user-role';
import UserSessionService from 'fabscale-app/services/user-session';
import { arrayValuesMatch } from 'fabscale-app/utilities/utils/array';

interface Args {
  user: User;
}

export default class SettingsCompanyUserEdit extends Component<Args> {
  @service userSession: UserSessionService;
  @service('store/user') userStore: StoreUserService;
  @service('store/user-role') userRoleStore: StoreUserRoleService;
  @service analytics: AnalyticsService;

  updateUserTask = dropTask(
    async ({ id, name, locale, roleId, locationIds }: UserFormData) => {
      let { userStore } = this;
      let { user } = this.args;
      let currentUser = this.userSession.user!;
      let userIsCurrentUser = id === currentUser.id;

      // Check if name has changed
      let nameHasChanged = name !== user.name;
      let localeHasChanged = locale !== user.locale;

      if (nameHasChanged || localeHasChanged) {
        await userStore.update(id!, { name, locale });
      }

      // Check if roles have changed
      if (roleId !== user.role.id) {
        await userStore.updateRoleForUser({ userId: id!, roleId });

        this.analytics.addEvent('settings-users-update-role');
      }

      // Check if locations have changed
      let locationsHaveChanged = !arrayValuesMatch(
        locationIds,
        user.locations.map((location) => location.id)
      );
      if (locationsHaveChanged) {
        await userStore.updateLocationsForUser({ userId: id!, locationIds });

        this.analytics.addEvent('settings-users-update-locations');
      }

      // Update current user
      if (userIsCurrentUser) {
        if (locationsHaveChanged) {
          window.location.reload();
        }

        let newData: { name: string; locale: Locale; role?: UserRole } = {
          name,
          locale,
        };

        if (roleId !== user.role.id) {
          let role: UserRole = await this.userRoleStore.findById(roleId);
          newData.role = role;
        }

        let newCurrentUser = cloneUser(currentUser, newData);
        this.userSession.user = newCurrentUser;

        await this.userSession.setLocale(newCurrentUser.locale);
      }
    }
  );
}
