/* import __COLOCATED_TEMPLATE__ from './paginated-sort-container.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { SortDirection } from 'fabscale-app/models/enums/sort-options';

interface Args {
  sortBy?: string;
  sortDirection?: SortDirection;
  updateSort: (sortBy: string, sortDirection: SortDirection) => void;
}

export default class UiDataTablePaginatedSortContainer extends Component<Args> {
  @action
  updateSort(sortBy: string) {
    let currentSortBy = this.args.sortBy;
    let currentSortDirection = this.args.sortDirection;

    let newSortDirection: SortDirection =
      sortBy === currentSortBy && currentSortDirection === 'ASC'
        ? 'DESC'
        : 'ASC';

    this.args.updateSort(sortBy, newSortDirection);
  }
}
