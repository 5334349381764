import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { LabSample } from 'fabscale-app/models/lab-sample';
import { action } from '@ember/object';

export type RoastPicDetailPageTab =
  | 'PICTURE_DATA'
  | 'SIZE'
  | 'COLOR'
  | 'DEFECTS';

export default class RoastPicShowIndexController extends Controller {
  queryParams = ['tab'];

  @tracked tab?: RoastPicDetailPageTab = 'PICTURE_DATA';

  @action
  onLabSampleUpdate(labSample: LabSample) {
    this.model = labSample;
  }
}
