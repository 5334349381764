/* import __COLOCATED_TEMPLATE__ from './select.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

interface Args {
  value?: any;
  matchValueById?: boolean;
  options?: any[];
  onSearch: (search: string, ...rest: any) => any[];
}

export default class FormSelect extends Component<Args> {
  powerSelectInstance: any;

  @action
  onRegisterPowerSelect(powerSelectInstance: any) {
    this.powerSelectInstance = powerSelectInstance;
  }

  @action
  onClickLabel() {
    if (this.powerSelectInstance) {
      this.powerSelectInstance.actions.open();
    }
  }
}
