import { EnumsBase } from './-base';

export class LabSampleColorNameEnums extends EnumsBase<string> {
  constructor(...rest: any) {
    super(...rest);

    let { l10n } = this;
    let labels = {
      '1': l10n.t('Lighter Grayish Yellow'),
      '2': l10n.t('Medium Grayish Yellow'),
      '3': l10n.t('Darker Grayish Yellow'),
      '4': l10n.t('Lighter Brownish Yellow'),
      '5': l10n.t('Medium Brownish Yellow'),
      '6': l10n.t('Darker Brownish Yellow'),
      '7': l10n.t('Lighter Yellowish Brown'),
      '8': l10n.t('Medium Yellowish Brown'),
      '9': l10n.t('Darker Yellowish Brown'),
      '10': l10n.t('Lighter Reddish Brown'),
      '11': l10n.t('Medium Reddish Brown'),
      '12': l10n.t('Darker Reddish Brown'),
      '13': l10n.t('Lighter Moderate Brown'),
      '14': l10n.t('Medium Moderate Brown'),
      '15': l10n.t('Darker Moderate Brown'),
      '16': l10n.t('Lighter Deep Brown'),
      '17': l10n.t('Moderate Deep Brown'),
      '18': l10n.t('Darker Deep Brown'),
      '19': l10n.t('Lighter Blackish Brown'),
      '20': l10n.t('Moderate Blackish Brown'),
      '21': l10n.t('Darker Blackish Brown'),
      '22': l10n.t('Vivid pink'),
      '23': l10n.t('Strong pink'),
      '24': l10n.t('Deep pink'),
      '25': l10n.t('Light pink'),
      '26': l10n.t('Moderate pink'),
      '27': l10n.t('Dark pink'),
      '28': l10n.t('Pale pink'),
      '29': l10n.t('Grayish pink'),
      '30': l10n.t('Pinkish white'),
      '31': l10n.t('Pinkish gray'),
      '32': l10n.t('Vivid red'),
      '33': l10n.t('Strong red'),
      '34': l10n.t('Deep red'),
      '35': l10n.t('Very deep red'),
      '36': l10n.t('Moderate red'),
      '37': l10n.t('Dark red'),
      '38': l10n.t('Very dark red'),
      '39': l10n.t('Light grayish red'),
      '40': l10n.t('Grayish red'),
      '41': l10n.t('Dark grayish red'),
      '42': l10n.t('Reddish gray'),
      '43': l10n.t('Dark reddish gray'),
      '44': l10n.t('Vivid yellowish pink'),
      '45': l10n.t('Strong yellowish pink'),
      '46': l10n.t('Deep yellowish pink'),
      '47': l10n.t('Light yellowish pink'),
      '48': l10n.t('Moderate yellowish pink'),
      '49': l10n.t('Dark yellowish pink'),
      '50': l10n.t('Pale yellowish pink'),
      '51': l10n.t('Grayish yellowish pink'),
      '52': l10n.t('Brownish pink'),
      '53': l10n.t('Vivid reddish orange'),
      '54': l10n.t('Strong reddish orange'),
      '55': l10n.t('Deep reddish orange'),
      '56': l10n.t('Moderate reddish orange'),
      '57': l10n.t('Dark reddish orange'),
      '58': l10n.t('Grayish reddish orange'),
      '59': l10n.t('Strong reddish brown'),
      '60': l10n.t('Deep reddish brown'),
      '61': l10n.t('Light reddish brown'),
      '62': l10n.t('Moderate reddish brown'),
      '63': l10n.t('Dark reddish brown'),
      '64': l10n.t('Light grayish reddish brown'),
      '65': l10n.t('Grayish reddish brown'),
      '66': l10n.t('Vivid orange'),
      '67': l10n.t('Strong orange'),
      '68': l10n.t('Deep orange'),
      '69': l10n.t('Light orange'),
      '70': l10n.t('Moderate orange'),
      '71': l10n.t('Brownish orange'),
      '72': l10n.t('Strong brown'),
      '73': l10n.t('Deep brown'),
      '74': l10n.t('Light brown'),
      '75': l10n.t('Dark brown'),
      '76': l10n.t('Light grayish brown'),
      '77': l10n.t('Grayish brown'),
      '78': l10n.t('Light brownish gray'),
      '79': l10n.t('Brownish gray'),
      '80': l10n.t('Brilliant orange yellow'),
      '81': l10n.t('Strong orange yellow'),
      '82': l10n.t('Deep orange yellow'),
      '83': l10n.t('Light orange yellow'),
      '84': l10n.t('Moderate orange yellow'),
      '85': l10n.t('Dark orange yellow'),
      '86': l10n.t('Pale orange yellow'),
      '87': l10n.t('Strong yellowish brown'),
      '88': l10n.t('Deep yellowish brown'),
      '89': l10n.t('Light grayish yellowish brown'),
      '90': l10n.t('Grayish yellowish brown'),
      '91': l10n.t('Vivid yellow'),
      '92': l10n.t('Brilliant yellow'),
      '93': l10n.t('Strong yellow'),
      '94': l10n.t('Deep yellow'),
      '95': l10n.t('Light yellow'),
      '96': l10n.t('Moderate yellow'),
      '97': l10n.t('Dark yellow'),
      '98': l10n.t('Pale yellow'),
      '99': l10n.t('Yellowish white'),
      '100': l10n.t('Yellowish gray'),
      '101': l10n.t('Light olive brown'),
      '102': l10n.t('Moderate olive brown'),
      '103': l10n.t('Dark olive brown'),
      '104': l10n.t('Vivid greenish yellow'),
      '105': l10n.t('Brilliant greenish yellow'),
      '106': l10n.t('Strong greenish yellow'),
      '107': l10n.t('Deep greenish yellow'),
      '108': l10n.t('Light greenish yellow'),
      '109': l10n.t('Moderate greenish yellow'),
      '110': l10n.t('Dark greenish yellow'),
      '111': l10n.t('Pale greenish yellow'),
      '112': l10n.t('Grayish greenish yellow'),
      '113': l10n.t('Light olive'),
      '114': l10n.t('Moderate olive'),
      '115': l10n.t('Dark olive'),
      '116': l10n.t('Light grayish olive'),
      '117': l10n.t('Grayish olive'),
      '118': l10n.t('Dark grayish olive'),
      '119': l10n.t('Light olive gray'),
      '120': l10n.t('Olive gray'),
      '121': l10n.t('Vivid yellow green'),
      '122': l10n.t('Brilliant yellow green'),
      '123': l10n.t('Strong yellow green'),
      '124': l10n.t('Deep yellow green'),
      '125': l10n.t('Light yellow green'),
      '126': l10n.t('Moderate yellow green'),
      '127': l10n.t('Pale yellow green'),
      '128': l10n.t('Grayish yellow green'),
      '129': l10n.t('Strong olive green'),
      '130': l10n.t('Moderate olive green'),
      '131': l10n.t('Dark olive green'),
      '132': l10n.t('Grayish olive green'),
      '133': l10n.t('Dark grayish olive green'),
      '134': l10n.t('Vivid yellowish green'),
      '135': l10n.t('Brilliant yellowish green'),
      '136': l10n.t('Strong yellowish green'),
      '137': l10n.t('Deep yellowish green'),
      '138': l10n.t('Very deep yellowish green'),
      '139': l10n.t('Very light yellowish green'),
      '140': l10n.t('Light yellowish green'),
      '141': l10n.t('Moderate yellowish green'),
      '142': l10n.t('Dark yellowish green'),
      '143': l10n.t('Very dark yellowish green'),
      '144': l10n.t('Vivid green'),
      '145': l10n.t('Brilliant green'),
      '146': l10n.t('Strong green'),
      '147': l10n.t('Very light green'),
      '148': l10n.t('Light green'),
      '149': l10n.t('Moderate green'),
      '150': l10n.t('Dark green'),
      '151': l10n.t('Very dark green'),
      '152': l10n.t('Very pale green'),
      '153': l10n.t('Pale green'),
      '154': l10n.t('Grayish green'),
      '155': l10n.t('Dark grayish green'),
      '156': l10n.t('Greenish white'),
      '157': l10n.t('Light greenish gray'),
      '158': l10n.t('Greenish gray'),
      '159': l10n.t('Dark greenish gray'),
      '160': l10n.t('Vivid bluish green'),
      '161': l10n.t('Brilliant bluish green'),
      '162': l10n.t('Strong bluish green'),
      '163': l10n.t('Very light bluish green'),
      '164': l10n.t('Light bluish green'),
      '165': l10n.t('Moderate bluish green'),
      '166': l10n.t('Dark bluish green'),
      '167': l10n.t('Very dark bluish green'),
      '168': l10n.t('Brilliant greenish blue'),
      '169': l10n.t('Strong greenish blue'),
      '170': l10n.t('Very light greenish blue'),
      '171': l10n.t('Light greenish blue'),
      '172': l10n.t('Moderate greenish blue'),
      '173': l10n.t('Dark greenish blue'),
      '174': l10n.t('Very dark greenish blue'),
      '175': l10n.t('Vivid blue'),
      '176': l10n.t('Brilliant blue'),
      '177': l10n.t('Strong blue'),
      '178': l10n.t('Deep blue'),
      '179': l10n.t('Very light blue'),
      '180': l10n.t('Light blue'),
      '181': l10n.t('Moderate blue'),
      '182': l10n.t('Dark blue'),
      '183': l10n.t('Very pale blue'),
      '184': l10n.t('Pale blue'),
      '185': l10n.t('Grayish blue'),
      '186': l10n.t('Dark grayish blue'),
      '187': l10n.t('Blackish blue'),
      '188': l10n.t('Bluish white'),
      '189': l10n.t('Light bluish gray'),
      '190': l10n.t('Bluish gray'),
      '191': l10n.t('Dark bluish gray'),
      '192': l10n.t('Vivid purplish blue'),
      '193': l10n.t('Brilliant purplish blue'),
      '194': l10n.t('Strong purplish blue'),
      '195': l10n.t('Deep purplish blue'),
      '196': l10n.t('Very light purplish blue'),
      '197': l10n.t('Light purplish blue'),
      '198': l10n.t('Moderate purplish blue'),
      '199': l10n.t('Dark purplish blue'),
      '200': l10n.t('Very pale purplish blue'),
      '201': l10n.t('Pale purplish blue'),
      '202': l10n.t('Grayish purplish blue'),
      '203': l10n.t('Vivid violet'),
      '204': l10n.t('Brilliant violet'),
      '205': l10n.t('Strong violet'),
      '206': l10n.t('Deep violet'),
      '207': l10n.t('Very light violet'),
      '208': l10n.t('Light violet'),
      '209': l10n.t('Moderate violet'),
      '210': l10n.t('Dark violet'),
      '211': l10n.t('Very pale violet'),
      '212': l10n.t('Pale violet'),
      '213': l10n.t('Grayish violet'),
      '214': l10n.t('Vivid purple'),
      '215': l10n.t('Brilliant purple'),
      '216': l10n.t('Strong purple'),
      '217': l10n.t('Deep purple'),
      '218': l10n.t('Very deep purple'),
      '219': l10n.t('Very light purple'),
      '220': l10n.t('Light purple'),
      '221': l10n.t('Moderate purple'),
      '222': l10n.t('Dark purple'),
      '223': l10n.t('Very dark purple'),
      '224': l10n.t('Very pale purple'),
      '225': l10n.t('Pale purple'),
      '226': l10n.t('Grayish purple'),
      '227': l10n.t('Dark grayish purple'),
      '228': l10n.t('Blackish purple'),
      '229': l10n.t('Purplish white'),
      '230': l10n.t('Light purplish gray'),
      '231': l10n.t('Purplish gray'),
      '232': l10n.t('Dark purplish gray'),
      '233': l10n.t('Vivid reddish purple'),
      '234': l10n.t('Strong reddish purple'),
      '235': l10n.t('Deep reddish purple'),
      '236': l10n.t('Very deep reddish purple'),
      '237': l10n.t('Light reddish purple'),
      '238': l10n.t('Moderate reddish purple'),
      '239': l10n.t('Dark reddish purple'),
      '240': l10n.t('Very dark reddish purple'),
      '241': l10n.t('Pale reddish purple'),
      '242': l10n.t('Grayish reddish purple'),
      '243': l10n.t('Brilliant purplish pink'),
      '244': l10n.t('Strong purplish pink'),
      '245': l10n.t('Deep purplish pink'),
      '246': l10n.t('Light purplish pink'),
      '247': l10n.t('Moderate purplish pink'),
      '248': l10n.t('Dark purplish pink'),
      '249': l10n.t('Pale purplish pink'),
      '250': l10n.t('Grayish purplish pink'),
      '251': l10n.t('Vivid purplish red'),
      '252': l10n.t('Strong purplish red'),
      '253': l10n.t('Deep purplish red'),
      '254': l10n.t('Very deep purplish red'),
      '255': l10n.t('Moderate purplish red'),
      '256': l10n.t('Dark purplish red'),
      '257': l10n.t('Very dark purplish red'),
      '258': l10n.t('Light grayish purplish red'),
      '259': l10n.t('Grayish purplish red'),
      '260': l10n.t('White'),
      '261': l10n.t('Light gray'),
      '262': l10n.t('Medium gray'),
      '263': l10n.t('Dark gray'),
      '264': l10n.t('Black'),
    };

    this.labels = labels;
  }
}
