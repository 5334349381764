import { service } from '@ember/service';
import LabSampleStore from 'fabscale-app/services/store/lab-sample';
import RoastPicCompareController from 'fabscale-app/routes/roast-pic/compare/controller';
import Route from '@ember/routing/route';
import Transition from '@ember/routing/-private/transition';

export default class RoastPicCompareRoute extends Route {
  @service('store/lab-sample') roastPicStore: LabSampleStore;

  async model(params: { id1: string; id2: string }) {
    let { roastPicStore } = this;
    let firstLabSample = await roastPicStore.getLabSampleById(params.id1);
    let secondLabSample = await roastPicStore.getLabSampleById(params.id2);
    return {
      firstLabSample,
      secondLabSample,
    };
  }

  resetController(
    controller: RoastPicCompareController,
    isExiting: boolean,
    transition: Transition
  ) {
    super.resetController(controller, isExiting, transition);

    if (isExiting) {
      controller.tab = undefined;
    }
  }
}
