/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import UserSessionService from 'fabscale-app/services/user-session';

export default class DashboardCardsMyOpenTasks extends Component {
  @service userSession: UserSessionService;

  get currentUserId() {
    return this.userSession.user?.id;
  }
}
