/* import __COLOCATED_TEMPLATE__ from './over-time.hbs'; */
import Component from '@glimmer/component';
import { Interval } from 'fabscale-app/models/enums/intervals';
import { KpiCompareReportTableType } from 'fabscale-app/routes/reports/kpi-compare/index/controller';

interface Args {
  tableId: KpiCompareReportTableType;
}

export default class KpiCompareReportDetailsOverTime extends Component<Args> {
  get interval(): Interval {
    let { tableId } = this.args;

    if (tableId === KpiCompareReportTableType.PerHour) {
      return 'HOUR';
    }

    if (tableId === KpiCompareReportTableType.PerWeek) {
      return 'WEEK';
    }

    if (tableId === KpiCompareReportTableType.PerMonth) {
      return 'MONTH';
    }

    return 'DAY';
  }
}
