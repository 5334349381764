/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { DayOfWeek } from 'fabscale-app/models/enums/day-of-week';
import { UnitSystem } from 'fabscale-app/models/enums/unit-systems';
import { UnitRoastColor } from 'fabscale-app/models/enums/units';
import { FormDataModel } from 'fabscale-app/models/form-data';
import { cloneLocation, Location } from 'fabscale-app/models/location';
import { LocationSettings } from 'fabscale-app/models/location-settings';
import ErrorParserService from 'fabscale-app/services/error-parser';
import SettingsService from 'fabscale-app/services/settings';
import StoreLocationService from 'fabscale-app/services/store/location';
import UserSessionService from 'fabscale-app/services/user-session';
import { DateTime } from 'luxon';
import Transition from '@ember/routing/-private/transition';
import RouterService from '@ember/routing/router-service';

interface Args {
  location: Location;
}

class FormData {
  @tracked name: string;
  @tracked unitSystem: UnitSystem;
  @tracked dayStartTime: string;
  @tracked timezoneName: string;
  @tracked startDayOfWeek: DayOfWeek;
  @tracked defaultRoastColorUnit: UnitRoastColor;

  get timezoneDate(): DateTime {
    let { timezoneName } = this;
    return DateTime.local().setZone(timezoneName);
  }

  get timezoneIsValid(): boolean {
    return this.timezoneDate.isValid;
  }
}

export default class PlantSettingsIndex extends Component<Args> {
  @service userSession: UserSessionService;
  @service settings: SettingsService;
  @service l10n: L10nService;
  @service('store/location') locationStore: StoreLocationService;
  @service('error-parser') errorParser: ErrorParserService;
  @service router: RouterService;

  @tracked formData: FormData;
  @tracked formModel: FormDataModel<FormData>;
  @tracked locationSettings: LocationSettings;
  @tracked showDialog = false;

  private transition?: Transition;

  public shouldAbort = true;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this._initLocationSettings();

    let { l10n } = this;

    let formData = new FormData();
    formData.name = this.args.location.name;
    formData.unitSystem = this.locationSettings.unitSystem;
    formData.startDayOfWeek = this.locationSettings.startDayOfWeek;
    formData.dayStartTime = this.locationSettings.dayStartTime;
    formData.timezoneName = this.args.location.timezoneName;
    formData.defaultRoastColorUnit =
      this.locationSettings.defaultRoastColorUnit;

    this.formData = formData;
    this.formModel = new FormDataModel({
      data: formData,
      validations: [
        {
          propertyName: 'name',
          message: l10n.t('You have to enter a name.'),
          validate: (value) => !!value,
        },
        {
          propertyName: 'timezoneName',
          message: l10n.t('The timezone is invalid.'),
          validate: (value, { data }) => !!value && data.timezoneIsValid,
        },
      ],
    });
  }

  willDestroy(): void {
    this.resetRouteHandler();

    super.willDestroy();
  }

  get locationName() {
    return this.args.location.name;
  }

  @action
  updateName(name: string) {
    this.formModel.updateProperty('name', name);
    this.addRouteHandler();
  }

  @action
  updateUnitSystem(unitSystem: UnitSystem) {
    this.formModel.updateProperty('unitSystem', unitSystem);
    this.addRouteHandler();
  }

  @action
  updateDayStartTime(dayStartTime: string) {
    this.formModel.updateProperty('dayStartTime', dayStartTime);
    this.addRouteHandler();
  }

  @action
  updateStartDayOfWeek(startDayOfWeek: DayOfWeek) {
    this.formModel.updateProperty('startDayOfWeek', startDayOfWeek);
    this.addRouteHandler();
  }

  @action
  updateTimezoneName(timezoneName: string) {
    this.formModel.updateProperty('timezoneName', timezoneName);
    this.addRouteHandler();
  }

  @action
  updateDefaultRoastColorUnit(defaultRoastColorUnit: UnitRoastColor) {
    this.formModel.updateProperty(
      'defaultRoastColorUnit',
      defaultRoastColorUnit
    );
    this.addRouteHandler();
  }

  @action
  hideSuccessMessage() {
    this.formModel.successMessage = undefined;
  }

  @action
  handleOnCancel() {
    this.shouldAbort = true;
    this.showDialog = false;
  }

  @action
  handleOnYes() {
    this.showDialog = false;
    this.shouldAbort = false;
    if (this.transition) {
      this.router.transitionTo(this.transition.to.name);
    }
  }

  submitFormTask = dropTask(async () => {
    let isValid: boolean = await this.formModel.validate();

    if (!isValid) {
      return;
    }

    // Ev. update the location itself
    let { name, timezoneName } = this.formData;

    try {
      await this._maybeUpdateLocation({ name, timezoneName });
      this.resetRouteHandler();
    } catch (error) {
      this.formModel.addError(this.errorParser.getErrorMessage(error));
      return;
    }

    // Ev. update location settings
    let { startDayOfWeek, dayStartTime, defaultRoastColorUnit, unitSystem } =
      this.formData;

    try {
      await this._maybeUpdateLocationSettings({
        startDayOfWeek,
        dayStartTime,
        defaultRoastColorUnit,
        unitSystem,
      });
    } catch (error) {
      this.formModel.addError(this.errorParser.getErrorMessage(error));
      return;
    }

    let { l10n } = this;

    this.formModel.hasSuccess({
      title: l10n.t('Settings saved'),
      description: l10n.t('Your plant settings were successfully updated.'),
    });
  });

  async _maybeUpdateLocation({
    name,
    timezoneName,
  }: {
    name: string;
    timezoneName: string;
  }) {
    let { location } = this.args;

    if (name === location.name && timezoneName === location.timezoneName) {
      return;
    }

    await this.locationStore.update(location.id, { name, timezoneName });

    let newLocation = cloneLocation(location, { name, timezoneName });
    this._updateLocation(newLocation);
  }

  async _maybeUpdateLocationSettings({
    startDayOfWeek,
    dayStartTime,
    unitSystem,
    defaultRoastColorUnit,
  }: {
    startDayOfWeek: DayOfWeek;
    dayStartTime: string;
    unitSystem: UnitSystem;
    defaultRoastColorUnit: UnitRoastColor;
  }) {
    let { locationSettings } = this;

    if (
      startDayOfWeek === locationSettings.startDayOfWeek &&
      dayStartTime === locationSettings.dayStartTime &&
      unitSystem === locationSettings.unitSystem &&
      defaultRoastColorUnit === locationSettings.defaultRoastColorUnit
    ) {
      return;
    }

    let backup = getLocationSettingsBackup(locationSettings);

    Object.assign(locationSettings, {
      startDayOfWeek,
      dayStartTime,
      unitSystem,
      defaultRoastColorUnit,
    });

    try {
      await locationSettings.save();
    } catch (error) {
      Object.assign(locationSettings, backup);

      throw error;
    }
  }

  _updateLocation(updatedLocation: Location) {
    if (this.userSession.currentLocation?.id === updatedLocation.id) {
      this.userSession.currentLocation = updatedLocation;
    }

    let locationPos = this.userSession.locations.findIndex(
      (location) => location.id === updatedLocation.id
    );

    if (locationPos > -1) {
      this.userSession.locations.splice(locationPos, 1, updatedLocation);
    }

    this.addRouteHandler();
  }

  _initLocationSettings() {
    // If it is the current location, re-use the existing locationSettings
    if (this.args.location.id === this.userSession.currentLocation?.id) {
      this.locationSettings = this.settings.locationSettings;
      return;
    }

    this.locationSettings = new LocationSettings({
      location: this.args.location,
      store: this.locationStore,
    });

    this.locationSettings.fromLocationSettings(this.args.location.settings);
  }

  public beforeRouteChangeHandler?: (transition: Transition) => void;

  private addRouteHandler() {
    if (!this.beforeRouteChangeHandler) {
      this.beforeRouteChangeHandler = (transition: Transition) => {
        // When we abort, it triggers a new transition to the same route
        // Which leads to an endless loop if we don't handle that here
        if (transition.from?.name === transition?.to.name) {
          return;
        }

        if (this.shouldAbort) {
          this.showDialog = true;
          transition.abort();
        }

        this.transition = transition;
        this.shouldAbort = true;
      };

      this.router.on('routeWillChange', this.beforeRouteChangeHandler);
    }
  }

  private resetRouteHandler() {
    if (this.beforeRouteChangeHandler) {
      this.router.off('routeWillChange', this.beforeRouteChangeHandler);
      this.beforeRouteChangeHandler = undefined;
    }
  }
}

function getLocationSettingsBackup(locationSettings: LocationSettings): {
  startDayOfWeek: DayOfWeek;
  dayStartTime: string;
  unitSystem: UnitSystem;
  defaultRoastColorUnit: UnitRoastColor;
} {
  let { startDayOfWeek, dayStartTime, defaultRoastColorUnit, unitSystem } =
    locationSettings;

  return { startDayOfWeek, dayStartTime, unitSystem, defaultRoastColorUnit };
}
