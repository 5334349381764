/* import __COLOCATED_TEMPLATE__ from './role-form.hbs'; */
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { Permission } from 'fabscale-app/models/enums/permissions';
import { FormDataModel } from 'fabscale-app/models/form-data';
import { UserRole } from 'fabscale-app/models/user-role';
import ErrorParserService from 'fabscale-app/services/error-parser';
import { logError } from 'fabscale-app/utilities/utils/log-error';
import Transition from '@ember/routing/-private/transition';

interface Args {
  role?: UserRole;
  onSubmit: (data: {
    name: string;
    permissions: Permission[];
  }) => Promise<void>;
}

class FormData {
  @tracked name = '';
  @tracked permissions: Permission[] = [];
}

export default class PageSettingsRolesForm extends Component<Args> {
  @service l10n: L10nService;
  @service('error-parser') errorParser: ErrorParserService;
  @service router: RouterService;

  @tracked formData: FormData;
  @tracked formModel: FormDataModel<FormData>;
  @tracked showDialog = false;

  private transition?: Transition;
  private shouldAbort = true;

  get isNew() {
    return typeof this.args.role === 'undefined';
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this._initialiseFormData();
  }

  willDestroy(): void {
    this.resetRouteHandler();

    super.willDestroy();
  }

  submitFormTask = dropTask(async () => {
    let isValid: boolean = await this.formModel.validate();

    if (!isValid) {
      return;
    }

    let { name, permissions } = this.formData;

    try {
      await this.args.onSubmit({ name, permissions });
      this.resetRouteHandler();
    } catch (error) {
      logError(error);
      this.formModel.addError(this.errorParser.getErrorMessage(error));
      return;
    }

    if (!this.isNew) {
      this.router.transitionTo(
        'routes/company-settings.roles.show.index',
        this.args.role!.id
      );
      return;
    }

    this._initialiseFormData();
  });

  @action
  updateName(name: string) {
    this.formModel.updateProperty('name', name);
    this.addRouteHandler();
  }

  @action
  cancel() {
    if (this.isNew) {
      this.router.transitionTo('routes/company-settings.roles.index');
    } else {
      this.router.transitionTo('routes/company-settings.roles.show.index');
    }
  }

  @action
  prefillForRoleTemplate(role: UserRole) {
    this.formModel.updateProperty('permissions', role.permissions.slice());
  }

  @action
  handleOnCancel() {
    this.shouldAbort = true;
    this.showDialog = false;
  }

  @action
  handleOnYes() {
    this.showDialog = false;
    this.shouldAbort = false;
    if (this.transition) {
      this.router.transitionTo(this.transition.to.name);
    }
  }

  _initialiseFormData() {
    let { l10n } = this;

    let formData = new FormData();

    if (this.args.role) {
      let { name, permissions } = this.args.role;
      formData.name = name;
      formData.permissions = permissions.slice();
    }

    this.formData = formData;
    this.formModel = new FormDataModel({
      data: formData,
      validations: [
        {
          propertyName: 'name',
          validate: (value?: string) => !!value,
          message: l10n.t('You have to enter a name.'),
        },
      ],
    });
  }

  public beforeRouteChangeHandler?: (transition: Transition) => void;

  private addRouteHandler() {
    if (!this.beforeRouteChangeHandler) {
      this.beforeRouteChangeHandler = (transition: Transition) => {
        // When we abort, it triggers a new transition to the same route
        // Which leads to an endless loop if we don't handle that here
        if (transition.from?.name === transition?.to.name) {
          return;
        }

        if (this.shouldAbort) {
          this.showDialog = true;
          transition.abort();
        }

        this.transition = transition;
        this.shouldAbort = true;
      };

      this.router.on('routeWillChange', this.beforeRouteChangeHandler);
    }
  }

  private resetRouteHandler() {
    if (this.beforeRouteChangeHandler) {
      this.router.off('routeWillChange', this.beforeRouteChangeHandler);
      this.beforeRouteChangeHandler = undefined;
    }
  }
}
