/* import __COLOCATED_TEMPLATE__ from './create.hbs'; */
import { dropTask } from 'ember-concurrency';
import Component from '@glimmer/component';
import { service } from '@ember/service';
import StoreParameterGoalService from 'fabscale-app/services/store/parameter-goal';
import { ParameterGoalInput } from 'fabscale-app/models/parameter-goal';
import { tracked } from '@glimmer/tracking';

export default class PagePlantParameterGoalsCreate extends Component {
  @service('store/parameter-goal')
  parameterGoalStore: StoreParameterGoalService;

  @tracked createdParameterGoal?: { id: string };

  createParameterGoalTask = dropTask(async (data: ParameterGoalInput) => {
    this.createdParameterGoal = undefined;

    let { id }: { id: string } = await this.parameterGoalStore.create(data);

    this.createdParameterGoal = { id };
  });
}
