/* import __COLOCATED_TEMPLATE__ from './timezone.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { dropTask, restartableTask, timeout } from 'ember-concurrency';
import { TIMEOUTS } from 'fabscale-app/utilities/fixtures/timeouts';
import AvailableDataService from 'fabscale-app/services/available-data';
import { DateTime } from 'luxon';

interface Args {
  timezoneName: string;
  updateTimezoneName: (timezoneName: string) => void;
  timezoneDate: DateTime;
  error?: string;
}

export default class PlantSettingsDateTimeSettingsTimezone extends Component<Args> {
  @service availableData: AvailableDataService;

  availableTimezoneNames?: string[];

  searchTimezoneNamesTask = restartableTask(async (searchTerm: string) => {
    if (!searchTerm) {
      return [];
    }

    if (!this.availableTimezoneNames) {
      await this._initAvailableDataTask.perform();
    }

    await timeout(TIMEOUTS.searchTypeDebounce);

    let searchTermNormalized = searchTerm.toLowerCase();
    let results = this.availableTimezoneNames!.filter((timezoneName) => {
      let lowerCaseName = timezoneName.toLowerCase();
      let normalizedName = lowerCaseName.replace(/_/g, ' ').replace(/\//g, ' ');

      return (
        lowerCaseName.includes(searchTermNormalized) ||
        normalizedName.includes(searchTermNormalized)
      );
    });

    return results.slice(0, 10);
  });

  _initAvailableDataTask = dropTask(async () => {
    let { availableData } = this;

    this.availableTimezoneNames =
      await availableData.getAvailableTimezoneNames();
  });
}
