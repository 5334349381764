/* import __COLOCATED_TEMPLATE__ from './chart.hbs'; */
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { TimelineBarChartDataInput } from 'fabscale-app/components/chart';
import {
  PlantAssetStatus,
  PlantAssetStatuses,
} from 'fabscale-app/models/enums/plant-asset-status';
import { PlantAssetStateInfo } from 'fabscale-app/models/plant-asset-state-info';
import EnumLabelsService from 'fabscale-app/services/enum-labels';
import { sortByPropertyInList, uniq } from 'fabscale-app/utilities/utils/array';
import { DateTime } from 'luxon';

interface Signature {
  Args: {
    date: DateTime;
    statusInfos: PlantAssetStateInfo[];
  };
}

export default class PagePlantAssetStatusMapChart extends Component<Signature> {
  @service declare enumLabels: EnumLabelsService;
  @service declare l10n: L10nService;

  get startDate() {
    return this.args.date.startOf('day');
  }

  get endDate() {
    return this.args.date.endOf('day');
  }

  @cached
  get now() {
    return DateTime.local();
  }

  @cached
  get chartData(): (TimelineBarChartDataInput & {
    status: PlantAssetStatus;
  })[] {
    let { enumLabels, l10n, endDate: maxEnd, now } = this;

    if (+now < +maxEnd) {
      maxEnd = now;
    }

    return this.args.statusInfos.map((info) => {
      let { status, startDate, endDate, reason } = info;

      if (!endDate) {
        endDate = maxEnd;
      }

      let colorType = getColorTypeForStatus(info.status);

      return {
        status,
        label: enumLabels.plantAssetStatus(status),
        startDate,
        endDate: endDate,
        colorType,
        hasComment: !!reason,
        tooltip: reason
          ? htmlSafe(
              `${l10n.t('Reason')}: <strong>${enumLabels.plantAssetStatusReason(
                reason
              )}</strong>`
            )
          : undefined,
      };
    });
  }

  get labels(): string[] {
    let { chartData } = this;

    return uniq(
      sortByPropertyInList(chartData, PlantAssetStatuses, 'status').map(
        (item) => item.label
      )
    );
  }
}

function getColorTypeForStatus(
  status: PlantAssetStatus
): 'success' | 'error' | 'warning' | 'info' {
  switch (status) {
    // grey
    case 'OFF':
      return 'info';
    // red
    case 'MACHINE_FAILURE':
      return 'error';
    case 'IN_PRODUCTION':
      return 'success';
    // Default: orange
    default:
      return 'warning';
  }
}
